import { API_BASE_ENDPOINT, API_BASE_HEADER } from '../http/apiConfig';
import { message } from 'antd';

class TranslateAPI {
    translate = async(from, to, text) => {
       
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/translation/f/${from}/t/${to}/text/${text}`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.text();
            return data;
        }catch(e){
            message.error(e);
            
            return false;
        }
    }
}

const translateAPI = new TranslateAPI();
export default translateAPI;