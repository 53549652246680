import { API_BASE_ENDPOINT, API_BASE_HEADER, parseMenuLocation, parse } from '../http/apiConfig';
import { message } from 'antd';

class TableAPI {
   
    create = async(menuId, qr) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menutable/${menuId}`,
                await API_BASE_HEADER('POST', qr)
            );
            let data = await response.json();
            return parse(data, {}, parseMenuLocation);
        }catch(e){
            message.error(e.message);
            console.error(e);
            return null;
        } 
    }

    update = async(menuLocationId, qr) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menutable/${menuLocationId}`,
                await API_BASE_HEADER('PUT', qr)
            );
            let data = await response.json();
            return parse(data, {}, parseMenuLocation);
        }catch(e){
            message.error(e.message);
            console.error(e);
        } 
    }

    updatePosition = async(menuLocationId, qrPosition) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menutable/${menuLocationId}/position`,
                await API_BASE_HEADER('PUT', qrPosition)
            );
            let data = await response.json();
            return parse(data, {}, parseMenuLocation);
        }catch(e){
            message.error(e.message);
            console.error(e);
        } 
    }

    delete = async(menuLocationId) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menutable/${menuLocationId}`,
                await API_BASE_HEADER('DELETE')
            );
            let data = await response.json();
            return parse(data);
            
        }catch(e){
            message.error(e.message);
            console.error(e);
        } 
    }

    toggleVisible = async(menuLocationId) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menutable/${menuLocationId}/visible`,
                await API_BASE_HEADER('PATCH')
            );
            let data = await response.json();
            return parse(data);
        }catch(e){
            message.error(e.message);
            console.error(e);
        } 
    }

    toggleAllowOrder = async(menuLocationId) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menutable/${menuLocationId}/alloworder`,
                await API_BASE_HEADER('PATCH')
            );
            let data = await response.json();
            return parse(data);
        }catch(e){
            message.error(e.message);
            console.error(e);
        } 
    }

    bulkUpdateSetting = async(menuId, key, value) => {
        const response = await fetch(
            `${API_BASE_ENDPOINT}/api/menutable/${menuId}/key/${key}/value/${value}/bulk`,
            await API_BASE_HEADER('PATCH')
        );
    }
  
   
};

const tableAPI = new TableAPI();
export default tableAPI;