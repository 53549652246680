import React, { useState, useEffect } from 'react';
import {
    Radio,
    Layout,
    Select
  } from 'antd';
  
import { useCompany } from '../../hooks/CompanyProvider';
import QrList from './TableList';
import menuAPI from '../../http/apiMenu';
import { PageHeader } from '@ant-design/pro-layout'

//const { Content } = Layout;
//const { Option } = Select;


export default function TableIndex(props){
    const [menus, setMenus] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState();
    const company = useCompany();

    useEffect(() => {
        if (!company.companyId) return;
        getMenuList()
    }, [company.companyId]);

    const getMenuList = async() => {
        let menuList = await menuAPI.getListForCompany(company.companyId);
        setMenus(menuList || []);
     
        if (selectedMenu)
            setSelectedMenu(selectedMenu)
        else if (menuList && menuList.length > 0) {
            setSelectedMenu(menuList[0].id);
        }
    }

    const onSelectMenu = async(e) => {
        setSelectedMenu(e.target.value);
    }

    return (
        
        <Layout style={{ height: "100vh", overflow: "auto" }} >
              <PageHeader
                className="site-page-header"
                title={'Qr code and table zones'}
                subTitle="settings">
                <Radio.Group
                    options={menus.map(l => { return { label: l.name, value: l.id} })}
                    onChange={onSelectMenu}
                    value={selectedMenu}
                    optionType="button"
                    buttonStyle="solid"
                    />
                 
            </PageHeader>
            
           
            <div className="px-7">
                {
                    selectedMenu 
                    ? <QrList menuId={selectedMenu} qrList={ menus.find(x => x.id === selectedMenu).menuLocations } zoneList={ menus.find(x => x.id === selectedMenu).menuZones } refresh={ getMenuList } />
                    : <></>
                }
                
            </div>

       </Layout>
        
        
    )
}