
import React, { useState, useEffect } from 'react'
import { Modal, Input, Layout, Button, Select, Form, Radio, Switch, Divider  } from 'antd';
import currencies from '../Settings/currency-list.json';
import { languages, getEmojiFlag, countries } from 'countries-list';
import companyAPI  from '../../http/apiCompany';

const { Content } = Layout;
const { Option } = Select;

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };



export default function MenuCompanyCreate({isModalOpen, onCreate, onCancel}){

    const [form] = Form.useForm(); 
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [selectedLanguages, setSelectedLanguages] = useState([]);

    useEffect(()=> {
        form.resetFields(); 
        
    },[isModalOpen]);

    const save = async(values) => {
        setConfirmLoading(true);
        
        let apiObj = {
            companyName: values.companyName,
            phone: values.phone,
            vat: values.vat,
            currency: values.currency,
            country: values.country,
            language: values.language,
            languages: values.languages.map(l =>{
                return {
                    key: l,
                    ...languages[l]
                };
            }),
            username: values.username,
            email: values.email,
            password: values.password
        };
        
        try {
            let result = await companyAPI.register(apiObj);
          
            if (result !== false) {
                setConfirmLoading(false);
                form.resetFields(); 
                onCreate(result);
               
            }
        } catch(e) {
            console.error(e);
        } finally {
            setConfirmLoading(false);
        }
    }

    const handleOk = () => {
        form.validateFields()
            .then(values => { save(values); })
            .catch(info => { console.log('Validate Failed:', info);});
      };

    const handleFormValuesChange = (changedValues) => {
        const formFieldName = Object.keys(changedValues)[0];
        
        if (formFieldName === "languages") {
          setSelectedLanguages(changedValues[formFieldName]);  // perform setState here
        
        }
      };

    const handleCancel = () => {
        form.resetFields(); 
        onCancel();
    };

    return <Modal
                enableDarkTheme
                destroyOnClose={true}
                width={600}
                title={"Create company"}
                open={isModalOpen}
                forceRender
                confirmLoading={confirmLoading}
                onOk={handleOk}
                onCancel={handleCancel}
                
            >
                
                <Form
                    key={"createCompany"}
                    form={form}
                    {...formItemLayout}
                    name="createCompany"
                    id="createCompany"
                    scrollToFirstError
                    onValuesChange={handleFormValuesChange}
                >
                    <Form.Item
                        name="country"
                        label={`Country`}
                        rules={[
                        {
                            required: true,
                            message: 'Please select country',
                        },
                        ]}
                    >
                        <Select
                            mode="single"
                            allowClear
                            style={{ width: '200px' }}
                            placeholder="Select currency for and products"
                        >
                            { Object.keys(countries).map(l => <Option key={l} value={l}> {countries[l].name}</Option>) }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="companyName"
                        label={`Company name`}
                        rules={[
                        {
                            required: true,
                            message: 'Please input company name',
                        },
                        ]}
                    >
                        <Input type="text" style={{width:"200px"}}/>
                    </Form.Item>

                    <Form.Item
                        name="phone"
                        label={`Phone`}
                        rules={[
                        {
                            required: true,
                            message: 'Please input phone',
                        },
                        ]}
                    >
                    <Input type="phone" style={{width:"200px"}}/>
                    </Form.Item>

                    <Form.Item
                        name="vat"
                        label={`VAT`}
                        rules={[
                        {
                            required: true,
                            message: 'Please input vat',
                        },
                        ]}
                    >
                        <Input type="number" suffix="%" style={{width:"100px"}}/>
                    </Form.Item>

                    <Form.Item
                        name="currency"
                        label={`Currency`}
                        rules={[
                        {
                            required: true,
                            message: 'Please select currency',
                        },
                        ]}
                    >
                        <Select
                            mode="single"
                            allowClear
                            style={{ width: '200px' }}
                            placeholder="Select currency for and products"
                        >
                            { Object.keys(currencies).map(l => <Option key={l} value={l}> {currencies[l].name} {currencies[l].symbol_native}</Option>) }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="languages"
                        label={`Languages`}
                        rules={[
                        {
                            required: true,
                            message: 'Please select languages',
                        },
                        ]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            
                            placeholder="Select languages for menu and products"
                        >
                            { Object.keys(languages).map(l => <Option key={l} value={l}>{getEmojiFlag(l)} {languages[l].name}</Option>) }
                        </Select>
                    </Form.Item>

                    <Form.Item
                      name="language"
                      label={`Default`}
                      rules={[
                        {
                            required: true,
                            message: 'Please select ui default language',
                        }
                      ]}
                    >
                        <Radio.Group>
                            { selectedLanguages.map(l => <Radio.Button key={l} value={l}>{languages[l].name}</Radio.Button>) }
                        </Radio.Group>
                    </Form.Item>

                    <Divider orientation="left">User account</Divider>

                    <Form.Item
                        name="username"
                        label={
                        <span>
                            User name
                        </span>
                        }
                        rules={[{ required: true, message: 'User  name', whitespace: true }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        },
                        {
                            required: true,
                            message: 'Please input email for account E-mail!',
                        },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            ]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                    
                        <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('The two passwords that you entered do not match!');
                                },
                            }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                </Form>
            </Modal>
}