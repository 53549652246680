
import React, { useState, useEffect } from 'react'
import '@firebase/auth';
import '@firebase/storage';
import {
  Modal,
  Form,
  Input,
  InputNumber,
  TimePicker,
  Space,
  Switch,
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import productPropertyAPI from '../../http/apiProductProperty';
import { PRODUCT_TYPE } from '../Common';
import dayjs from 'dayjs';

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

export default function PropertyEdit(props){

    const [form] = Form.useForm(); 
    const [confirmLoading, setConfirmLoading] = useState(false);
    
    const { property } = props;
    const timeFormat = 'HH:mm';

    useEffect(()=> {
        form.resetFields(); 
        
        if (property && property.id && props.isModalOpen === true){
            
            form.setFieldsValue({
                name: property.data.name,
                order: property.data.order || 0,
                hoursFrom: property.data.saleHours && property.data.saleHours.length > 1 ? dayjs(property.data.saleHours[0], 'HH:mm') : '',
                hoursTo: property.data.saleHours && property.data.saleHours.length > 1 ? dayjs(property.data.saleHours[1], 'HH:mm') : '',
                type: PRODUCT_TYPE
            });
        }else{
            form.setFieldsValue({
                type: PRODUCT_TYPE
            });
        }
    },[props.isModalOpen]);

    const save = async(values) => {
        
        setConfirmLoading(true);
        
        let apiObj = {
            data: JSON.stringify({ name: values.name, order: values.order,  saleHours: [values.hoursFrom.format('HH:mm'), values.hoursTo.format('HH:mm')] }),
            type: values.type
        };

        let result;
        if (!property.id){
            result = await productPropertyAPI.create(props.companyId, apiObj);
        }else{
            result = await productPropertyAPI.update(property.id, apiObj);
        }
        
        setConfirmLoading(false);
        if (result !== false) {
            setConfirmLoading(false);
            form.resetFields(); 
            props.setModalOpen(false);
            props.onSuccess(result);
        }
    }

    const handleOk = () => {
        form
          .validateFields()
          .then(values => {
            save(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      };

    const handleCancel = () => {
        form.resetFields(); 
        props.setModalOpen(false);
    };

    return (
        <Modal
            destroyOnClose={true}
            title={!props.id ? "Create" : "Update"}
            open={props.isModalOpen}
            forceRender
            confirmLoading={confirmLoading}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Form
                key={props.id}
                form={form}
                {...formItemLayout}
                name="property"
                id="propertyForm"
                scrollToFirstError
            >
            <Form.Item
                name="name"
                label={`Name`}
                rules={[
                {
                    required: true,
                    message: 'Please input name',
                },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="order"
                label={`order`}
                rules={[
                {
                    required: true,
                    message: 'Please input order',
                },
                ]}
            >
                <InputNumber/>
            </Form.Item>
            <Form.Item label="Sale hours">
                <Space.Compact>
                    <Form.Item name="hoursFrom">
                        <TimePicker format={timeFormat} minuteStep={15} width={22}/>
                    </Form.Item>
                        &nbsp; -&nbsp; 
                    <Form.Item name="hoursTo" size={2}>
                        <TimePicker  format={timeFormat} minuteStep={15} />
                    </Form.Item>
                </Space.Compact>
            </Form.Item>
            <Form.Item
                name="type"
                label={`Type`}
                
            >
                <Input disabled="true"/>
            </Form.Item>
            </Form>

        </Modal>
    )
}