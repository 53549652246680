import React, { useState, useEffect } from 'react';
import {
    Radio,
    Layout,
    Select
  } from 'antd';
  
import { useCompany } from '../../hooks/CompanyProvider';
import WaiterTableList from './WaiterTableList';
import menuAPI from '../../http/apiMenu';
import userAPI from '../../http/apiUser';
import { PageHeader } from '@ant-design/pro-layout'

//const { Content } = Layout;
//const { Option } = Select;


export default function WaiterIndex(props){
    const [menus, setMenus] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState();
    
    const [waiters, setWaiters] = useState([]);
    const [selectedWaiter, setSelectedWaiter] = useState();

    const company = useCompany();


    useEffect(() => {
        if (!company.companyId) return;
        getMenuList();
        getWaitressList();
    }, [company.companyId]);

    const getMenuList = async() => {
    
        let menuList = await menuAPI.getListForCompany(company.companyId);
        setMenus(menuList || []);
     
        if (selectedMenu)
            setSelectedMenu(selectedMenu)
        else if (menuList && menuList.length > 0) {
            setSelectedMenu(menuList[0].id);
        }
    }

    const getWaitressList = async() => {
    
        let userList = await userAPI.getList('waiter');
        setWaiters(userList || []);
     
        if (selectedWaiter)
            setSelectedWaiter(selectedWaiter)
        else if (userList && userList.length > 0) 
            setSelectedWaiter(userList[0].id);
    }


    const onSelectMenu = async(e) => {
        setSelectedMenu(e.target.value);
    }

    const onSelectWaiter = async(e) => {
        setSelectedMenu(e.target.value);
    }

    return (
        
        <Layout style={{ height: "100vh", overflow: "auto" }} >
              <PageHeader
                className="site-page-header"
                title={'Assign waiters to tables'}
                subTitle="settings">
                    <div>
                    <Radio.Group
                        options={menus.map(l => { return { label: l.name, value: l.id} })}
                        onChange={onSelectMenu}
                        value={selectedMenu}
                        optionType="button"
                        buttonStyle="solid"
                    />
                    </div>
                   {/*  
                   <div className=''>
                        <div>Waiters</div>
                        <Radio.Group
                            options={waiters.map(l => { return { label: l.name, value: l.id} })}
                            onChange={onSelectWaiter}
                            value={selectedWaiter}
                            optionType="button"
                            buttonStyle="solid"
                            />
                    </div>
                    */}
            </PageHeader>
            
           
            <div className="px-7">
                {
                    selectedMenu 
                    ? <WaiterTableList tableList={ menus.find(x => x.id === selectedMenu).menuLocations } zoneList={menus.find(x => x.id === selectedMenu).menuZones} waiterList={waiters}  refresh={ getMenuList } />
                    : <></>
                }
                
            </div>

       </Layout>
        
        
    )
}