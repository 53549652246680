import { API_BASE_ENDPOINT, API_BASE_HEADER, parseCategory, parse } from '../http/apiConfig';

class CategoryAPI {

    getListForMenu = async(menuId) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/location/${menuId}/list`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return parse(data, [], parseCategory);
        }catch(e){
            console.error(e);
            return [];
        }
    }

    create = async(menuId, category) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/category/${menuId}`,
                await API_BASE_HEADER('POST', category)
            );
            let data = await response.json();
            return parse(data, {}, parseCategory);
        }catch(e){
            console.error(e);
            return null;
        } 
    }

    update = async(id, category) => {
        if (!id)
            return;
            
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/category/${id}`,
                await API_BASE_HEADER('PUT', category)
            );
            let data = await response.json();
            return parse(data, {}, parseCategory);
        }catch(e){
            console.error(e);
            return null;
        }   
    }

    get = async(id) => {
        
        if (!id)
            return;

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/category/${id}`,
                await API_BASE_HEADER('GET')
            );
            let data = await response.json();
            return parse(data, {}, parseCategory);
        }catch(e){
            console.error(e);
            return null;
        }
    }

    toggleDelete = async(id) => {
        if (!id)
            return;

        try {
            await fetch(
                `${API_BASE_ENDPOINT}/api/category/${id}/delete`,
                await API_BASE_HEADER('DELETE')
            );
            
        }catch(e){
            console.error(e);
            return null;
        }   
    }

    addProduct = async(id, productId, order) => {
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/category/${id}/product/${productId}/order/${order}`,
                await API_BASE_HEADER('PUT')
            );
            let data = await response.json();
            return parse(data, [], parseCategory);
        }catch(e){
            console.error(e);
            return null;
        }   
    }

    removeProduct = async(id, productId) => {
        try {
            await fetch(
                `${API_BASE_ENDPOINT}/api/category/${id}/product/${productId}`,
                await API_BASE_HEADER('DELETE')
            );
            
        }catch(e){
            console.error(e);
            return null;
        }   
    }

    updateProductOrder = async(id, productId, order) => {
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/category/${id}/product/${productId}/order/${order}`,
                await API_BASE_HEADER('PATCH')
            ); 
            let data = await response.json();
            return parse(data, [], parseCategory);
        }catch(e){
            console.error(e);
        }   
    }

    toggleProductVisibility = async(id, productId) => {
        try {
            await fetch(
                `${API_BASE_ENDPOINT}/api/category/${id}/product/${productId}/visible`,
                await API_BASE_HEADER('PATCH')
            ); 
            
           
        }catch(e){
            console.error(e);
        }   
    }

    toggleCategoryVisibility = async(id,) => {
        try {
            await fetch(
                `${API_BASE_ENDPOINT}/api/category/${id}/visible`,
                await API_BASE_HEADER('PATCH')
            ); 
            
        }catch(e){
            console.error(e);
        }   
    }


   
};

const categoryAPI = new CategoryAPI();
export default categoryAPI;