import React, { useState, useEffect } from 'react'
import userAPI from '../../http/apiUser';
import { useCompany } from '../../hooks/CompanyProvider';

import { LoadingOutlined, PlusOutlined  } from '@ant-design/icons';
import { Input, Upload, message, Form, Select, Modal  } from 'antd';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

export default function User(props) {
    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const [imageUrl, setImageUrl] = useState();
    const [imageLoading, setImageLoading] = useState(false);

    const company = useCompany();

    useEffect(()=> {
        getUser(props.userId);

    },[props.userId]);

    useEffect(()=>{
        getRoles();
    },[]);

    const getUser = async(userId) => {
        if (!props.userId)
            return;
        let user = await userAPI.get(userId);
        form.setFieldsValue({
            name: user.name,
            email: user.email,
            avatar: user.photoUrl,
            prefix: user.phone.split(' ')[0],
            roles: user.role,
            phone: user.phone.split(' ')[1],
            companies: user.companies.map(c => c.id)
        });
        setImageUrl(user.photoUrl);
    }

    function UploadImage({file, onSuccess }) {

        file.status = "uploading";
        setImageLoading(true);

        const uploadTask = firebase.storage().ref().child(`${company.companyId}/avatars/${file.uid}_${file.name}`).put(file);
        uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            snapshot => {
                file.percent =  ((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                //setFileProgress(file.uid, ((snapshot.bytesTransferred / snapshot.totalBytes) * 100))
                },
                error => { console.log(error.code); file.staus = "error"; },
                () => {
                    setImageLoading(false);
                    uploadTask.snapshot.ref
                            .getDownloadURL()
                            .then((downloadURL)=> {
                                //console.log(downloadURL)
                                setImageUrl(downloadURL);
                                
                                onSuccess("ok")
                            });
                });

                Promise.resolve(uploadTask);
    }

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
      }

    const getRoles = async() => {
        const roles = await userAPI.roles();
        setRoles(roles);
    }

    const saveUser = async(values) => {
        setConfirmLoading(true);
        
        let apiObj = {
            id: props.userId,
            name: values.name,
            email: values.email,
            password: values.password,
            phone: values.prefix + ' ' + values.phone,
            role: values.roles,
            photoUrl: imageUrl,
            companyIds: values.companies
        };

        let result;
        if (!props.userId){
            result = await userAPI.create(apiObj);
        }else{
            result = await userAPI.update(apiObj);
        }
        
        setConfirmLoading(false);
        if (result) {
            form.resetFields(); 
            props.setModalOpen(false);
            props.onSuccess(result);
        }
    }

    const handleOk = (values) => {

        form
          .validateFields()
          .then(values => {
           
            saveUser(values);

          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      };

    const handleCancel = () => {
        form.resetFields(); 
        props.setModalOpen(false);
      };

      const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select style={{ width: 90 }}>
            <Option value="+372">+372</Option>
            <Option value="+371">+371</Option>
            <Option value="+358">+358</Option>
            <Option value="+234">+234</Option>
            <Option value="+1">+1</Option>
          </Select>
        </Form.Item>
      );

     
      const uploadButton = (
        <div>
          {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      );

    return (
        <Modal
            destroyOnClose={true}
            title={!props.id ? "Create" : "Update"}
            open={props.isModalOpen}
            forceRender
            confirmLoading={confirmLoading}
            onOk={handleOk}
            onCancel={handleCancel}
        >
        <Form
            {...formItemLayout}
            form={form}
            name="userCreate"
            scrollToFirstError
            >
          
            <Form.Item
                name="email"
                label="E-mail"
                rules={[
                {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                },
                {
                    required: true,
                    message: 'Please input your E-mail!',
                },
                ]}
            >
                <Input />
            </Form.Item>
            {
                 (!props.userId)
                 ?
                 <>
                 <Form.Item
                name="password"
                label="Password"
                rules={[
                {
                    required: true,
                    message: 'Please input your password!',
                },
                ]}
                hasFeedback
            >
                <Input.Password />
            </Form.Item>
            
            <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                {
                    required: true,
                    message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject('The two passwords that you entered do not match!');
                    },
                }),
                ]}
            >
                <Input.Password />
            </Form.Item>
                 </>
                 : <></>
            }
            
            <Form.Item
                name="name"
                label={
                <span>
                    Full name
                </span>
                }
                rules={[{ required: true, message: 'Please input your nickname!', whitespace: true }]}
            >
                <Input />
            </Form.Item>
            
            <Form.Item
                name="phone"
                label="Phone Number"
                rules={[{ required: true, message: 'Please input your phone number!' }]}
            >
            <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item 
                name="roles"
                label="Role"
                rules={[{ required: true, message: 'Please select user role!' }]}
                >
                <Select>
                    {
                        roles.map(r => 
                            <Option key={r} value={r}>{r}</Option>
                        )
                    }
                </Select>
            </Form.Item>
            <Form.Item
                name="companies"
                label='Companies'
                
                >
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select company"
                    filterOption={(input, option) => (option.label.toLowerCase()).includes(input.toLowerCase())}
                    options={[...company.companyList.map(c => ({id: c.id, value: c.id, label: c.name, name: c.name}))]}
                >
                    
                </Select>
          </Form.Item>
            <Form.Item
                name="avatar"
                label="Avatar"
            >
            <Upload
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                customRequest={UploadImage}
            >
                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '70px' }} /> : uploadButton}
            </Upload>
            </Form.Item>
        </Form>
        </Modal>
    )
}