import { 
    formatCurrencty,
    DELIVERY_TAKEAWAY,
    DELIVERY_RESTAURANT,
    DELIVERY_TIME_NOW,
    DELIVERY_TIME_CUSTOM,
    PAYMENT_STRIPE,
    PAYMENT_POS,
    OPTION_SINGLE,
    OPTION_MULTIPLE,
    PAYMENT_PAID,
    PAYMENT_AWAIT,
    PAYMENT_ERROR,
    INSERTED,
    CONFIRMED,
    PROGRESS,
    READY,
    PAYED,
    DELIVERED,
    CANCELED,
    COMPLETED,
    OREDER_STATUS_BY_ORDER,
    OREDER_STATUS_BY_NAME,
    OREDER_STATUS_ACTION,
    OPTION_SINGLE_TYPE,
    OPTION_CHECK_TYPE,
    PRODUCT_TYPE,
    PRODCUT_ALLERGY_TYPE,
    PRODCUT_CATEGORY_TYPE,
    PRODCUT_SPICY_TYPE
 } from '../Common';

 export const filterOrdersAndProducts = (orders, zones, filter, statuses) => {

    let ordersCopy = JSON.parse(JSON.stringify(orders));
    /*
    {
                        o.Delivery.DeliveryType === DELIVERY_RESTAURANT
                        ? <div className="absolute top-2 -right-1"><TableIcon className="h-3.5"/></div>
                        : <div className="absolute top-2 right-1"><BagIcon className="h-4"/></div>
                    }
                    </div>
                    <div className="h-8 flex justify-between border-b px-3 pt-1 pb-1">
                        <div className="font-bold">{o.Delivery.MenuLocationName}</div>
    */
    // group orders from same table into one
    const groupedByTable = ordersCopy.filter(o =>  o.Delivery.DeliveryType === DELIVERY_RESTAURANT && [CONFIRMED, PROGRESS].includes(o.Status)).reduce((map, o)=>{
        if (!map[o.Delivery.MenuLocationName])
            map[o.Delivery.MenuLocationName] = [];

        map[o.Delivery.MenuLocationName].push(o);
        return map;
    }, {});

    return ordersCopy.filter(o => {
        let include = true;
        if (filter.zones && filter.zones.length > 0) {
            const qrs = zones.filter(z => filter.zones.includes(z.id)).map(z => z.qrs).flat();
            include = qrs.includes(o.Company.Qr); 
        }
        if (filter.types && filter.types.length > 0) {
            let filteredProducts = Object.values(o.Products).filter(o => filter.types.includes(o.Type) /*&& [INSERTED, PROGRESS].includes(o.Status)*/);  
            include = filteredProducts.length > 0;
        }
        
        
        return include;
    })
    .map((o) => {

        if (filter.types && filter.types.length > 0) {
            let filteredProducts = Object.values(o.Products).filter(o => filter.types.includes(o.Type));    
            if (filteredProducts.length > 0){
                o.Products = filteredProducts.reduce((map, obj) => {
                    map[obj.Id] = obj;
                    return map;
                }, {})
            } else {
                o.Products = []; 
            }
        }

        if (o.Products.length == 0)
            return null;

        return o;
    }).filter(o => o !== null);
} 

export const itemStatusColor = (item) => {
    if (item.Status === INSERTED) {
        return 'bg-red';
    } 
    if (item.Status === CONFIRMED) {
      return 'bg-yellow-400';
    } 
    else if (item.Status === PROGRESS) {
        return 'bg-yellow-400';
    }
    else if (item.Status === READY) {
      return 'bg-green-700';
    }
    else if (item.Status === DELIVERED) {
      return 'border-4';
    }
}



export const getOrderTime = (o) => new Date(o.Created.seconds * 1000);