import React, { useEffect, useState } from 'react'
import { 
    DELIVERY_RESTAURANT,
    INSERTED,
    CONFIRMED,
    PROGRESS,
    READY,
    DELIVERED,
    PRODUCT_TYPE,
 } from '../Common';
 import { useCompany } from '../../hooks/CompanyProvider';
 /*
 import orderAPI from '../../http/apiOrder';
 import posAPI from '../../http/apiPos';
 import { ReactComponent as BagIcon } from '../../assets/mulle_bag_icon.svg';
 import { ReactComponent as TableIcon } from '../../assets/mulle_table_icon.svg';
 import { ReactComponent as ClockIcon } from '../../assets/mulle_clock_icon.svg';
 import { ReactComponent as HomeIcon } from '../../assets/mulle_home_icon.svg';
 import { ReactComponent as UserIcon } from '../../assets/mulle_profile_icon.svg';
 */
 import { useFirestore,  useFirestoreCollection } from 'reactfire';
 //import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
 import logo from '../../assets/mulle_logo_color.svg';
 import { Tag, Skeleton } from 'antd';
 import zoneAPI from '../../http/apiZone';
 import productPropertyAPI from '../../http/apiProductProperty';
 //import Timer, {getClassColor} from './OrderTimer';
 import { useLocalStorage } from '../../hooks/LocalStorageProvider';
 import {  getOrderTime } from './OrderCommon';
 import ringer from "../../assets/media/new_order.mp3";
 import OrderCard from "./OrderCard";
 import { collection, query, where, orderBy, limit } from "firebase/firestore";
 import CallToWaiter from '../CallToWaiter';
 //const { Option } = Select;
 const { CheckableTag } = Tag;


export default function OrderCards(){
    const audio = new Audio(ringer);

    const [loading, setLoading] = useState(true);
    const [zones, setZones] = useState([]);
    //const [toggleOrder, setToggleOrder] = useState({});
    const [types, setTypes] = useState({});
    
    const company = useCompany();
    const companyId = company.companyId;

    const [filter, setFilter] = useLocalStorage(`orderFilter_${companyId}`, { types: [], zones: []});
    
    const firestore = useFirestore();
    const firestoreCollection = collection(firestore, 'orders');

    const [localOrders, setLocalOrders] = useState({});
   

    const { status, data: snapshot } = useFirestoreCollection(query(firestoreCollection,
                                        where("Company.CompanyId", "==", companyId),
                                        where("CardPaymentInProgress", "==", false),
                                        where("Status", "in", [INSERTED, CONFIRMED, PROGRESS, READY, DELIVERED]),
                                        orderBy('Created', 'desc'),
                                        limit(100))
                                        , {
                                            idField: 'id'
                                        });

    const notifyMe = () => {
        
        
        if (!("Notification" in window)) {
         
          // Check if the browser supports notifications
          //alert("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
          // Check whether notification permissions have already been granted;
          // if so, create a notification
          const n = new Notification("New order received!", { requireInteraction: true, icon: logo, silent: true, renotify: false  });
          audio.loop = false;
          audio.play();
          // …
        } else if (Notification.permission !== "denied") {
          // We need to ask the user for permission
          Notification.requestPermission().then((permission) => {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
              const n = new Notification("New order received!", { requireInteraction: true, icon: logo, silent: true, renotify: false });
        
              // …
            }
          });
        } else if (Notification.permission === "denied") {
        
        }
      
        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them anymore.
      }

    useEffect(() => {
        if (!company.companyId) return;
       getFilters();
       
     }, [company.companyId]);


     useEffect(() => {
        if (orders().length > 0){
            snapshot.docChanges().map((change) => {
                console.log(change.type);
                if (change.type === "added") {
                    //console.log(change.type);
                    const doc = change.doc.data();
                    //if (!localOrders[doc.Id]) {
                        notifyMe();
                       // const copied = {...localOrders};
                        //copied[doc.Id] = true;
                        //setLocalOrders(copied);
                        
                   // }
                }
                /* if (change.type === "modified") {
                        let doc = change.doc.data();
                        let copied = {...localOrders};
                        copied[doc.Id] = doc;
                        setLocalOrders(copied);
                }*/
                if (change.type === "removed") {
                        const doc = change.doc.data();
                        //const copied = {...localOrders};
                        //if (copied[doc.Id]){
                        //    delete copied[doc.Id];
                            //(copied);
                        //}
                }
            });

            document.title = `(${orders().length}) Orders`;
        }
        else 
            document.title = 'Orders';
            
     }, [orders().length])

    function orders() { return snapshot.docs.map(d => d.data()); } //snap.docs.map(d => d.data());

    const getFilters = async() => {
        setLoading(true);
        try {
            let zoneList = await zoneAPI.getListForCompany(companyId);
            zoneList = zoneList.map(z => {
                return {
                    id: z.id,
                    name: z.name,
                    qrs: z.menuLocations.map(m => m.data.qr)
                }
            }).sort((a, b) => a.name.localeCompare(b.name));
            
            setZones(zoneList);
            
            let apiProperties = await productPropertyAPI.getListProperties(company.companyId);
            setTypes(apiProperties.filter(p => p.type === PRODUCT_TYPE).sort((a,b) => (a.data.order ? a.data.order : 99) - ((b.data.order ? b.data.order : 99))).reduce((map, obj) => {
                map[obj.id] = obj;
                return map;
            },{}));
        }catch(e){
            console.log(e);
        }finally{
            setLoading(false);
        }
       
    }

    const filterOrdersAndProducts = (orders) => {

        let ordersCopy = JSON.parse(JSON.stringify(orders));
        /*
        const groupedByTable = ordersCopy.filter(o =>  o.Delivery.DeliveryType === DELIVERY_RESTAURANT && [CONFIRMED, PROGRESS].includes(o.Status)).reduce((map, o)=>{
            if (!map[o.Delivery.MenuLocationName])
                map[o.Delivery.MenuLocationName] = [];

            map[o.Delivery.MenuLocationName].push(o);
            return map;
        }, {});
        */
        return ordersCopy.filter(o => {
            let include = true;
            if (filter.zones && filter.zones.length > 0) {
                const qrs = zones.filter(z => filter.zones.includes(z.id)).map(z => z.qrs).flat();
                include = qrs.includes(o.Company.Qr); 
            }
            if (filter.types && filter.types.length > 0) {
                let filteredProducts = Object.values(o.Products).filter(o => filter.types.includes(o.Type) && [INSERTED, PROGRESS].includes(o.Status));  
                include =  include && filteredProducts.length > 0;
            }
            
            
            return include;
        })
        .map((o) => {

            if (filter.types && filter.types.length > 0) {
                let filteredProducts = Object.values(o.Products).filter(o => filter.types.includes(o.Type));    
                if (filteredProducts.length > 0){
                    o.Products = filteredProducts.reduce((map, obj) => {
                        map[obj.Id] = obj;
                        return map;
                    }, {})
                } else {
                    o.Products = []; 
                }
            }

            if (o.Products.length == 0)
                return null;

            return o;
        }).filter(o => o !== null);
    } 

    const handleTypeChange = (typeId, checked) => {
        const selectedTypes = checked ? [...filter.types, typeId] : filter.types.filter(t => t !== typeId);
        setFilter({...filter, types: selectedTypes})
    }

    const handleZoneChange = (zoneId, checked) => {
        const selectedZones = checked ? [...filter.zones, zoneId] : filter.zones.filter(t => t !== zoneId);
        setFilter({...filter, zones: selectedZones})
    }

    return loading 
    ? <Skeleton active></Skeleton> 
    : <div className="p-3 bg-white h-full overflow-auto ">
        <div className='flex justify-between'>
            <div>
                <div className="flex flex-col">
                    <div className="text-gray-400 text-sm">Table zones</div>
                    <div className="flex pt-1 pl-3">
                    {
                    zones
                    .map(a => <div className="p-1">
                        <CheckableTag key={a.id} onChange={checked => handleZoneChange(a.id, checked)} checked={filter.zones.indexOf(a.id) > -1}><div className="text-base">{a.name}</div></CheckableTag>
                    </div>) 
                }
                    </div>
                </div>
                <div className="flex flex-col pt-1">
                    <div className="text-gray-400 text-sm">Product types</div>
                    <div className="flex pt-1 pl-3">
                    { 
                        Object.values(types)
                        .filter(p => p.type === PRODUCT_TYPE)
                        .map(a => <div className="p-1">
                            <CheckableTag onChange={checked => handleTypeChange(a.id, checked)} checked={filter.types.indexOf(a.id) > -1} key={a.id}><div className="text-base">{a.data.name}</div></CheckableTag>
                        </div>) 
                    }
                    </div>
                </div>
            </div>
            <div>
                <CallToWaiter useDrawer={true}/>
            </div>
        </div>
    {/*
    <div className="grid lg:grid-cols-4 md:grid-cols-4">
            <StatisticsCard title="New" status={[INSERTED, CONFIRMED]} />
            <StatisticsCard title="In Progress" status={[PROGRESS]} />
            <StatisticsCard title="Ready For Serving/Pickup" status={[READY]} />
            <StatisticsCard title="Unpaid" status={['UNPAID']} />
            
    </div> 
    */}
    <div className="flex justify-between pt-3 space-x-1">
        
        <div key={'new'} className="flex flex-col w-60">
            <div className="text-center text-lg border-0 border-solid  border-b-4 border-red">New</div>
            {
                    filterOrdersAndProducts(orders()).filter(o => [INSERTED].includes(o.Status)).length > 0 
                    ? filterOrdersAndProducts(orders()).filter(o => [INSERTED].includes(o.Status)).sort((a, b) => getOrderTime(a) - getOrderTime(b)).map(o => <OrderCard types={types} company={company} o={o} />)
                    : <div className="text-gray-300">No new orders</div>
            }
        </div>
        <div key={'progress'} className="flex-grow">
            <div className="text-center text-lg border-0 border-solid border-b-4 border-yellow-500">In Progress</div>
            <div className={`flex flex-wrap`}>    
            {
                filterOrdersAndProducts(orders()).filter(o => [CONFIRMED, PROGRESS].includes(o.Status)).sort((a, b) => getOrderTime(a) - getOrderTime(b)).map(o => <OrderCard types={types} company={company} o={o} />)
            }
            </div>
            
        </div>
        <div key={'ready'} className="flex flex-col w-60">
            <div className="text-center text-lg border-0 border-solid border-b-4 border-green-500">Ready</div>
            {
               filterOrdersAndProducts(orders()).filter(o => [READY, DELIVERED].includes(o.Status)).sort((a, b) => getOrderTime(a) - getOrderTime(b)).map(o => <OrderCard types={types} company={company} o={o} />)
            }
        </div>
    </div>
        
    </div>
    
}
