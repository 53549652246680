import React, { useState, useEffect } from 'react'
import { 
    formatCurrencty,
    DELIVERY_TAKEAWAY,
    DELIVERY_RESTAURANT,
    DELIVERY_TIME_NOW,
    DELIVERY_TIME_CUSTOM,
    PAYMENT_STRIPE,
    PAYMENT_POS,
    OPTION_SINGLE,
    OPTION_MULTIPLE,
    PAYMENT_PAID,
    PAYMENT_AWAIT,
    PAYMENT_ERROR,
    INSERTED,
    CONFIRMED,
    PROGRESS,
    READY,
    PAYED,
    DELIVERED,
    CANCELED,
    COMPLETED,
    PRODUCT_TYPE,
    OREDER_STATUS_BY_ORDER,
    OREDER_STATUS_BY_NAME,
    OREDER_STATUS_ACTION,
    ORDERS_ROLE_SERVE,
    ORDERS_ROLE_COOK
 } from '../Common';
 import { Tag, Skeleton, Radio } from 'antd';
 import { useCompany } from '../../hooks/CompanyProvider';
 import orderAPI from '../../http/apiOrder';
 import { useLocalStorage } from '../../hooks/LocalStorageProvider';
 import zoneAPI from '../../http/apiZone';
 import productPropertyAPI from '../../http/apiProductProperty';
 import { useFirestore, useFirestoreCollectionData } from 'reactfire';
 import { itemStatusColor, getOrderTime } from './OrderCommon';
 import { collection, query, where, orderBy, limit } from "firebase/firestore";

 const { CheckableTag } = Tag;

export default function OrderItems(){

    const company = useCompany();
    const companyId = company.companyId;

    const [loading, setLoading] = useState(true);
    const [chaningStatusOfItem, setChaningStatusOfItem] = useState({});
    const [zones, setZones] = useState([]);
    const [types, setTypes] = useState({});
    const [filter, setFilter] = useLocalStorage(`itemsFilter_${companyId}`, { types: [], zones: [], selectedRoles: [ORDERS_ROLE_COOK] });

    const PRODUCT_STATUS_COOK = [INSERTED, CANCELED, CONFIRMED, PROGRESS, READY];
    const PRODUCT_STATUS_COOK_COMPLETE = [READY];

    const PRODUCT_STATUS_SERVE = [READY, DELIVERED];
    const PRODUCT_STATUS_SERVE_COMPLETE = [DELIVERED];

    const firestore = useFirestore();
    const firestoreQuery = collection(firestore, 'orders');
    
    const { status, data: firestoreOrders } = useFirestoreCollectionData(query(firestoreQuery
        ,where("Company.CompanyId", "==", companyId)
        ,where("CardPaymentInProgress", "==", false)
        ,where("Status", "in", [INSERTED, CONFIRMED, PROGRESS, READY, DELIVERED])
        ,orderBy('Created', 'desc')
        ,limit(100)), {
            idField: 'id'
        });

      useEffect(() => {
        getFilters();
     }, []);

     const getFilters = async() => {
        setLoading(true);
         try {
            let zoneList = await zoneAPI.getListForCompany(companyId);
            zoneList = zoneList.map(z => {
                return {
                    id: z.id,
                    name: z.name,
                    qrs: z.menuLocations.map(m => m.data.qr)
                }
            }).sort((a, b) => a.name.localeCompare(b.name));
            
            setZones(zoneList);
            
            let apiProperties = await productPropertyAPI.getListProperties(company.companyId);
            setTypes(apiProperties.filter(p => p.type === PRODUCT_TYPE).sort((a,b) => (a.data.order ? a.data.order : 99) - ((b.data.order ? b.data.order : 99))).reduce((map, obj) => {
                map[obj.id] = obj;
                return map;
            },{}));
            
         }catch(e) {
            console.log(e);
         } finally {
            setLoading(false);
         }
        
    }

    const handleTypeChange = (typeId, checked) => {
        const selectedTypes = checked ? [...filter.types, typeId] : filter.types.filter(t => t !== typeId);
        setFilter({...filter, types: selectedTypes})
    }

    const handleZoneChange = (zoneId, checked) => {
        const selectedZones = checked ? [...filter.zones, zoneId] : filter.zones.filter(t => t !== zoneId);
        setFilter({...filter, zones: selectedZones})
    }

    const handleRoleChange = (role, checked) => {
        const selectedRoles = checked ? [...filter.selectedRoles, role] : filter.selectedRoles.filter(t => t !== role);
        setFilter({...filter, selectedRoles: selectedRoles})
    }

    const changeItemStatus = async(orderId, itemId) => {
        const key = `${orderId}_${itemId}`;

        setChaningStatusOfItem({ ...chaningStatusOfItem, [key]: true });

        const order = await orderAPI.changeItemStatus(orderId, itemId);
        if (!order) {
            console.log(`order: ${orderId} status update failed`);
        }
        
        const newChaningStatusOfItem = { ...chaningStatusOfItem };
        delete newChaningStatusOfItem[key];
        setChaningStatusOfItem(newChaningStatusOfItem);
      }

      const filterOrdersAndProducts = (orders) => {

        let ordersCopy = JSON.parse(JSON.stringify(orders));

        const filteredOrders = ordersCopy.filter(o => {
            let include = true;
            if (filter.zones && filter.zones.length > 0) {
                const qrs = zones.filter(z => filter.zones.includes(z.id)).map(z => z.qrs).flat();
                include = qrs.includes(o.Company.Qr); 
            }
            if (filter.types && filter.types.length > 0) {
                let filteredProducts = Object.values(o.Products).filter(o => filter.types.includes(o.Type) /*&& [INSERTED, PROGRESS].includes(o.Status)*/);  
                include = include && filteredProducts.length > 0;
            }
           
            
            return include;
        })
        .map((o) => {

            if (filter.types && filter.types.length > 0) {
                let filteredProducts = Object.values(o.Products).filter(o => filter.types.includes(o.Type));    
                if (filteredProducts.length > 0){
                    o.Products = filteredProducts.reduce((map, obj) => {
                        map[obj.Id] = obj;
                        return map;
                    }, {})
                } else {
                    o.Products = []; 
                }
            }

            if (o.Products.length == 0)
                return null;

            return o;
        }).filter(o => o !== null);
        
        productsByTableAndType(filteredOrders);

        return filteredOrders;
    } 

    const productsByTableAndType = (orders) =>{
        const groupedByTableOrders = orders.filter(o =>  o.Delivery.DeliveryType === DELIVERY_RESTAURANT && [CONFIRMED, PROGRESS, READY].includes(o.Status)).reduce((map, o)=>{
            if (!map[o.Delivery.MenuLocationName])
                map[o.Delivery.MenuLocationName] = [];

            map[o.Delivery.MenuLocationName].push(o);
            return map;
        }, {});

        //console.log(groupedByTableOrders)

        let products = {};
       
        Object.entries(groupedByTableOrders).map(([table, orders]) => {
            orders.map(o => {
                Object.values(o.Products).map(p => {
                    const key = `${table}_${types[p.Type].data.name}`;
                    if (!products[key])
                        products[key] = [];
                    
                    p.OrderId = o.Id;
                    products[key].push(p);
                });
            }) 
        });
        
        let statusesToInclude = [];
        if (filter.selectedRoles && filter.selectedRoles.length > 0) {
            statusesToInclude = filter.selectedRoles.map(s => {
                if (s === ORDERS_ROLE_COOK)
                    return PRODUCT_STATUS_COOK;

                if (s === ORDERS_ROLE_SERVE)
                    return PRODUCT_STATUS_SERVE;

                return [];
            }).filter(s => s).flat();
            
            for (const key in products) {
                products[key].sort((a,b) => a.Id.localeCompare(b.Id));
               
                if (!products[key].every(p => statusesToInclude.includes(p.Status)) 
                    || (filter.selectedRoles.includes(ORDERS_ROLE_COOK) && products[key].every(p => PRODUCT_STATUS_COOK_COMPLETE.includes(p.Status))) 
                    || (filter.selectedRoles.includes(ORDERS_ROLE_SERVE) && products[key].every(p => PRODUCT_STATUS_SERVE_COMPLETE.includes(p.Status)))
                    //|| !(filter.selectedRoles.includes(ORDERS_ROLE_COOK) && filter.selectedRoles.includes(ORDERS_ROLE_SERVE) && products[key].filter(p => statusesToInclude.includes(p.Status)).length > 0)
                ){
                    delete products[key]
                } 
            }
        }
        
        return products;
    } 

    const getProductsSummary = (orders) => {
        const products = Object.entries(productsByTableAndType(orders)).map(([key, ps]) => ps)
            .flat()
            .reduce((map, obj) => {
                
                if (!map[obj.ProductId])
                    map[obj.ProductId] = [];

                map[obj.ProductId].push(obj)

                return map;
            },{});
        
        const productsCount = Object.entries(products)
            .map(([k,ps])  => {return {
                Count: ps.reduce((sum, obj) => sum + obj.Count, 0),
                Id: ps[0].Id,
                Name: ps[0].Name[company.language],
                Type: ps[0].Type
            }})
            .sort((a, b) => b.Count - a.Count);

        return productsCount.map(p => StatisticsCard(p.Name, p.Count))
      }

    const StatisticsCard = (title, value, suffix) => {
        return <div className="p-1 w-32 h-28">
                <div className="rounded-lg bg-white shadow-md p-2 h-full">
                    <div className="text-sm font-semibold text-gray-400">
                    {`${value ? value : '0'} ${suffix ? suffix : ''}`}
                    </div>
                    <div className="text-xs font-medium text-gray-600 dark:text-gray-400  h-16 overflow-hidden pt-3">
                        {title}
                    </div>
                </div>
                </div>
    }

    const getTableTypeGroupName = (table_key) => {
          let ret = '';

          const splitted = table_key.split('_');
          ret = <div className="flex">
              <div className="text-gray-500 text-">{`${splitted[0]}`}</div>
              <div className=" pl-3">{`${splitted[1]}`}</div>
              
          </div>

          return ret;
      }

    return loading ? <Skeleton active></Skeleton> 
        : <div className="p-3 bg-white">
            <div className="flex flex-col">
                <div className="text-gray-400 text-sm">Table zones</div>
                <div className="flex pt-1 pl-3">
                {
                zones
                .map(a => <div className="p-1">
                    <CheckableTag onChange={checked => handleZoneChange(a.id, checked)} checked={filter.zones.indexOf(a.id) > -1} key={a.id}><div className="text-base">{a.name}</div></CheckableTag>
                </div>) 
            }
                </div>
            </div>
            <div className="flex flex-col pt-1">
                <div className="text-gray-400 text-sm">Product types</div>
                <div className="flex pt-1 pl-3">
                { 
                    Object.values(types)
                    .filter(p => p.type === PRODUCT_TYPE)
                    .map(a => <div className="p-1">
                        <CheckableTag onChange={checked => handleTypeChange(a.id, checked)} checked={filter.types.indexOf(a.id) > -1} key={a.id}><div className="text-base">{a.data.name}</div></CheckableTag>
                    </div>) 
                }
                </div>
            </div>
            <div className="flex flex-col pt-1">
                <div className="text-gray-400 text-sm">Readiness</div>
                <div className="flex space-x-5 pt-1 pl-3">
                { 
                    [ORDERS_ROLE_COOK, ORDERS_ROLE_SERVE]
                    .map(a => <div className="p-1">
                        <CheckableTag onChange={checked => handleRoleChange(a, checked)} checked={filter.selectedRoles.indexOf(a) > -1} key={a}><div className="text-base">{a}</div></CheckableTag>
                    </div>) 
                }
                </div>
            </div>
            <div className="flex pt-3">
                <div className={`pr-3`}>
                {
                    Object.entries(productsByTableAndType(
                        filterOrdersAndProducts(firestoreOrders)
                            .sort((a, b) => getOrderTime(a) - getOrderTime(b))
                        )).map(([table_type, products]) => 
                            <div className="border-l-4 mt-3 mb-3 border-green-500">
                                <div className="font-bold whitespace-nowrap pl-3">{getTableTypeGroupName(table_type)}</div>
                                {
                                products.sort((a,b) => b.Id.localeCompare(a.Id)).map(i =>
                                    <div className="h-10 pt-2.5 pl-3" key={i.Id}>
                                    <div className="flex space-x-5 w-full">
                                        
                                        <div className="px-3" onClick={() => changeItemStatus(i.OrderId, i.Id)}>
                                        {  
                                            <div className={`h-5 w-5 rounded-full ${itemStatusColor(i)}`} >
                                            {
                                                chaningStatusOfItem[`${i.OrderId}_${i.Id}`] === true
                                                ?<svg className="animate-spin mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                : ''
                                            }
                                            </div>     
                                        }
                                        </div>           
                                        <div className="w-11/12">{i.Count} x {i.Name[company.language]}</div>
                                    </div>
                                    </div>
                                )
                                }
                            </div>
                            )
                }
                </div>
                <div className="p-2 w-1/3">
                    <div className="flex flex-wrap ">
                    {
                        getProductsSummary(filterOrdersAndProducts(firestoreOrders))
                    }
                    </div>
                </div>
            </div>
        </div>
    
   
}
