import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';
import productAPI from '../../http/apiProduct';
import translateAPI from '../../http/apiTranslate';
import productPropertyAPI from '../../http/apiProductProperty';
import { useCompany } from '../../hooks/CompanyProvider';
import { useProduct } from '../../hooks/ProductProvider';
import ProductIngridient from './ProductIngridient';

import { useStorage } from 'reactfire';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import { 
  formatPrice, 
  getNet, 
  getGross,
  PRODUCT_TYPE,
  PRODUCT_PROMOTION,
  PRODCUT_ALLERGY_TYPE,
  PRODCUT_CATEGORY_TYPE,
} from '../Common';
import Resizer from "react-image-file-resizer";

import {
  Modal,
  Form,
  Input,
  Select,
  Upload,
  InputNumber,
  message,
  Divider,
  Switch,
  Radio,
  Button,
  AutoComplete
} from 'antd';

import { PlusOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useMulleUser } from '../../hooks/UserProvider';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const NAME_PROPERTY = "name";
const DESC_PROPERTY = "description";
const OPTION_NAME_PROPERTY = "name";


export default function Product(props){
    
    const user = useMulleUser();
    const company = useCompany();
    const products = useProduct();

    const [form] = Form.useForm();

    const [productView, setProductView] = useState('product_view');

    const [lang, setLang] = useState();
    const [confirmLoading, setConfirmLoading] = useState(false);
    
    const [properties, setProperties] = useState([]);
    const [options, setOptions] = useState([]);

    const [product, setProduct] = useState({});
    const [relatedProducts, setRelatedProducts] =useState([]);
    const [images, setImages] = useState([]);
    const [smallImages, setSmallImages] = useState([]);
    const [productIntegrationsSearch, setProductIntegrationsSearch] = useState([]);
    const [productIntegrationsAdditionSearch, setProductIntegrationsAdditionSearch] = useState([]);
    const [customOptions, setCustomOptions] = useState([]);

    const [externalId, setExternalId] = useState('');

    const storage = useStorage();

    useEffect(()=>{
      getProperties();
      getOptions();
      
      if (props.productId && props.isModalOpen === true) {
        getProduct(props.productId);
      }
      else
        setLang(company.language);
          
    },[props.isModalOpen])

    useEffect(() => { 
      setFormValues(product, true);
    }, [lang]);

    const getProperties = async() => {
      let apiProperties = await productPropertyAPI.getListProperties(company.companyId);
      setProperties(apiProperties);
    }
    
    const getOptions = async() => {
      let apiOptions = await productAPI.getListOptions();
      setOptions(apiOptions);
    }

    async function getProduct(id) {
      let apiProduct = await productAPI.get(id);
      setProduct(apiProduct);
      setFormValues(apiProduct);
      setLang(company.language);
    }

    const selectedLanguage = () => {
      if (lang && lang.length > 0)
        return lang.toUpperCase();

      return '';
    }

    function getPopular(product) {
      return product.data && (product.data.popular || false); 
    }

    function getPromote(product) {
      return product.data && (product.data.promote || false); 
    }

    function getAllergyFromProduct(product){
      return product.properties && product.properties.length > 0 ? product.properties.filter(p => p.type === PRODCUT_ALLERGY_TYPE || p.type === PRODCUT_CATEGORY_TYPE).map(p => p.id) : [];
    }

    function getTypesFromProduct(product){
      let type = product.properties && product.properties.length > 0 ? product.properties.filter(p => p.type === PRODUCT_TYPE || p.type === PRODUCT_PROMOTION).map(p => p.id) : [];
      if (type.length > 0)
        return type[0];
    }

    function getCustomOptions(product) {
       return product.options && product.options.length > 0 ? product.options : [];
    }

    function getImagesFromProduct(product){
      return product.data && product.data.images ? product.data.images.filter(i => i.small === false || !i.small) : [];
    }

    function getSmaillImagesFromProduct(product){
      return product.data && product.data.images ? product.data.images.filter(i => i.small === true) : [];
    }

    function getPriceFromProduct(product){
      return product.data.net;
    }

    function getDiscountNetPriceFromProduct(product){
      return product.data.discountPrice;
    }

    function getDiscountGrossPriceFromProduct(product){
      return product.data.discountGross || getGross(getDiscountNetPriceFromProduct(product), company.vat);
    }

    function getGrossPriceFromProduct(product){
      return product.data.gross || getGross(getPriceFromProduct(product), company.vat);
    }

    function getNameFromProduct(product) {
      return product.data && product.data.name && product.data.name[lang] ? product.data.name[lang] : '';
    }

    function getDescriptionFromProduct(product){
      return product.data && product.data.description && product.data.description[lang] ? product.data.description[lang] : '';
    }

    function getOfferRelations(product){
      return product.productRelations && product.productRelations.length > 0 ? product.productRelations : [];
    }

    function getExternalId(product) {
      return product.externalId;
    }

    function setFormValues(product, languageChange = false){
      form.setFieldsValue({
        name: getNameFromProduct(product),
        description: getDescriptionFromProduct(product),
        stock: product.stock,
        popular: getPopular(product),
        promote: getPromote(product),
        externalId: getExternalId(product),
        //relatedProductOfferText: getOfferTextRelatedProduct(product),
        images:[],
        language: lang,
      });
      setExternalId(getExternalId(product));
      

      if (languageChange === false){
        form.setFieldsValue({
          images:  getImagesFromProduct(product),
          price:  getPriceFromProduct(product),
          priceGross: getGrossPriceFromProduct(product),
          stock: product.stock,
          discountPrice: getDiscountNetPriceFromProduct(product),
          discountPriceGross: getDiscountGrossPriceFromProduct(product),
        //  relatedProduct: getOfferIdRelatedProduct(product),
        //  relatedProductPrice: getOfferPriceRelatedProduct(product),
          allergy: getAllergyFromProduct(product),
          type: getTypesFromProduct(product),
          popular: getPopular(product),
          promote: getPromote(product),
        });
        
        priceUpdate(getPriceFromProduct(product), null);
        setImages(getImagesFromProduct(product));
        setSmallImages(getSmaillImagesFromProduct(product));
        setCustomOptions(getCustomOptions(product));
        setRelatedProducts(getOfferRelations(product))
      }
    }

    async function ProcessImage(obj){
      ProduceResizedImage(obj.file);
      ProduceSmallImage(obj.file);
    }

    function UploadImage({file, onSuccess, uid, isSmall }) {

      
      const newRef = ref(storage, `${company.companyId}/images/${isSmall === true ? '/small/' : ''}${file.uid || uid}_${file.name}`);
      const uploadTask = uploadBytes(newRef, file);
      uploadTask.then((e) => {
        getDownloadURL(newRef)
        .then((downloadURL) => {
            file.url = downloadURL;
            if (isSmall === true)
              setSmallImages([...smallImages, { uid: `${file.uid || uid}${isSmall === true ? '_small' : ''}`, url: downloadURL, small: isSmall === true ? true : false }])
            else
              setImages([...images, { uid: `${file.uid || uid}${isSmall === true ? '_small' : ''}`, url: downloadURL, small: isSmall === true ? true : false }]);
    
            if (onSuccess) onSuccess("ok")
        });
      });

      file.status = "uploading";
    
      Promise.resolve(uploadTask);
    }

    const checkImageWidth = async (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const image = new Image();
          image.src = event.target.result;
          image.onload = () => {
            resolve(image);
            return image;
          };
          reader.onerror = (err) => reject(err);
        };
      });
    };

    function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
      var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
      return { width: srcWidth*ratio, height: srcHeight*ratio };
    }

    async function ProduceResizedImage(file){
      try {

        const image = await checkImageWidth(file);
        const fit = calculateAspectRatioFit(image.width, image.height, 1000, 600);

        let width = fit.width;
        let height = fit.height;

        Resizer.imageFileResizer(
            file,
            width,
            height,
            "JPEG",
            85,
            0,
            (uri) => {
              UploadImage({file: uri, uid: file.uid, isSmall: false})
            },
            "file",
            300,
            200
          );
      }catch(err){
        console.log(err);
      }
    }

    function ProduceSmallImage(file) {
        try {
          Resizer.imageFileResizer(
              file,
              400,
              300,
              "JPEG",
              20,
              0,
              (uri) => {
               UploadImage({file: uri, uid: file.uid, isSmall: true})
              },
              "file",
              300,
              200
            );
        }catch(err){
          console.log(err);
        }
    }

    function setLocalizedProperty(key, lang, value){
      let p = product;
      
      if (!p){
        p = {};
      }

      if (!p['data']){
        p['data'] = {};
      }

      if (!p['data'][key]){
        p['data'][key] = {};
      }
      
      if (!p['data'][key][lang]){
        p['data'][key][lang] = '';
      }

      p['data'][key][lang] = value; 

      setProduct({
        ...p
      });
    }

    function setLocalizedProductOfferText(id, lang, value){
      let p = relatedProducts.find(p => p.productId == id);
      
      if (!p){
        return;
      }

      if (!p['data']){
        p['data'] = {};
      }
      
      if (!p.data.offerText){
        p.data.offerText = {};
      }

      if (!p.data.offerText){
        p.data.offerText = {};
      }

      if (!p.data.offerText[lang]){
        p.data.offerText[lang] = '';
      }

      p.data.offerText[lang] = value; 

      //console.log(p);
      updateRelatedProducts(p);
    }

    function setLocalizedPropertyOption(id, key, lang, value) {
      let p = customOptions.find(o => o.id === id);
      
      if (!p){
        p = {};
      }

      if (!p[key]){
        p[key] = {};
      }

      if (!p[key][lang]){
        p[key][lang] = '';
      }

      p[key][lang] = value; 

      updateOption(p)
    }

    function setLocalizedPropertyOptionsOption(id, optionId, key, lang, value) {
      let p = customOptions.find(o => o.id === id);
      p.options = p.options.map(o => {
        if (o.id === optionId){
          if (!o[key][lang]) { o[key][lang] = ''; }
          o[key][lang] = value;
          return o;
        }
        return o;
      });

      updateOption(p);
    }

    const onAutoTranslateClick = async() => {
      const languages = company.languages.filter(l => l.key !== lang).map(l => l.key);

      const name = product.data[NAME_PROPERTY] ? product.data[NAME_PROPERTY][lang] : null;
      const desc = product.data[DESC_PROPERTY] ? product.data[DESC_PROPERTY][lang] : null;
      
      for(let i=0; i < languages.length; i++) {
        const langKey = languages[i];
        
        const translateName = name ? await translateAPI.translate(lang, langKey, name) : null;
        const translateDesc = desc ? await translateAPI.translate(lang, langKey, desc) : null;
        
        if (translateName)
          setLocalizedProperty(NAME_PROPERTY, langKey, translateName);

        if (translateDesc)
          setLocalizedProperty(DESC_PROPERTY, langKey, translateDesc);

        
        for(let j=0; j < customOptions.length; j++) {
          const option = customOptions[j];

          const optionName =  option[OPTION_NAME_PROPERTY] ? option[OPTION_NAME_PROPERTY][lang] : null;

          const translateOptionName = optionName ? await translateAPI.translate(lang, langKey, optionName) : null;
          if (translateOptionName){
            setLocalizedPropertyOption(option.id, OPTION_NAME_PROPERTY, langKey, translateOptionName);
          }
          
          for(let o=0; o < option.options.length; o++) {
            const optionOption = option.options[o];
            const optionOptionName = optionOption[OPTION_NAME_PROPERTY] ? optionOption[OPTION_NAME_PROPERTY][lang] : null;
            const translateOptionOptionName = optionOptionName ? await translateAPI.translate(lang, langKey, optionOptionName) : null;
            if (translateOptionOptionName) {
              setLocalizedPropertyOptionsOption(option.id, optionOption.id, OPTION_NAME_PROPERTY, langKey, translateOptionOptionName);
            }
          }
         
        }

        
        for(let rpi=0; rpi < relatedProducts.length; ++rpi){
          const rp = relatedProducts[rpi];
          const rpOfferText = rp.data.offerText[lang];
          const translatedRpOfferText = rpOfferText ? await translateAPI.translate(lang, langKey, rpOfferText) : null;
          if (translatedRpOfferText) {
            setLocalizedProductOfferText(rp.productId, langKey, translatedRpOfferText);
          }
        }
      }
      
      message.success('translation done');
    }

    function setCustomOptionOptionsPrice(id, optionId, net) {
      
      let p = customOptions.find(o => o.id === id);
      p.options = p.options.map(o => {
        if (o.id === optionId){
          o.net = parseFloat(net).toFixed(2);
          return o;
        }
        return o;
      });

      updateOption(p);
    }

    function setCustomOptionOptionsExternalId(id, optionId, externalId) {
      
      let externalProduct = products.productIntegrationList.find(p => p.id === externalId);
      if (!externalProduct) return;
      //console.log(externalProduct)
      let p = customOptions.find(o => o.id === id);
      p.options = p.options.map(o => {
        if (o.id === optionId){
          o.externalId = externalId;
          o.net = externalProduct.gross;
          if (!o[OPTION_NAME_PROPERTY][lang])
            o[OPTION_NAME_PROPERTY][lang] = externalProduct.name; 
            
          return o;
        }
        return o;
      });

      updateOption(p);
    }

    async function SaveProduct(values){ 
      setConfirmLoading(true);
      
      let apiObj = {
        ...product,
        stock: values.stock,
        externalId: values.externalId,
        data: { 
          ...product.data,
          images: [...images, ...smallImages],
          net: values.price,
          
          gross: values.priceGross,
          discountPrice: values.discountPrice,
          discountGross: values.discountPriceGross,
          popular: values.popular,
          promote: values.promote
        },
        options: [...(customOptions || [])],
        properties: [...(values.allergy || []), values.type]
      };

      if (relatedProducts){
        apiObj.relatedProducts = [...(relatedProducts || [])];
      }
     
      let result = null;
      
      if (product.id){
        result = await productAPI.update(product.id, apiObj);
      }
      else {
        result = await productAPI.create(company.companyId, apiObj);
      }

      setConfirmLoading(false);

      if (result !== null){
        form.resetFields(); 
        products.appendProduct(result);
        if (props.onSave) {
          props.onSave(result);
        }
      }
    }

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    
    const propsUpload = {
      fileList: images,
      maxCount: 1,
      listType: "picture-card",
      beforeUpload: file => {
        if (file.type !== 'image/png' && file.type !== 'image/jpeg' ) {
          message.error(`${file.name} is not a png or jpeg file`);
        }
        return file.type === 'image/png' || file.type === 'image/jpeg';
      },
      onRemove: file => {
        const newImages = [...images.filter(i => i.uid !== file.uid)];
        const newSmallImages = [/*...smallImages.filter(i => i.uid !== `${file.uid}_small`)*/];
        setImages([...newImages]);
        setSmallImages([...newSmallImages])
      },
      onChange: info => {
        
        
      },

      customRequest: request => ProcessImage(request)
    };

    const handleOk = () => {
      form
        .validateFields()
        .then((values) => {
          SaveProduct({...values });
        })
        .then(() => {
          setCustomOptions([]);
          setImages([]);
          setSmallImages([]);
          setProduct({});
          setLang(company.language);
          form.resetFields(); 
          props.setModalOpen(false);
        })
        .catch(info => {
          console.log('Validate Failed:', info);
        });
    };

    const handleCancel = () => {
        setCustomOptions([]);
        setImages([]);
        setSmallImages([]);
        setProduct({});
        setLang(company.language);
        form.resetFields(); 
        props.setModalOpen(false);
    };

    const onOptionAdd = (id) => {
      let option = options.find(o => o.id === id);
      setCustomOptions([...customOptions, 
        { 
          name: {}, 
          type: option.type, 
          id: uuidv4(), 
          optionId: option.id,
          options:[
            { 
              name: {},
              id: uuidv4(),
              net: 0,
              externalId: '',
              gross: 0
            }
          ] 
        }]);
    }

    const onOptionRemove = (id) => {
      setCustomOptions([...customOptions.filter(o => o.id !== id)]);
    } 

    const onOptionOptionAdd = (id) => {
        let option = customOptions.find(o => o.id === id);
        option.options = [...option.options, { 
          name: {},
          id: uuidv4(),
          net: 0,
          externalId: '',
          gross: 0
        }
        ];
        updateOption(option);
    }

    const onOptionOptionRemove = (id, optionId) => {
      let option = customOptions.find(o => o.id === id);
      option.options = [...option.options.filter(o => o.id !== optionId)];
      updateOption(option);
    }

    const updateOption = (option) => {
      setCustomOptions(customOptions.map(o => {
        if (o.id === option.id){
          return { ...option };
        } 
        return o;
      }));
    }

    const priceUpdate = (priceNet, priceGross) => {
      if (priceNet) {
        const net = formatPrice(priceNet);
        const gross = getGross(net, company.vat);
        form.setFieldsValue({
          priceGross: gross,
          priceVAT: (gross - net).toFixed(2)
        }) 
      }

      if (priceGross) {
        const gross = formatPrice(priceGross);
        const net = getNet(gross, company.vat);
        form.setFieldsValue({
          price: net,
          priceVAT: (gross - net).toFixed(2)
        }) 
      }
    }

    const discountPriceUpdate = (priceNet, priceGross) => {
      if (priceNet) {
        const net = formatPrice(priceNet);
        const gross = getGross(net, company.vat);
        form.setFieldsValue({
          discountPriceGross: gross,
          discountPriceVAT: (gross - net).toFixed(2)
        }) 
      }

      if (priceGross) {
        const gross = formatPrice(priceGross);
        const net = getNet(gross, company.vat);
        form.setFieldsValue({
          discountPrice: net,
          discountPriceVAT: (gross - net).toFixed(2)
        }) 
      }
    }

    
    function setCustomRelatedProductPrice(id, net) {
      let p = relatedProducts.find(p => p.productId === id);
      p.data.newPrice = net;
      updateRelatedProducts(p);
    }

    const onRelatedProductChange = (id, productId) => {
      let p = relatedProducts.find(p => p.id === id);
      //console.log(products);
      let currentProduct = products.productList.find(p => p.id === productId);
      p.productId = productId;
      p.data.offerText = {};
      p.data.currentPrice = getGrossPriceFromProduct(currentProduct);
      p.data.newPrice = p.data.currentPrice;
      updateRelatedProducts(p);
    }

    const onRelatedProductAdd = () => {
      let rp = [...relatedProducts, { 
        id: uuidv4(),
        productId: '',
        data: {
          offerText: {},
          newPrice: 0,
          currentPrice: 0
        }
      }
      ];
      setRelatedProducts(rp);
    }

    const onRelatedProductRemove = (productId) => {
      let rp = [...relatedProducts.filter(p => p.productId !== productId && p.id !== productId)];
      setRelatedProducts(rp);
    }

    const updateRelatedProducts = (relatedProduct) => {
      setRelatedProducts(relatedProducts.map(p => {
        if (p.productId === relatedProduct.productId){
          return { ...relatedProduct };
        } 
        return p;
      }));
    }

    const switchProductView = (e) => {
      setProductView(e.target.value);
    }

    const getMultiLang = (data, field) => {
      if (!data || !field || !data[field])
          return '';

      var multiLangStr = Object.keys(data[field]);
      if (multiLangStr.length > 0){
          let eeIndex = multiLangStr.indexOf(company.language);
          return data[field][eeIndex > -1 ? multiLangStr[eeIndex] : multiLangStr[0]];
      }
      else
          return '';
    }

    const onSearchExternalId = (searchText) => {
      setProductIntegrationsSearch(
        !searchText 
        ? products.productIntegrationList.map(x => { return { value: x.id, label: x.name }})
        : products.productIntegrationList
            .filter(x => x.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
            .map(x => { return { value: x.id, label: x.name }}),
      );
    };
    /*
    const onSearchAdditionExternalId = (searchText) => {
      
      let externalProduct = products.productIntegrationList.find(x => x.id === externalId);
      
      let additions = externalProduct ? externalProduct.additions : [];
      
      let filtered = !searchText 
      ? additions.map(x => { return { value: x.id, label: x.name }})
      : (additions
          .filter(x => x.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) || [])
          .map(x => { return { value: x.id, label: x.name }});

      setProductIntegrationsAdditionSearch(
        filtered
      );
    }
    */
  
    const onSelectExternalId = (key) => {
      //console.log('onSelect', key);
      setExternalId(key);
      let externalProduct = products.productIntegrationList.find(x => x.id == key);
      if (company.isPoster)
        setProductIntegrationsAdditionSearch(externalProduct ? externalProduct.additions.map(x => { return { value: x.id, label: x.name }}) : []);
    };

    return (
      <>
        <Modal
          destroyOnClose={true}
          title={
            <div className='flex justify-between'>
              <div className='font-bold'>
              {((props.productId && props.productId.length > 0) ? getMultiLang(product.data, 'name') : "Create new product")}
              </div>
              <div className=''>
                {
                  user.isFeature("Ingridients") && <Radio.Group onChange={switchProductView} defaultValue={productView} buttonStyle="solid">
                    <Radio.Button key={'product_view'} value="product_view">Information</Radio.Button>
                    <Radio.Button key={'ingridients_view'} value="ingridients_view">Recipe</Radio.Button>
                </Radio.Group>
                }
              </div>
              
            </div>
            
          }
          closable={false}
          open={props.isModalOpen}
          confirmLoading={confirmLoading}
          footer={null}
          width={750}
        >
          <div className=''>
            <div className={`${productView === 'product_view' ? '' : 'hidden'}`}>
                  <Form
                    key={product.id}
                    {...formItemLayout}
                    form={form}
                    name="register"
                    id="productForm"
                    scrollToFirstError
                  >
                    {
                      (company.isCompucash || company.isPoster) && <Form.Item
                          name="externalId"
                          label={`External Id`}
                        >
                          <AutoComplete 
                            options={productIntegrationsSearch}
                            onSelect={onSelectExternalId}
                            onSearch={onSearchExternalId}
                            placeholder="Type to search"
                          />
                        </Form.Item>
                    }
                    
                    <Form.Item 
                      name="language"
                      label="Language"
                      initialValue={company.language}
                      rules={[
                        {
                          required: true,
                          message: 'Please select language',
                        },
                      ]}
                    >
                      <Radio.Group onChange={(e) => setLang(e.target.value)}>
                      { 
                        company.languages && company.languages.length > 0 
                            ? company.languages.map(o => <Radio.Button key={o.key} value={o.key}>{o.name}</Radio.Button>)
                            :  ''
                      }  
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item 
                    label={`Auto Translate`}>
                    <Button onClick={(e) => onAutoTranslateClick()}>Click to translate</Button>
                  </Form.Item>
                  <Divider orientation='left'>General info</Divider>
                    <Form.Item
                    
                      name="name"
                      label={`Name ${selectedLanguage()}`}
                      rules={[
                        {
                          required: true,
                          message: 'Please input name',
                        },
                      ]}
                    >
                      <Input disabled={!lang} onChange={(e) => setLocalizedProperty(NAME_PROPERTY, lang, e.target.value)}/>
                  </Form.Item>
                  <Form.Item
                      name="description"
                      label={`Description ${selectedLanguage()}`}
                      rules={[
                      ]}
                    >
                      <Input.TextArea disabled={!lang} onChange={(e) => setLocalizedProperty(DESC_PROPERTY, lang, e.target.value)}/>
                  </Form.Item>
                  <Form.Item
                  name="options"
                  label='Options for product'
                  >
                      
                        <Form.Item name="options_select" >
                            <Select
                              disabled={!lang}
                              onSelect={ (e) => onOptionAdd(e)}
                              allowClear
                              style={{ width: '100%' }}
                              placeholder="Can customers choose?"
                            >
                              { options.map(a => <Option key={a.id} value={a.id}>{a.data.name}</Option>) }
                            </Select>
                        </Form.Item>
                        {
                          customOptions.map((o, i) => 
                            <div>
                              <Divider orientation="left">{`${o.type === 'SINGLE' ? 'Single option' : 'Multiple options'} ${i + 1}`} <MinusCircleOutlined onClick={() => onOptionRemove(o.id)} /></Divider>
                                
                                <Input.Group compact>
                                    <Input style={{"width":"90%"}} value={o.name[lang]} onChange={ (e) => setLocalizedPropertyOption(o.id, OPTION_NAME_PROPERTY, lang, e.target.value) }/>
                                    <PlusCircleOutlined onClick={() => onOptionOptionAdd(o.id)} />
                                </Input.Group>
                                {
                                  o.options.map(oo => 
                                    <Input.Group compact>
                                        <Input style={{"width": '130px'}} value={oo.name[lang]} onChange={(e) => setLocalizedPropertyOptionsOption(o.id, oo.id, OPTION_NAME_PROPERTY, lang, e.target.value) }/>
                                        <InputNumber
                                          value={oo.net}
                                          onChange={(e) => setCustomOptionOptionsPrice(o.id, oo.id, e) }
                                          min={0}
                                          style={{"width": '60px'}}
                                          formatter={value => value}//`${company.currency} ${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ',')}
                                          parser={value => { 
                                            return formatPrice(value);
                                          }}
                                      />
                                        {  (company.isCompucash || company.isPoster) && <AutoComplete key={oo.id} 
                                              //value={oo.externalId}
                                              initialValue={oo.externalId}
                                              style={{"width": '170px'}}
                                              dropdownMatchSelectWidth={300}
                                              options={productIntegrationsSearch}
                                              onChange={(e) => setCustomOptionOptionsExternalId(o.id, oo.id, e) }
                                              onSelect={(e) => setCustomOptionOptionsExternalId(o.id, oo.id, e)}
                                              onSearch={onSearchExternalId}
                                              placeholder="Type to search"
                                        /> }
                                        <MinusCircleOutlined onClick={() => onOptionOptionRemove(o.id, oo.id)} />
                                      </Input.Group>
                                  
                                  )
                                }
                            </div>
                            )
                        }
                  </Form.Item>
                  <Form.Item
                  name="type"
                  label='Type'
                  rules={[
                    {
                      required: true,
                      message: 'Please select product type',
                    },
                  ]}
                  >
                  <Select  
                    disabled={!lang}
                    style={{ width: '100%' }}
                    placeholder="Please select type"
                  >
                    { properties.filter(p => p.type === PRODUCT_TYPE || p.type === PRODUCT_PROMOTION).map(a => <Option key={a.id} value={a.id}>{a.data.name}</Option>) }
                  </Select>
                  </Form.Item>
                  <Form.Item
                  name="allergy"
                  label='Allergy Icons'
                  >
                  <Select
                    disabled={!lang}
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select allergy"
                  >
                    { properties.filter(p => p.type === PRODCUT_ALLERGY_TYPE || p.type === PRODCUT_CATEGORY_TYPE).map(a => <Option key={a.id} value={a.id}>{a.data.name}</Option>) }
                  </Select>
                  </Form.Item>
                  <Form.Item
                      name="popular"
                      valuePropName="checked"
                      label={`Popular`}
                    >
                      <Switch size="small" defaultChecked={false}/>
                  </Form.Item>
                  <Divider orientation='left'>Price</Divider>
                  {/* standart price */}
                  <Form.Item
                      name="price"
                      label="Net"
                      disabled={true}
                      onChange={(e) => priceUpdate(e.target.value, null) }
                      rules={[
                        {
                          required: true,
                          message: 'Please input price',
                        },
                      ]}
                    >
                    <Input
                        addonAfter={company.currency}
                        disabled={!lang}
                        style={{ width: '30%' }}
                        min={0.00}
                        formatter={value => formatPrice(value)}//`${company.currency} ${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ',')}
                        parser={value => { 
                          return formatPrice(value);
                        }}
                      />

                    </Form.Item>

                    <Form.Item
                      name="priceGross"
                      label="Gross"
                      onChange={(e) => priceUpdate(null, e.target.value) }
                      rules={[
                        {
                          required: true,
                          message: 'Please input gross price',
                        },
                      ]}
                    >
                    <Input
                        addonAfter={company.currency}
                        disabled={!lang}
                        min={0.00}
                        style={{ width: '30%' }}
                        formatter={value => formatPrice(value)}//`${company.currency} ${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ',')}
                        parser={value => { 
                          //let v = value.slice(1);
                          //return parseFloat(v);
                          return formatPrice(value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="VAT" name="priceVAT">
                      <Input
                        addonAfter={company.currency}
                        min={0.00}
                        style={{ width: '30%' }}
                        disabled={true}
                      />
                      </Form.Item>
                      <Form.Item label="Stock" name="stock">
                      <Input
                        addonAfter={'PCS'}
                        min={0}
                        style={{ width: '30%' }}
                      />
                      </Form.Item>
                  <Divider orientation='left'>Discount price</Divider>

                  {/* discount price */}
                  <Form.Item
                      name="discountPrice"
                      label="Discount Net"
                      disabled={true}
                      onChange={(e) => discountPriceUpdate(e.target.value, null) }
                    >
                    <Input
                        addonAfter={company.currency}
                        disabled={!lang}
                        style={{ width: '30%' }}
                        min={0.00}
                        formatter={value => formatPrice(value)}//`${company.currency} ${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ',')}
                        parser={value => { 
                          return formatPrice(value);
                        }}
                      />

                    </Form.Item>

                    <Form.Item
                      name="discountPriceGross"
                      label="Discount Gross"
                      onChange={(e) => discountPriceUpdate(null, e.target.value) }
                    >
                    <Input
                        addonAfter={company.currency}
                        disabled={!lang}
                        min={0.00}
                        style={{ width: '30%' }}
                        formatter={value => formatPrice(value)}//`${company.currency} ${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ',')}
                        parser={value => { 
                          //let v = value.slice(1);
                          //return parseFloat(v);
                          return formatPrice(value);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="VAT" name="discountPriceVAT">
                      <Input
                        addonAfter={company.currency}
                        min={0.00}
                        style={{ width: '30%' }}
                        disabled={true}
                      />
                      </Form.Item>

                      <Form.Item
                        name="promote"
                        valuePropName="checked"
                        label={`Promote to top group`}
                      >
                        <Switch size="small" defaultChecked={false}/>
                    </Form.Item>

                  <Divider orientation='left'>Configure upsales</Divider>
                  <Form.Item name="productRelations" label='Products to offer'>

                      <div>
                            <div className='flex justify-end'>
                            <Button
                                type="link"
                                width={80}
                                onClick={() => {
                                  onRelatedProductAdd();
                                }}
                              >
                                <PlusOutlined /> Add offer product after
                            </Button>
                            </div>
                            {relatedProducts.map((rp, i) =>  (
                              <div className='gap-3'>
                                  
                                  <div className='pt-3'>
                                    <div className='pb-1'>Select product</div>
                                    <Select
                                      rules={[{ required: true, message: "Missing product" }]}
                                      value={rp.productId}
                                      allowClear
                                      style={{ width: '100%' }}
                                      onChange={(e) => onRelatedProductChange(rp.id, e) }
                                      placeholder="Please select product that would be suggested"
                                    >
                                      { products.productList.filter(p => p.id !== product.id).map(p => <Option key={p.id} value={p.id}>{getMultiLang(p.data, 'name')}</Option>) }
                                    </Select>
                                  </div>
                                  <div className='pt-3 flex gap-3'>
                                    <div className='w-32'>
                                      <div className='pb-1'>Discount price</div>
                                      <InputNumber
                                        rules={[{ required: true, message: "Missing discount price" }]}
                                        addonAfter={company.currency}
                                        disabled={!rp.productId}
                                        min={0.00}
                                        onChange={(e) => setCustomRelatedProductPrice(rp.productId, e) }
                                        value={rp.data.newPrice}
                                        style={{ width: '100%' }}
                                        formatter={value => formatPrice(value)}
                                        parser={value => { 
                                          return formatPrice(value);
                                        }}
                                      />
                                    </div>
                                    <div className='w-32'>
                                      <div className='pb-1'>Current gross price</div>
                                      <InputNumber
                                        addonAfter={company.currency}
                                        disabled={true}
                                        value={rp.data.currentPrice}
                                        style={{ width: '100%' }}
                                        formatter={value => formatPrice(value)}
                                        parser={value => { 
                                          return formatPrice(value);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className='pt-3'>
                                    <div className='pb-1'>Offer text {lang.toUpperCase()}</div>
                                    <Input.TextArea 
                                      disabled={!rp.productId} 
                                      value={rp.data.offerText[lang]} 
                                      onChange={(e) => setLocalizedProductOfferText(rp.productId, lang, e.target.value)}
                                      />
                                  </div>
                                  <div className='flex justify-end'>
                                  <Button
                                        type="link"
                                        width={80}
                                        onClick={() => {
                                          onRelatedProductRemove(rp.productId.length === 0 ? rp.id : rp.productId);
                                        }}
                                        
                                      >
                                        <MinusCircleOutlined /> Remove
                                    </Button>
                                  </div>
                                </div>
                            ))}
                        </div>
                        
                      

                  </Form.Item>
                  <Divider orientation='left'>Upload image of the product</Divider>
                  <Form.Item
                    name="images"
                    label="Image"
                  >
                    <Upload 
                      {...propsUpload}  
                      disabled={!lang}>
                        
                      {images.length === 0 ? uploadButton : ''}
                    </Upload>
                  </Form.Item>
                  
                  </Form>
                  <div className='flex justify-end gap-1'>
                    <Button key="cancel" type="ghost" onClick={() => handleCancel()}>Close</Button>,
                    <Button key="ok" type="primary" onClick={() => handleOk()}>Save Information</Button>,
                  </div>
            </div>
            {
              user.isFeature("Ingridients") && <div className={`${productView === 'ingridients_view' ? '' : 'hidden '}`}>
                  <ProductIngridient id={props.productId} />
              </div>
            }
          </div>
        
          
        </Modal>
        </>
    );
};