import React, { useState } from 'react'
import { useEffect } from 'react';

import { useCompany } from '../../hooks/CompanyProvider';
import { useMulleUser } from '../../hooks/UserProvider';

import statsAPI from '../../http/apiStatistics';

import { Link } from "react-router-dom";
import { Layout, Skeleton, Table, DatePicker } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { SyncOutlined } from '@ant-design/icons';
import CallToWaiter from '../CallToWaiter';

const {  Content } = Layout;

export default function DashboardWaitress(props) {
    const user = useMulleUser();
    const company = useCompany();

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!company.companyId) return;
        //initialData();
        setLoading(false);
    },[company.companyId, refresh]);

    return <Layout style={{ height: "100vh", overflow: "auto" }}>    
    <Content className="site-layout" style={{ padding: '0 50px', marginTop: 5 }}>
    <PageHeader
        className="site-page-header"
        title={<div>{`Dashboard for `}<span className='underline'>{`${user.user.name}`}</span></div>}
        subTitle="watch your stats and available actions"
        extra={[
            <div className="flex space-x-4">
                <div className="pt-1.5">
                    <SyncOutlined style={{fontSize: "20px"}} onClick={ () => setRefresh(!refresh)}/>
                </div>
            </div>
        ]}
        />
        { loading 
            ? <Skeleton active></Skeleton> 
            : <div className='px-5'>
                <div className='text-2xl pt-10'>My actions:</div>
                <div className='pt-3'><Link to='/orders/cards' className='text-lg'>Kitchen Display</Link><span className='text-gray-500 text-lg'> - accept orders from tables realtime</span></div>
                <div className='pt-1'><Link to='/orders/items' className='text-lg'>Waitress Serving Display</Link><span className='text-gray-500 text-lg'> - see what orders are ready for serving grouped by tables</span></div>
                <div className='pt-1'><Link to='/reservations' className='text-lg'>Reservations</Link><span className='text-gray-500 text-lg'> - reservation management for tables</span></div>
                { <CallToWaiter useDrawer={false} />}
            </div>
        }
    </Content>
    </Layout>;
}