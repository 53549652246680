
import React, { useState, useEffect } from 'react';
import {
    Button,
    Layout
  } from 'antd';

import { PageHeader } from '@ant-design/pro-layout';
import { useCompany } from '../../hooks/CompanyProvider';
import PropertyList from './PropertyList';
import { PRODUCT_TYPE, PRODUCT_PROMOTION } from '../Common';
import PropertyEdit from './PropertyEdit';
import productPropertyAPI from '../../http/apiProductProperty';

export default function PropertyIndex() {
    const [propertyList, setPropertyList] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState();
    const [editOpen, setEditOpen] = useState(false);
    const company = useCompany();

    useEffect(() => {
        getPropertyList()
    }, [company.companyId]);


    const getPropertyList = async() => {
        let propertyResponse = await productPropertyAPI.getListProperties(company.companyId);
        
        setPropertyList(propertyResponse.filter(p => p.type === PRODUCT_TYPE || p.type === PRODUCT_PROMOTION).sort((a,b) => (a.data.order)- (b.data.order)));
    }

    const onEdit = (property) => {
        setSelectedProperty(property);
        setEditOpen(true);
    }

    const onDelete = async(id) => {
        await productPropertyAPI.delete(id);
        await getPropertyList();
    }

    return <Layout style={{ height: "100vh", overflow: "auto" }} >
    <PageHeader
      className="site-page-header"
      title={'Product types'}
      subTitle="define custom types for you products"
      extra={
        [  
            <>
            <Button onClick={()=>onEdit('')} style={{width:"120px"}}  type="primary">New</Button>
            <PropertyEdit isModalOpen={editOpen} setModalOpen={setEditOpen} onSuccess={getPropertyList} property={selectedProperty} companyId={company.companyId}/>
            </>
        ]
    }
    >
       <PropertyList list={propertyList} onDelete={onDelete} onEdit={onEdit} />
    </PageHeader>
  </Layout>
}