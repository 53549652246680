import settlementAPI from '../../http/apiSettlement';
import {
    Modal,
    Form,
    Input,
    Switch,
    Divider,
    Button,
    InputNumber 
  } from 'antd';
  import moment from "moment";

export default function PosSellSettlementOpen({companyId, onPosOpen}) {
    const [form] = Form.useForm(); 

    const open = async(cashAmount) => {
        const newSettlement = await settlementAPI.open(companyId, cashAmount, 0);
        onPosOpen(newSettlement);
    }

    const onOpen = async() => {
        form
          .validateFields()
          .then(async(values) => {
            const cashAmount = values.cashAmount;
            open(cashAmount)
           
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
    }

    return <div className='grid h-screen place-items-center'>
        <div>
            <div className='font-bold text-xl text-center'>
                POS 
            </div>
            <div className='pt-7'>
                <Form
                    key={'settelementOpen'}
                    form={form}
                    name="settelementOpen"
                    id="settelementOpen"
                    onFinish={onOpen}
                    initialValues={{ size: 'large' }}
                >
                    <Form.Item
                        name="cashAmount"
                        label={`Cash amount on open`}
                        rules={[
                        {
                            required: true,
                            message: 'Please input cash amount',
                        },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className='w-full'>
                            OPEN POS
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    </div>;
}