import { API_BASE_ENDPOINT, API_BASE_HEADER, parse } from '../http/apiConfig';
import { message } from 'antd';

class SettlementAPI {
  
    open = async(companyId, cashAmount, pos = 0) => {
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/pos/settlement/${companyId}/pos/${pos}/open`,
                await API_BASE_HEADER('POST', cashAmount)
            );
            let data = await response.json();
            return parse(data);
        }catch(e){
            console.error(e);
            message.error(e.message);
            return null;
        } 
    }

    close = async(companyId, cashAmount, pos = 0) => {
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/pos/settlement/${companyId}/pos/${pos}/close`,
                await API_BASE_HEADER('POST', cashAmount)
            );
            let data = await response.json();
            return data;
        }catch(e){
            console.error(e);
            message.error(e.message);
            return null;
        } 
    }

    getLast = async(companyId, pos = 0) => {
        if (!companyId)
            return;

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/pos/settlement/${companyId}/pos/${pos}/last`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.text();
            return data ? JSON.parse(data) : {};
        }catch(e){
            message.error(e);   
            console.log(`error: ${JSON.stringify(e)}`)
        }
    }

    getList = async(companyId, pos, filters) => {
        if (!companyId)
            return;
            
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/pos/settlement/${companyId}/pos/${pos}/list`,
                await API_BASE_HEADER('POST', filters)
            );
            const data = await response.json();
            return parse(data);
        }catch(e) {
            message.error(e);
            return false;
        }
    }
}

const settlementAPI = new SettlementAPI();
export default settlementAPI;