import React, { useState } from "react";
import { Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import logo from '../../assets/mulle_logo_round.png';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'; // Firebase v9+
import { Spinner } from '../../components/Common'
//import SignInWithGoole from './SignInWithGoole';
//import SignInWithFacebook from './SignInWithFacebook';

export default function SignInWithPassword() {
    const auth = getAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const signIn = () => {
      setLoading(true);
      signInWithEmailAndPassword(auth, email, password)
        .then(result => {
          setLoading(false);
        } )
        .catch((e) => {
          setErrorMsg(e.message);
          setLoading(false);
        });
    }

    return (
      <div className="flex h-screen bg-white flex-col">
            <div className="m-auto w-64">
              <div className="hero container max-w-screen-lg mx-auto pb-10 flex">
                <img className="h-40 rounded-full mx-auto shadow-lg" src={logo}/>
              </div>
              
              <Form
                name="normal_login"
                initialValues={{ remember: true }}
                onFinish={signIn}
              >
                <Form.Item name="username" rules={[{ required: true, message: 'Please input your Username!' }]}>
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" className="h-9" value={email} onChange={(e) => setEmail(e.target.value)}/>
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
                  <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" className="h-9" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                </Form.Item>
                <Form.Item>
                  <button htmltype="submit" className="border-0 cursor-pointer w-full rounded-lg text-red h-10 text-lg font-bold bg-gray-100 flex justify-center border-1 border-red focus:outline-none hover:bg-red hover:text-white">
                    { loading ? <div className="pt-2.5" ><Spinner color="text-whie" /></div> : <div className="pt-1">Log In</div>}
                  </button>
                </Form.Item>
                <Form.Item>
                  { errorMsg ? <div className="text-red px-3">{errorMsg}</div> : <></>}
                </Form.Item>
              </Form>
              {/*
              <div><SignInWithFacebook/></div>
              <div className="pt-2"><SignInWithGoole/></div>
              */}
            </div>
            
          
      </div>
    )
  
  };