import React, { useEffect, useState } from 'react'
import { Space, Table, Popconfirm, DatePicker, Tag } from 'antd';
import { DeleteTwoTone, EditTwoTone, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import { CSVLink, CSVDownload } from "react-csv";
import { PAYMENT_CARD, PAYMENT_CASH, toUpperCase  } from '../Common';

import orderAPI from '../../http/apiOrder';
import { useCompany } from '../../hooks/CompanyProvider';

import { 
    formatCurrencty,
    DELIVERY_RESTAURANT,
    PAYMENT_STRIPE,
    PAYMENT_POS,
    INSERTED,
    CONFIRMED,
    PROGRESS,
    READY,
    DELIVERED,
    PRODUCT_TYPE,
    CANCELED,
    COMPLETED
 } from '../Common';
 import utc from 'dayjs/plugin/utc';
 import dayjs from 'dayjs';

 

//import { ReactComponent as BagIcon } from '../../assets/mulle_bag_icon.svg';
//import { ReactComponent as TableIcon } from '../../assets/mulle_table_icon.svg';
import OrderTableEdit from './OrderTableEdit';
import productPropertyAPI from '../../http/apiProductProperty';
//import { collection, query, where, orderBy, limit } from "firebase/firestore";
dayjs.extend(utc)
const { RangePicker } = DatePicker;
const { CheckableTag } = Tag;

export default function OrderTable() {

    const statusSelection = [INSERTED, CONFIRMED, PROGRESS, READY, DELIVERED, CANCELED, COMPLETED]

    const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const company = useCompany();
    const [loading, setLoading] = useState();
    
    const [types, setTypes] = useState({});

    const [statuses, setStatuses] = useState([COMPLETED]);
    const [limit, setLimit] = useState(50);
    const [skip, setSkip] = useState(0);

    const [orders, setOrders] = useState();
    const [fromTo, setFromTo] = useState([dayjs().startOf('day'), dayjs().endOf('day')]);


    useEffect(() => {
        getTypes();
        getOrders();
    },[statuses, fromTo]);
    
    const invoicePDF = async(order) => {
        if (!order) return;
        let data = toUpperCase(order.data.clientOrder);
        let blob = await orderAPI.getPdfInvoice(data);
        if (!blob) return;
        const url = window.URL.createObjectURL(blob);
                  const link = document.createElement('a');
                  link.href = url;
                  if (typeof window.navigator.msSaveBlob === 'function') {
                      window.navigator.msSaveBlob(
                          blob,
                          `${order.shortId}.pdf`
                      );
                  } else {
                      link.setAttribute('download', `${order.shortId}.pdf`);
                      document.body.appendChild(link);
                      link.click();
                  }
    }

    const orderEdit = async(order) => {
        setSelectedOrderId(order.id);
        setSelectedOrder(order);
        setIsOrderModalOpen(true);
    }

    const getTypes = async() => {
        let apiProperties = await productPropertyAPI.getListProperties(company.companyId);
        setTypes(apiProperties.filter(p => p.type === PRODUCT_TYPE).sort((a,b) => (a.data.order ? a.data.order : 99) - ((b.data.order ? b.data.order : 99))).reduce((map, obj) => {
            map[obj.id] = obj;
            return map;
        },{}));
    }

    const getOrders = async(params = {}) => {
        setLoading(true);
        const orders = await orderAPI.getOrders(company.companyId, {
            statuses: [ ...statuses ],
            start: fromTo[0],
            end: fromTo[1],
            skip: skip,
            limit: limit
        });
        setOrders(orders);
        setLoading(false);
    }

    const onRangeChange = (e) => {
        setFromTo([e[0], e[1]]);
    }

   const handleTableChange = (pagination, filters, sorter) => {
    getOrders({
          sortField: sorter.field,
          sortOrder: sorter.order,
          pagination,
          ...filters,
        });
      };

    const handleStatusChange = (status, checked) => {
        const selectedStatuses = checked ? [...statuses, status] : statuses.filter(t => t !== status);
        setStatuses(selectedStatuses);
    }

    const columns = [
        {
            title: "Order",
            render: (record) => (
              <div className="flex flex-col space-y-2">
                <div className="flex">
                    <div className="w-1/4 text-gray-400">Date: </div>
                    <div>{ dayjs.utc(record.created).local().format('DD-MM-YYYY HH:mm')}</div>
                </div>
                <div className="flex">
                    <div className="w-1/4 text-gray-400">Delivery: </div><div>
                    { 
                    record.data.clientOrder.delivery.deliveryType === DELIVERY_RESTAURANT
                        ? <div className="flex">{ record.data.companyData.menuLocationName }</div>
                        : <div className="flex">{ record.data.companyData.menuLocationName } </div>}
                        </div>
                </div>
                <div className="flex">
                    <div className="w-1/4 text-gray-400">Payment: </div><div>
                    {
                        record.data.clientOrder.delivery.paymentType === PAYMENT_POS
                        ? PAYMENT_POS
                        : PAYMENT_STRIPE
                    }
                    </div>
                </div>
                <div className="flex">
                    <div className="w-1/4 text-gray-400">Total: </div>
                    <div className="font-bold">{formatCurrencty(record.totalGross, record.data.companyData.currency)}</div>
                </div>
                <div className="flex">
                    <div className="w-1/4 text-gray-400">Status: </div>
                    <div className="font-bold">{record.status.status}</div>
                </div>
                <div className="flex">
                    <div className="w-1/4 text-gray-400">Client: </div>
                    <div className="font-bold">{record.client.phone}</div>
                </div>
                <div className="flex">
                    <div className="w-1/4 text-gray-400">Action: </div>
                    <div className="font-bold">
                    <Space size="middle">
                        <a onClick={() => orderEdit(record)}><EditTwoTone /></a>
                        <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" /*onConfirm={(e) => deleteOrder(record.id) }*/>
                            <a><DeleteTwoTone twoToneColor="#eb2f96" /></a>
                        </Popconfirm>
                    </Space>
                    </div>
                </div>

                
                
              </div>
            ),
            responsive: ["xs"]
          },
        {
            title: 'Order Time',
            key: 'time',
            width: 150,
            render: (text, record) => (
             /*{new Date(o.Created.seconds * 1000).toLocaleDateString()}*/
             //`${moment().diff(moment(record.Created.seconds * 1000), 'minutes')} min`
             dayjs.utc(record.created).local().format('DD-MM-YYYY HH:mm')
            ),
            responsive: ["md"]
          },
          {
            title: '#Order Nr',
            key: 'orderNr',
            width: 150,
            render: (text, record) => (
                record.shortId
            ),
            responsive: ["md"]
          },
          /*{
            title: 'Delivery',
            key: 'delivery',
            width: 50,
            align: "center",
            filters: [
                { text: 'Table', value: DELIVERY_RESTAURANT },
                { text: 'Takeaway', value: DELIVERY_TAKEAWAY },
              ],
            render: (text, record) => (
                record.data.clientOrder.delivery.deliveryType === DELIVERY_RESTAURANT
            ? <div className="flex"><TableIcon className="h-4"/></div>
            : <div className="flex pl-2"><BagIcon className=""/></div>
            ),
            responsive: ["md"]
        },*/
        {
          title: 'Location',
          key: 'location',
          render: (text, record) => (
            record.data.clientOrder.delivery.deliveryType === DELIVERY_RESTAURANT
            ? <div>{record.data.companyData.menuLocationName}</div>
            : <div className="flex pl-3"></div>
          ),
          responsive: ["md"]
        },
        {
            title: 'Payment',
            key: 'payment',
            width: 100,
            filters: [
                { text: 'Cash', value: PAYMENT_CASH },
                { text: 'Card', value: PAYMENT_CARD },
                { text: 'Stripe', value: PAYMENT_STRIPE },
              ],
            render: (text, record) => (
                <div>
                    <div>
                    {
                        record.data.clientOrder.delivery.paymentType
                        
                    }
                    </div>
                </div>
            ),
            responsive: ["md"]
        },
        {
            title: 'Total',
            key: 'total',
            width: 100,
            align: "right",
            render: (text, record) => (
                <div className="font-bold">{formatCurrencty(record.totalGross, record.data.companyData.currency)}</div>
            ),
            responsive: ["md"]
        },
        {
            title: 'Status',
            key: 'status',
            align: "center",
            width: 150,
            filters: [
                { text: 'Inserted', value: INSERTED },
                { text: 'Confimed', value: CONFIRMED },
                { text: 'Preparing', value: PROGRESS },
                { text: 'Ready for serving', value: READY },
                { text: 'Served', value: DELIVERED },
                { text: 'Completed', value: COMPLETED },
                { text: 'Canceled', value: CANCELED },
              ],
            render: (text, record) => (
                record.status.status
            ),
            responsive: ["md"]
        }, 
        {
            title: 'Client',
            key: 'client',
            width: 200,
            align: "right",
            render: (text, record) => (
                <div className="">{record.client.phone}</div>
            )
            ,responsive: ["md"]
        },
        {
            title: 'Action',
            key: 'action',
            width: 100,
            align: 'right',
            
            render: (text, record) => (
              <Space size="middle">
                <a onClick={() => invoicePDF(record)}><FilePdfOutlined /></a>
                <a onClick={() => orderEdit(record)}><EditTwoTone /></a>
                <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" /*onConfirm={(e) => deleteOrder(record.id) }*/>
                    <a><DeleteTwoTone twoToneColor="#eb2f96" /></a>
                </Popconfirm>
              </Space>
            ),
            responsive: ["md"]
          },
      ];

    return <div>
        <OrderTableEdit
            orderId={selectedOrderId}
            order={selectedOrder}
            isModalOpen={isOrderModalOpen}
            setModalOpen={setIsOrderModalOpen}
            company={company}
            types={types}
            statuses={statusSelection}
        />
        <div className="px-7 flex space-x-3 justify-between">
            <div className="flex flex-col pt-1">
            <div className="text-gray-400 text-sm">Date range</div>
                <div className="px-3 pt-3 pb-3">
                            <RangePicker 
                                showTime 
                                onChange={onRangeChange}
                                defaultValue={[fromTo[0], fromTo[1]]}
                            />
                </div>
           </div>
            <div className="pt-3 pb-3 text-base">
                <FileExcelOutlined className="pr-1.5"/>
                <CSVLink className="pt-1" separator={";"} filename={`orders-${dayjs().format('DD-MM-YYYYTHH:mm')}.csv`} data={
                    orders && orders.length > 0 
                        ? orders.map(o => [
                            dayjs.utc(o.created).local().format('DD-MM-YYYY HH:mm'),
                            o.shortId,
                            o.data.clientOrder.delivery.paymentType,
                            o.totalGross.toFixed(2).replace(".", ","),
                            (o.totalGross - o.totalVat).toFixed(2).replace(".", ","),
                            o.totalVat.toFixed(2).replace(".", ","),
                            o.status.status
                            ])
                        : []
                    }>
                     Excel
                </CSVLink>
            </div>
        </div>
        <div className="px-7 flex flex-col pt-1">
                <div className="text-gray-400 text-sm">Status</div>
                <div className="flex pt-1 pl-3">
                { 
                    statusSelection
                    .map(a => <div className="p-1">
                        <CheckableTag onChange={checked => handleStatusChange(a, checked)} checked={statuses.indexOf(a) > -1} key={a}><div className="text-base">{a}</div></CheckableTag>
                    </div>) 
                }
                </div>
            </div>
        <div className='px-7'>
            <Table size="small" 
                columns={columns} 
                dataSource={orders} 
                pagination={true} 
                loading={loading} 
                rowKey={record => record.id} onChange={handleTableChange} />
        </div>
    </div>;
}