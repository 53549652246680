import React, { useState, useEffect } from 'react';
import settlementAPI from '../../http/apiSettlement';
import moment from "moment";
import { Table, DatePicker } from 'antd';
import { useCompany } from '../../hooks/CompanyProvider';
import { 
    formatCurrencty, 
} from '../Common';

const { RangePicker } = DatePicker;
export default function PosSettlement() {
    const company = useCompany();

    const [settlements, setSettlements] = useState([]);
    const [fromTo, setFromTo] = useState([moment().add(-5, 'days').local().startOf('day'), moment().local().endOf('day')]);
    const [limit, setLimit] = useState(50);
    const [skip, setSkip] = useState(0);
    
    const [loading, setLoading] = useState(false);

    useEffect(()=> {
        getSettlements();
    },[fromTo]);

    const getSettlements = async(params = {}) => {
        setLoading(true);
        const settlements = await settlementAPI.getList(company.companyId, 0, {
            start: fromTo[0],
            end: fromTo[1],
            skip: skip,
            limit: limit
        });
        setSettlements(settlements);
        setLoading(false);
    }

    const onRangeChange = (e) => {
        setFromTo([e[0], e[1]]);

    }

    const handleTableChange = (pagination, filters, sorter) => {
        getSettlements({
              sortField: sorter.field,
              sortOrder: sorter.order,
              start: fromTo[0],
              end: fromTo[1],
              pagination,
              ...filters,
            });
    };

    const columns = [
        {
            title: 'From',
            key: 'from',
            width: 150,
            align: "center",
            render: (text, record) => (
                <div className='font-bold'>{moment(record.startTime).format('DD-MM-YYYY HH:mm')}</div>
            )
        },
        {
            title: 'To',
            key: 'to',
            width: 150,
            render: (text, record) => (
                record.endTime ? moment(record.endTime).format('DD-MM-YYYY HH:mm') : 'In Progress'
            )
        },
        {
            title: 'Orders',
            key: 'ordersCount',
            width: 50,
            render: (text, record) => (
               record.ordersCount
            )
        },
        {
            title: 'Total Sales',
            key: 'total',
            render: (text, record) => (
              <div className='font-bold'>{formatCurrencty(record.totalSales, company.currency)}</div>
            )
        },
        {
            title: 'Start Cash',
            key: 'startCash',
            render: (text, record) => (
                formatCurrencty(record.startCash, company.currency)
            )
        },
        {
            title: 'End Cash',
            key: 'endCash',
            render: (text, record) => (
                formatCurrencty(record.endCash, company.currency)
            )
        },
        {
            title: 'Stripe Sales',
            key: 'app',
            render: (text, record) => (
                formatCurrencty(record.appSales, company.currency)
            )
        },
        {
            title: 'Cash Sales',
            key: 'cash',
            render: (text, record) => (
                formatCurrencty(record.cashSales, company.currency)
            )
        },
        {
            title: 'Card Sales',
            key: 'card',
            render: (text, record) => (
                formatCurrencty(record.cardSales, company.currency)
            )
        }
      ];

    return <div>
        <div className='pt-7 px-7 font-bold text-xl'>Settelements</div>
        <div className="px-7 pt-5 flex space-x-3 justify-between">
            <div className="flex flex-col pt-1">
                <div className="text-gray-400 text-sm">Date range</div>
                <div className="px-3 pt-3 pb-3">
                            <RangePicker 
                                showTime 
                                onChange={onRangeChange}
                                defaultValue={[fromTo[0], fromTo[1]]}
                            />
                </div>
           </div>
        </div>
        <div className='px-7'>
            <Table size="middle" 
                columns={columns} 
                dataSource={settlements} 
                pagination={true} 
                loading={loading} 
                rowKey={record => record.id} onChange={handleTableChange} />
        </div>
    </div>;
}