import React, { useState } from 'react'
import { useEffect } from 'react';

import { useCompany } from '../../hooks/CompanyProvider';
import statsAPI from '../../http/apiStatistics';


import { formatCurrencty } from '../Common';
import { Layout, Skeleton, Table, DatePicker } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { SyncOutlined } from '@ant-design/icons';


import dayjs from 'dayjs';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    ArcElement
  } from 'chart.js';
  import { Bar, Doughnut } from 'react-chartjs-2';
import { useMulleUser } from '../../hooks/UserProvider';
import ChartDataLabels from 'chartjs-plugin-datalabels';

  
ChartJS.register(
    ChartDataLabels,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
  );

const { RangePicker } = DatePicker;

const {  Content } = Layout;


export default function DashboardAdmin(props) {
    const user = useMulleUser();

    const [loading, setLoading] = useState(true);
    
    const [stats, setStats] = useState();
    
    const [fromTo, setFromTo] = useState([dayjs().startOf('month'), dayjs().endOf('day')]);
    const [refresh, setRefresh] = useState(false);
   
    useEffect(() => {
        
        async function getStats() {
            setLoading(true)
            const result = await statsAPI.getStatsForDashboardAdmin(fromTo[0], fromTo[1]);
            
            if (result !== false) {
                setStats(result);
            }
                
            setLoading(false);
            
        }

        getStats();
             
    },[fromTo, refresh]);


    const onRangeChange = (e) => {
        
        setFromTo([e[0], e[1]]);
    }

    

    const columns = [
        {
          title: 'Name',
          dataIndex: 'companyName',
          key: 'companyName',
        },
        {
          title: 'View Count',
          dataIndex: 'viewCount',
          key: 'viewCount',
        },
        {
          title: 'Orders Count',
          dataIndex: 'ordersCount',
          key: 'ordersCount',
        },
        {
            title: 'Country',
            dataIndex: 'companyCountry',
            key: 'companyCountry',
        }
      ];

      
    
      const formatDate = 'DD-MM-YYYY hh:mm'

    return (
        <Layout style={{ height: "100vh", overflow: "auto" }}>    
            <Content className="site-layout" style={{ padding: '0 50px', marginTop: 5 }}>
            <PageHeader
                className="site-page-header"
                title="Admin Dashboard"
                subTitle="watch your stats"
                extra={[
                    <div className="flex space-x-4">
                        <RangePicker 
                            className="h-9 shadow-md"
                            showTime 
                            onChange={onRangeChange}
                            defaultValue={[fromTo[0], fromTo[1]]}
                        />
                        <div className="pt-1.5">
                            <SyncOutlined style={{fontSize: "20px"}} onClick={ () => setRefresh(!refresh)}/>
                        </div>
                    </div>
                ]}
                />
                { loading 
                    ? <Skeleton active></Skeleton>  
                    : 
                    <div>
                        
                    {
                        stats 
                        ? <div className="site-statistic-demo-card">
                                
                                    <div>
                                        
                                        <div>
                                            <div  className="" >
                                                <Bar
                                                    height={70}
                                                    data={{
                                                        labels: stats.map(o => o.companyName),
                                                        datasets: [
                                                             {
                                                                label: 'QR code scans by company',
                                                                data: stats.map(o => o.viewCount),
                                                                maxBarThickness: 100,
                                                                backgroundColor: '#5dbf97', 
                                                            }
                                                            ,
                                                        ]
                                                    }}

                                                    options={{
                                                        scales: {
                                                        
                                                        }
                                                    }}
                                                />
                                            </div>
                                            
                                        </div>
                                </div>
                                    
                                <div className="grid xl:grid-cols-2 md:grid-cols-1 space-x-5 pt-10">
                                        <div className="">
                                            <div className='text-lg pt-3 pb-5 font-bold'>Company usage statistic</div>
                                            {
                                                stats && stats.length > 0 
                                                ? <Table 
                                                size="small"
                                                dataSource={stats.sort((a,b) => b.viewCount - a.viewCount)} 
                                                columns={columns} 
                                                pagination={false} 
                                                
                                                />
                                                : ''
                                            }
                                        </div> 
                                    </div>
                            </div>
                        : ''
                    }
                    
                </div>
                }
            </Content>
        </Layout>
    )
}