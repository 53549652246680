import settlementAPI from '../../http/apiSettlement';
import {
    Modal,
    Form,
    Input,
    Switch,
    Divider,
    Button,
    InputNumber 
  } from 'antd';
  import moment from "moment";
import { useEffect, useState } from 'react';

export default function PosSellSettlementClose({companyId, onPosClose}) {

    const [form] = Form.useForm(); 
    const [lastSettlement, setLastSettlement] = useState({});

    useEffect(() => {
        getLastSettlement();
    },[]);

    const getLastSettlement = async() => {
        const lastSettlement = await settlementAPI.getLast(companyId, 0);
        setLastSettlement(lastSettlement || {});
        form.setFieldsValue({
            cashAmountOpen: lastSettlement.startCash,
          });
    }

    const close = async(cashAmount) => {
        const newSettlement = await settlementAPI.close(companyId, cashAmount, 0);
        onPosClose(newSettlement);
    }

    const cancel = () => {
        onPosClose();
    }

    const onClose = async() => {
        form
          .validateFields()
          .then(async(values) => {
            const cashAmount = values.cashAmountClose;
            close(cashAmount);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
    }

    return <div className='grid h-screen place-items-center  '>
        <div>
            <div className='font-bold text-xl text-center'>
                POS 
            </div>
            <div className='pt-7'>
                <Form
                    key={'settelementClose'}
                    form={form}
                    name="settelementClose"
                    id="settelementClose"
                    onFinish={onClose}
                >
                    <Form.Item
                        name="cashAmountOpen"
                        label={`Cash amount on open`}
                        rules={[
                        {
                            required: true,
                            message: 'Please input cash amount',
                        },
                        ]}
                    >
                        <InputNumber disabled />
                    </Form.Item>
                    <Form.Item
                        name="cashAmountClose"
                        label={`Cash amount on close`}
                        rules={[
                        {
                            required: true,
                            message: 'Please input cash amount',
                        },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className='w-full'>
                            CLOSE POS
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button type="ghost" className='w-full' onClick={() => cancel()}>
                            CANCEL
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    </div>;
}