import { API_BASE_ENDPOINT, API_BASE_HEADER, parseMenu, parseCategory, parseMenuLocation, parse } from '../http/apiConfig';
import { message } from 'antd';

class MenuAPI {
   
    getListForCompany = async(companyId) => {
        if (!companyId)
            return [];

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menu/company/${companyId}/list`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return data.map(p => parseMenu(p));
        }catch(e){
            message.error(e.message);
            message.error(e);
        }
    }

    getListForLocation = async(locationId) => {
        if (!locationId)
            return [];

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menu/${locationId}/list`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return data.map(p => parseMenu(p));
        }catch(e){
            message.error(e.message);
            message.error(e);
        }
    }

    create = async(menu) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menu/`,
                await API_BASE_HEADER('POST', menu)
            );
            let data = await response.json();
            return parseMenu(data);
        }catch(e){
            message.error(e.message);
            console.error(e);
        } 
    }

    update = async(id, menu) => {
        if (!id)
            return;
            
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menu/${id}`,
                await API_BASE_HEADER('PUT', menu)
            );
            let data = await response.json();
            return parseMenu(data);
        }catch(e){
            message.error(e.message);
            console.error(e);
        }   
    }

    get = async(id) => {
        if (!id)
            return;

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menu/${id}`,
                await API_BASE_HEADER('GET')
            );
            let data = await response.json();
            return parse(data, {}, parseMenu);
        }catch(e){
            message.error(e.message);
            console.error(e);
        }
    }

    toggleDelete = async(id) => {
        if (!id)
            return;

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menu/${id}/delete`,
                await API_BASE_HEADER('DELETE')
            );
            return response.text();
        }catch(e){
            message.error(e.message);
            console.error(e);
        }   
    }

    updateCategoryOrder = async(id, categoryId, order) => {
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menu/${id}/category/${categoryId}/order/${order}`,
                await API_BASE_HEADER('PATCH')
            ); 
            let data = await response.json();
            return data.map(p => parseCategory(p));
        }catch(e){
            message.error(e.message);
            console.error(e);
        }   
    }

    createMenuQR = async(menuId, qr) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menu/${menuId}/location`,
                await API_BASE_HEADER('POST', qr)
            );
            let data = await response.json();
            return parse(data, {}, parseMenuLocation);
        }catch(e){
            message.error(e.message);
            console.error(e);
            return null;
        } 
    }

    updateMenuQR = async(menuLocationId, qr) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menu/location/${menuLocationId}`,
                await API_BASE_HEADER('PUT', qr)
            );
            let data = await response.json();
            return parse(data, {}, parseMenuLocation);
        }catch(e){
            message.error(e.message);
            console.error(e);
        } 
    }

    toggleDeleteMenuQR = async(menuLocationId) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menu/location/${menuLocationId}/delete`,
                await API_BASE_HEADER('DELETE')
            );
            let data = await response.json();
            return parse(data);
            
        }catch(e){
            message.error(e.message);
            console.error(e);
        } 
    }

    toggleVisibleMenuQR = async(menuLocationId) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menu/location/${menuLocationId}/visible`,
                await API_BASE_HEADER('PATCH')
            );
            let data = await response.json();
            return parse(data);
        }catch(e){
            message.error(e.message);
            console.error(e);
        } 
    }

    publish = async(id) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menu/${id}/publish`,
                await API_BASE_HEADER('PUT')
            );

            let data = await response.json();
            return parse(data);
            
        }catch(e){
            message.error(e.message);
            console.error(e);
        } 
    }
   
};

const menuAPI = new MenuAPI();
export default menuAPI;