import React, { useState, useEffect } from 'react';
import {
    Button,
    Popconfirm,
    Switch,
    Descriptions,
    Divider,
    message,
    Popover,
    QRCode
  } from 'antd';

import { DeleteTwoTone, EditTwoTone, CheckOutlined, CloseOutlined, BorderHorizontalOutlined, LinkOutlined, QrcodeOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getItemStyle, getListStyle, move } from '../CommonDraggable'

import MenuQr from './TableEdit';
import ZoneEdit from './ZoneEdit';

import zoneAPI from '../../http/apiZone';
import tableAPI from '../../http/apiTable';

import { ReactComponent as TakeawayIcon } from '../../assets/mulle_bag_icon.svg';
import { ReactComponent as TableIcon } from '../../assets/mulle_table_icon.svg';

import { ReactComponent as CashIcon } from '../../assets/pos-terminal.svg';
import { ReactComponent as CardIcon } from '../../assets/credit-cards-payment.svg';

export default function TableList(props){
    const [menuLocations, setMenuLocations] = useState([]);
    //const [menuLocationsWithZones, setMenuLocationsWithZones] = useState([]);
    const [menuZones, setMenuZones] = useState([]);
    const [qrModalOpen, setQrModalOpen] = useState(false);
    const [zoneModalOpen, setZoneModalOpen] = useState(false);
    const [selectedMenuLoacation, setSelectedMenuLoacation] = useState({});
    const [selectedZoneLoacation, setSelectedZoneLoacation] = useState({});
    const [zonesWithLocations, setZonesWithLocations] = useState({});

    useEffect(() => { 
        
        initZonesAndTables();

    }, [props.menuId, props.zoneList, props.qrList])

    const initZonesAndTables = () => {
        // group by zones
        let groupedByZone = props.qrList.filter(x => x.menuZone).reduce((map, obj) => {
            if (!map[obj.menuZone.id])
                map[obj.menuZone.id] = [];

            map[obj.menuZone.id].push(obj);

            return map;
        },{});    

        // fill in gaps
        menuZones.map(x => {
            if (!groupedByZone[x.id])
                groupedByZone[x.id] = [];
        });

        setZonesWithLocations(groupedByZone);
        setMenuLocations(props.qrList.filter(x => !x.menuZone)); 
        setMenuZones(sortZones(props.zoneList));
    }

    const onSuccessQrSave = (qr) => {
        props.refresh();
       /* const menuLocation = menuLocations.find(ml => ml.id === qr.id);
        if (menuLocation){
            setMenuLocations([...menuLocations.map(ml => {
                if (ml.id === qr.id)
                    return {...qr};
                
                return ml;
            })]);
        }else{
            setMenuLocations([...menuLocations, {...qr}]);
        }*/
    }

    const sortZones = (zones) => {
        return zones.sort((a, b) => a.name.localeCompare(b.name))
    }

    const onSuccessZoneSave = (zone) => {
        props.refresh();
        /*const menuZoneLocation = menuZones.find(ml => ml.id === zone.id);
        
        if (menuZoneLocation){
            setMenuZones(sortZones([...menuZones.map(ml => {
                if (ml.id === zone.id)
                    return {...zone};
                
                return ml;
            })]));
        }else{
            setMenuZones(sortZones([...menuZones, {...zone}]));
        }*/
    }

    const toggleQrCode = async(menuLocationId) => {
        await tableAPI.toggleVisible(menuLocationId);
        const newMenuLocations = [...menuLocations.map(ml => {
            if (ml.id === menuLocationId)
                return {...ml, visible: !ml.visible }
            
            return ml;
        })];

        setMenuLocations([...newMenuLocations]);
    }

    const downloadQRCode = (table_id) => {
        const canvas = document.getElementById(`table_qr_code_${table_id}`)?.querySelector('canvas');
        console.log('canvas', canvas);
        if (canvas) {
          const url = canvas.toDataURL();
          console.log('url', url);
          const a = document.createElement('a');
          a.download = `table_qr_${table_id}.png`;
          a.href = url;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      };

    const toggleDeleteQrCode = async(menuLocationId) => {
        await tableAPI.delete(menuLocationId);
        await props.refresh();
        //setMenuLocations([...menuLocations.filter(ml => ml.id !== menuLocationId)]);
    }

    const toggleDeleteZone = async(menuZoneId) => {
        await zoneAPI.delete(menuZoneId);
        await props.refresh();
        //setMenuZones([...menuZones.filter(ml => ml.id !== menuZoneId)]);
    }

    const onEdit = (menuLocation) => {
        setSelectedMenuLoacation(menuLocation);
        setQrModalOpen(true);
    }

    const onZoneEdit = (menuZoneLocation) => {
        setSelectedZoneLoacation(menuZoneLocation);
        setZoneModalOpen(true);
    }

    const getDroppableArray = (droppableId) => {
        if (droppableId === 'avaialble_qr_codes'){
            return menuLocations;
        }

        return zonesWithLocations[droppableId] || [];       
    }

    const onDragEnd = async(result) => {
        const { source, destination } = result;

        if (!destination || !source || !destination.droppableId || !source.droppableId) return;

        if (source.droppableId !== destination.droppableId) {
            const sourceBefore = getDroppableArray(source.droppableId);
            const desitinationBefore = getDroppableArray(destination.droppableId);
            const result = move(
                sourceBefore,
                desitinationBefore,
                source,
                destination
            );

            const sourceItem = sourceBefore[source.index];
            //console.log(`result: ${JSON.stringify(result)}`)    
            //console.log(`source: ${JSON.stringify(sourceItem)} destination: ${destination.droppableId}`);
            if (destination.droppableId !== 'avaialble_qr_codes'){
                await zoneAPI.assign(destination.droppableId, sourceItem.id);
                
                //console.log({...zonesWithLocations, [destination.droppableId]: result[destination.droppableId]})
                setMenuLocations([...result[source.droppableId]]);
                setZonesWithLocations({...zonesWithLocations,[destination.droppableId]: result[destination.droppableId]})
            }else {
                await zoneAPI.deleteAssigment(source.droppableId, sourceItem.id);
                
                //console.log({...zonesWithLocations, [source.droppableId]: result})

                setMenuLocations([...result[destination.droppableId]]);
                setZonesWithLocations({...zonesWithLocations, [source.droppableId]: result[source.droppableId]});
            }

            
        }
    }

    const renderTableQr = (item) => {
        
        return <div className="flex flex-col shadow-md rounded-lg p-3 h-48 w-18">
        <div><div className="font-bold text-base">{item.name}</div></div>
        <div className="font-bold">{item.data.externalId || ""}</div>
        <div className="flex pt-2 h-10 space-x-2">

            {item.data.isTable ?  <div><TableIcon className="h-6 w-6 fill-current text-black"/></div> : ''}
            {item.data.isAllowTakeaway ?  <div><TakeawayIcon className="h-4 w-4 fill-current text-black"/></div> : ''}
            {item.data.isAllowCash ?  <div className=""><CashIcon className="h-5 w-5 fill-current text-black"/></div> : ''}
            {item.data.isAllowCards ?  <div className=""><CardIcon className="h-6 w-6 fill-current text-black"/></div> : ''}
        </div>
        <div className='flex h-full'>
            <div className='text-center p-3 m-auto text-sm truncate '>{item.data.qr}</div>
        </div>

        <div className="flex justify-between">
            <div>Active:</div>
            <div><Switch
                size="small"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={() => toggleQrCode(item.id)}
                checked={item.visible}
            />
            </div>
        </div>
        <div className="flex justify-between">
            <div className="pt-2"><EditTwoTone onClick={() => onEdit(item)}/></div>
            <div className="pt-2"><LinkOutlined onClick={()=> window.open(`https://app.mulle.ee/qr/${item.data.qr}`, "_blank")}/></div>
            <div className="pt-2">
            <Popover content={<div onClick={() => downloadQRCode(`${item.data.qr}`) } bgColor="#fffff" id={`table_qr_code_${item.data.qr}`}><QRCode className='cursor-pointer' value={`https://app.mulle.ee/qr/${item.data.qr}`} /><div>click on QR to download</div></div>}>
                        <QrcodeOutlined />
                    </Popover>
                
            </div>
            <div>
                <Popconfirm
                    title="Are you sure to delete this qr code?"
                    onConfirm={() => toggleDeleteQrCode(item.id)}
                >
                    <Button type="link" icon={<DeleteTwoTone twoToneColor="#eb2f96"/>}></Button>
                </Popconfirm>
            </div>
            
        </div>
    </div>
    }

    const bulkUpdateSetting = async(name, value) => {
        await tableAPI.bulkUpdateSetting(props.menuId, name, value);
        await props.refresh();
        message.success('Bulk update setting completed!');
    }

    return (
        <div>
        <MenuQr 
            isModalOpen={qrModalOpen} 
            setModalOpen={setQrModalOpen} 
            onSuccess={onSuccessQrSave}
            menuId={props.menuId}
            menuLocation={selectedMenuLoacation}
            menuLocationId={selectedMenuLoacation.id}
        />
         <Descriptions 
            title="Bulk settings"    
            subTitle="apply for all Table/QR codes"
        >
            <Descriptions.Item label="Ask for name before order">
                <div className='flex gap-2'>
                    <div><button className='px-1 rounded-md' onClick={() => bulkUpdateSetting('isAskForNameBeforeOrder', true)}>ON</button></div>
                    <div><button className='px-1 rounded-md' onClick={() => bulkUpdateSetting('isAskForNameBeforeOrder', false)}>OFF</button></div>
                </div>
            </Descriptions.Item>
            <Descriptions.Item label="Ask for phone before order">
                <div className='flex gap-2'>
                    <div><button className='px-1 rounded-md' onClick={() => bulkUpdateSetting('isAskForPhoneBeforeOrder', true)}>ON</button></div>
                    <div><button className='px-1 rounded-md' onClick={() => bulkUpdateSetting('isAskForPhoneBeforeOrder', false)}>OFF</button></div>
                </div>
            </Descriptions.Item>
            <Descriptions.Item label="Ask for email before order">
                <div className='flex gap-2'>
                    <div><button className='px-1 rounded-md' onClick={() => bulkUpdateSetting('isAskForEmailBeforeOrder', true)}>ON</button></div>
                    <div><button className='px-1 rounded-md' onClick={() => bulkUpdateSetting('isAskForEmailBeforeOrder', false)}>OFF</button></div>
                </div>
            </Descriptions.Item>
            <Descriptions.Item label="Ask for seat nr before order (poker table)">
                <div className='flex gap-2'>
                    <div><button className='px-1 rounded-md' onClick={() => bulkUpdateSetting('isAskForSeatBeforeOrder', true)}>ON</button></div>
                    <div><button className='px-1 rounded-md' onClick={() => bulkUpdateSetting('isAskForSeatBeforeOrder', false)}>OFF</button></div>
                </div>
            </Descriptions.Item>
            <Descriptions.Item label="Allow Cash payments">
                <div className='flex gap-2'>
                    <div><button className='px-1 rounded-md' onClick={() => bulkUpdateSetting('isAllowCash', true)}>ON</button></div>
                    <div><button className='px-1 rounded-md' onClick={() => bulkUpdateSetting('isAllowCash', false)}>OFF</button></div>
                </div>
            </Descriptions.Item>
             <Descriptions.Item label="Allow Card payments">
                <div className='flex gap-2'>
                    <div><button className='px-1 rounded-md' onClick={() => bulkUpdateSetting('isAllowCards', true)}>ON</button></div>
                    <div><button className='px-1 rounded-md' onClick={() => bulkUpdateSetting('isAllowCards', false)}>OFF</button></div>
                </div>
            </Descriptions.Item>
        </Descriptions>
        
        <Descriptions 
            title="Table/QR codes" 
            extra={
                [
                    <div className="flex space-x-2">
                        <div>
                            <Button className="w-40" onClick={() => onEdit('')}  icon={<QrcodeOutlined /> } key="qr_button">
                                New QR Code
                            </Button>
                        </div>
                      
                    </div>
                ]
            }
        >
        </Descriptions>

        <ZoneEdit
          isModalOpen={zoneModalOpen} 
          setModalOpen={setZoneModalOpen} 
          onSuccess={onSuccessZoneSave}
          menuId={props.menuId}
          menuZone={selectedZoneLoacation}
          menuZoneId={selectedZoneLoacation.id}
        >
        </ZoneEdit>

        <DragDropContext onDragEnd={onDragEnd}>
        { 
            menuLocations && menuLocations.length > 0 
            ?
            <Droppable droppableId={'avaialble_qr_codes'} key={'avaialble_qr_codes'} type="qr_code" direction="horizontal">
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                    <div className="gap-3 grid 2xl:grid-cols-10 xl:grid-cols-8 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2 grid-spa">
                        {menuLocations.sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => 
                        <Draggable
                            key={item.id}
                            draggableId={`${item.id}`}
                            index={index}

                            >
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                    
                                >
                                   {renderTableQr(item)}
                                </div>
                            )}
                        </Draggable>
                        )}

                    </div>
                </div>)}
            </Droppable>
            : <></>
        }
        <Divider/>
        <Descriptions 
            title="Zones" 
            extra={
                [
                    <div className="flex space-x-2">
                        
                        <div>
                            <Button className="w-40" onClick={() => onZoneEdit('')} icon={<BorderHorizontalOutlined />} key="zone_button">
                                New Zone
                            </Button>
                        </div>
                    </div>
                ]
            }
        >
        </Descriptions>
        { 
            menuZones && menuZones.length > 0 
            ?
            <div className="grid 2xl:grid-cols-6 xl:grid-cols-4 lg:grid-cols-5 md:grid-cols-2 sm:grid-cols-2">
                {
                menuZones.map((item, index) => 
                    <Droppable droppableId={item.id} key={item.id} type="qr_code" direction="vertical">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >   
                            <div className="flex flex-col shadow-md rounded-lg p-3 min-h-screen">
                            <div className="flex justify-between pt-4">
                                <div className="pt-2"><EditTwoTone onClick={() => onZoneEdit(item)}/></div>
                                <div className="text-center font-bold text-lg">{item.name}</div>
                                <div>
                                    <Popconfirm
                                        title="Are you sure to delete this qr code?"
                                        onConfirm={() => toggleDeleteZone(item.id)}
                                    >
                                    <Button type="link" icon={<DeleteTwoTone twoToneColor="#eb2f96"/>}></Button>
                                    </Popconfirm></div>
                            </div>
                           
                            <div className=""></div>
                                {
                                    zonesWithLocations[item.id] && zonesWithLocations[item.id].map((locationInZone, index) =>
                                        <Draggable
                                            key={locationInZone.id}
                                            draggableId={`${locationInZone.id}`}
                                            index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        {renderTableQr(locationInZone)}
                                                        
                                                    </div>
                                        )}
                                        </Draggable>

                                    )
                                }
                           </div>
                    </div>)}
                    </Droppable>
                    )
                }
            </div>

           
            : <></>
            
        
        }
         </DragDropContext>
        </div>
    )
}