

import React, { useState } from 'react'
import { useEffect } from 'react';

import { useCompany } from '../hooks/CompanyProvider';
import { useMulleUser } from '../hooks/UserProvider';

import { Layout, Badge, Button, Drawer } from 'antd';
import { collection, query, where, limit } from "firebase/firestore";
import { useFirestore,  useFirestoreCollection } from 'reactfire';

import { ReactComponent as CallWaiterIcon } from '../assets/call_waiter_icon.svg';
import callWaiterAPI from '../http/apiCallWaiter';
import logo from '../assets/mulle_logo_color.svg';
import ringer from "../assets/media/new_order.mp3";



export default function CallToWaiter({useDrawer = false}) {
    const audio = new Audio(ringer);

    const [myTableList, setMyTableList] = useState([]);
    
    const user = useMulleUser();
    const company = useCompany();

    const [open, setOpen] = useState(false);
    const firestore = useFirestore();
    const firestoreCollection = collection(firestore, 'callwaiter');

    
    
    const { status, data: snapshot } = useFirestoreCollection(query(firestoreCollection,
                                        where("CompanyId", "==", company.companyId),
                                        where("WaiterResponded", "==", false),
                                        myTableList.length > 0 ? where("Qr", "in", [...myTableList.map(t => t.data.qr)]) : where("Qr", "==", ""),
                                        limit(30))
                                        , {
                                            idField: 'DocumentId'
                                        });

    const notifyMe = (table) => {
        audio.loop = false;
        audio.play();
        
        if (!("Notification" in window)) {
            // Check if the browser supports notifications
            //alert("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
            // Check whether notification permissions have already been granted;
            // if so, create a notification
            const n = new Notification(`${table.Qr} calling!`, { requireInteraction: true, icon: logo, silent: true, renotify: false  });
        
            // …
        } else if (Notification.permission !== "denied") {
            // We need to ask the user for permission
            Notification.requestPermission().then((permission) => {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
                const n = new Notification(`${table.Qr} calling!`, { requireInteraction: true, icon: logo, silent: true, renotify: false });
            }
            });
        } else if (Notification.permission === "denied") {
        
        }
    }

    useEffect(() => {
        const getMyTables = async() => {
            const tableList = await callWaiterAPI.getMyTables();
            setMyTableList(tableList);
        }

        getMyTables();
    }, [])

    useEffect(() => {
        if (calls().length > 0){
            snapshot.docChanges().map((change) => {
                if (change.type === "added") {  
                    const doc = change.doc.data();
                    notifyMe(doc);
                }
            });
        }
        }, [calls().length])


    function calls() { return snapshot.docs.map(d => { return { DocumentId: d.id, ...d.data()}; } ); } //snap.docs.map(d => d.data());

   

    const acknowledgeCall = async(eventId, firebaseId) => {
        await callWaiterAPI.resolveCallWaiterCall(eventId, firebaseId);
    }

    return <div>
        <div>
            { useDrawer 
                ? <div>
                    
                    <Badge count={calls().length} size='small'><Button className={`${calls().length > 0 ? 'bg-yellow-400 animate-pulse' : ''} `} type="dashed" shape="" icon={<CallWaiterIcon />} size='medium' onClick={() => setOpen(true)}></Button></Badge>
                    <Drawer
                        title="List of tables calling for waiter"
                        placement="right"
                        closable={true}
                        open={open}
                        onClose={() => setOpen(false)}
                        width={320}
                        >
                        <div className='flex justify-center'>
                        { calls().length > 0 && calls().map(c => 
                            <div className='w-60 h-16 bg-gray-500 rounded-lg pt-2 text-white text-lg pt-100 cursor-pointer' onClick={() => { acknowledgeCall(c.Id, c.DocumentId); }} >
                                <div className='text-center text-2xl font-bold'>{c.Qr}</div>
                                <div className='text-xs text-center text-green-500 font-bold pt-1'>Press To Acknowledge Call</div>
                            </div>)
                        }
                        </div>
                    </Drawer>
                </div> 
                : <div><div className='text-2xl pt-10'>Table calls:</div>
                    { calls().length > 0 && calls().map(c => 
                    <div className='w-48 h-16 rounded-lg pt-2 bg-gray-500 text-white text-lg pt-100 cursor-pointer' onClick={() =>  { acknowledgeCall(c.Id, c.DocumentId); }} >
                        <div className='text-center'>Table: <strong className='text-white'>{c.Qr}</strong></div>
                        <div className='text-xs text-center text-green-500 font-bold pt-2'>Press To Acknowledge Call</div>
                    </div>) }
                </div>
            
            }
            
        </div>
    </div>
}