import { API_BASE_ENDPOINT, API_BASE_HEADER, parseProduct, parse } from '../http/apiConfig';
import { message } from 'antd';

class ProductAPI {
  
    getListForCompany = async(companyId) => {
        if (!companyId)
            return [];
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/product/list/${companyId}/company`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return parse(data, [], parseProduct);
        }catch(e){
            console.error(e);
            return [];
        }
    }

    getIntegrationListForCompany= async(companyId) => {
        if (!companyId)
            return [];
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/product/list-integration/${companyId}/company`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return parse(data, [], parseProduct);
        }catch(e){
            console.error(e);
            return [];
        }
    }

    getListForCompanyPos = async(companyId, pos = 0) => {
        if (!companyId)
            return [];
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/pos/product/${companyId}/pos/${pos}/list`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return parse(data, [], parseProduct);
        }catch(e){
            console.error(e);
            return [];
        }
    }

    getListForCategory = async(category) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/product/list/${category}/category`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return parse(data, [], parseProduct);
        }catch(e){
            console.error(e);
            return [];
        }
    }

    create = async(companyId, product) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/product/${companyId}/company`,
                await API_BASE_HEADER('POST', product)
            );
            let data = await response.json();
            return parse(data, {}, parseProduct);
        }catch(e){
            console.error(e);
            message.error(e.message);
            return null;
        } 
    }

    update = async(productId, product) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/product/${productId}`,
                await API_BASE_HEADER('PUT', product)
            );
            let data = await response.json();
            return parse(data, {}, parseProduct);
        }catch(e){
            console.error(e);
            message.error(e.message);
            return null;
        }   
    }

    get = async(productId) => {
        if (!productId)
            return;

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/product/${productId}`,
                await API_BASE_HEADER('GET')
            );
            let data = await response.json();
            return parse(data, {}, parseProduct);
        }catch(e){
            message.error(e.message);
            console.error(e);
            return {};
        }
    }

    toggleDelete = async(productId) => {
        if (!productId)
            return;

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/product/${productId}/delete`,
                await API_BASE_HEADER('DELETE')
            );
          
            let data = await response.json();
            return parse(data, {}, parseProduct);
        }catch(e){
            message.error(e.message);
            console.error(e);
            return {};
        }   
    }

    toggleVisible = async(productId) => {
        if (!productId)
            return;

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/product/${productId}/visible`,
                await API_BASE_HEADER('DELETE')
            );
          
            let data = await response.json();
            return parse(data, {}, parseProduct);
        }catch(e){
            message.error(e.message);
            console.error(e);
            return {};
        }   
    }

    getListOptions = async() => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/product/options`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            if (!data)
                return [];
                
            return data.map(p => { p.data = JSON.parse(p.data); return p;});
        }catch(e){
            message.error(e.message);
            console.error(e);
            return [];
        }
    }

    importProductFromCompucash = async(companyId) => {
        try {
            await fetch(
                `${API_BASE_ENDPOINT}/api/product/compucash/company/${companyId}/import`,
                await API_BASE_HEADER('PUT')
            );
        }catch(e){
            message.error('Error importing products');
            console.error(e);
            return [];
        }
    }

    createRelation = async(relationObj) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/product/related`,
                await API_BASE_HEADER('POST', relationObj)
            );
           // let data = await response.json();
           // return parse(data, {}, parseProduct);
        }catch(e){
            console.error(e);
            message.error(e.message);
            return null;
        } 
    }

    deleteRelation = async(productId, relatedProductId) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/product/${productId}/related/${relatedProductId}`,
                await API_BASE_HEADER('DELETE')
            );
           // let data = await response.json();
           // return parse(data, {}, parseProduct);
        }catch(e){
            console.error(e);
            message.error(e.message);
            return null;
        } 
    }

};



const productAPI = new ProductAPI();
export default productAPI;