
export const PICKUP_SELF = 'SELF';

export const DELIVERY_TAKEAWAY = 'TAKEAWAY';
export const DELIVERY_RESTAURANT = 'RESTAURANT';

export const DELIVERY_TIME_NOW = 'NOW';
export const DELIVERY_TIME_CUSTOM = 'CUSTOM';

export const PAYMENT_STRIPE = 'ONLINE';
export const PAYMENT_POS = 'LOCAL';
export const PAYMENT_CARD = 'CARD';
export const PAYMENT_CASH = 'CASH';

export const OPTION_SINGLE = 'SINGLE';
export const OPTION_MULTIPLE = 'MANY';

export const PAYMENT_PAID = "PAID";
export const PAYMENT_AWAIT = "AWAITING";
export const PAYMENT_UNPAID = "UNPAID";
export const PAYMENT_ERROR = "ERROR";

export const INSERTED = "INSERTED";
export const CONFIRMED = "CONFIRMED";
export const PROGRESS = "PROGRESS";
export const READY = "READY";
export const PAYED = "PAID";
export const DELIVERED = "DELIVERED";
export const CANCELED = "CANCELED";
export const COMPLETED = "COMPLETED";

export const OPTION_SINGLE_TYPE = "SINGLE";
export const OPTION_CHECK_TYPE = "MANY";

export const PRODUCT_PROMOTION = "PROMOTION";
export const PRODUCT_TYPE = "TYPE";
export const PRODCUT_ALLERGY_TYPE = "ALLERGY";
export const PRODCUT_CATEGORY_TYPE = "CATEGORY";
export const PRODCUT_SPICY_TYPE = "HOT";

export const ORDERS_ROLE_COOK = 'COOK';
export const ORDERS_ROLE_SERVE = 'SERVE';

export const DEFAULT_CURRENCY = 'EUR';

export const INGRIDIENT_CUSTOM = 'CUSTOM';
export const INGRIDIENT_STOCK = 'STOCK';

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const OREDER_STATUS_BY_ORDER = {
    1: INSERTED, 
    2: PROGRESS, 
    3: READY, 
    4: DELIVERED, 
    5: COMPLETED
};

export const OREDER_STATUS_BY_NAME = {
    INSERTED:   1, 
    PROGRESS:   2, 
    READY:      3, 
    DELIVERED:  4,
    COMPLETED:  5
};


export const OREDER_STATUS_ACTION = {
    INSERTED:   'CONFIRM', 
    PROGRESS:  'READY', 
    READY:      'DELIVERED', 
    DELIVERED:  'COMPLETE'
};



export const getGross = (netPrice, vat) => {
    if (!netPrice || !vat) return netPrice;
    const netPriceFormat = formatPrice(netPrice);
    const grossPriceFormat = formatPrice(netPriceFormat * ((parseFloat(vat) / 100) + 1));
    return grossPriceFormat;
}

export const getNet = (grossPrice, vat) => {
    if (!grossPrice  || !vat) return grossPrice;
    const grossPriceFormat = formatPrice(grossPrice);
    const netPriceFormat = formatPrice(grossPriceFormat / ((parseFloat(vat) / 100) + 1));
    return netPriceFormat;
}


export const formatPrice = (price) => {
    if (!price)
        return 0.00;
    
    return Number(parseFloat(price)).toFixed(2);
}

export const calculateTotal = (products) => { 
  const productsTotal = products.reduce((sum, obj) => { 
  console.log(obj);
  sum += ((obj.data ? obj.data.gross : obj.gross) * obj.count); 
  
  if (obj.selectedOption) {
      obj.selectedOption.map(option => {
      
      sum += (option.options.reduce((optionSum, o) => {
          optionSum += (o.gross || o.net);
          return optionSum;
          }, 0) * obj.count);  

          return sum;
      });
  }

  return sum; 
  }, 0);
  
  return productsTotal;
}

export const getDomainForReservation = () => {
  if (process.env.NODE_ENV === 'development'){
      return 'localhost:3001';
  }

  return window.location.hostname.indexOf('mulle.ee') > -1 ? 'mulle.ee' : 'mulle.ng';;
}

export const formatCurrencty = (price, currency) => {
    
    const currencyFormater = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: !currency ? DEFAULT_CURRENCY : currency,
      });

    let formattedPrice = currencyFormater.format(price);
    if (!price && price !== 0){
        formattedPrice = formattedPrice.replace('0.00','');
    }

    const splitPrice = formattedPrice.split('.');
    if (splitPrice.length > 1) {
        if (splitPrice[1] === '00'){
            return splitPrice[0];
        }
        if (splitPrice[1].endsWith(0)){
            formattedPrice = formattedPrice.substring(0, formattedPrice.length - 1)
        }
    }
    
    return formattedPrice;
}

export const Spinner = ({color}) => {
    return <svg className={"animate-spin -ml-1 mr-3 h-5 w-5 " + (color ? color : 'text-red')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
}


const reorder = (list, startIndex, endIndex) => {
    let result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const getMultiLang = (data, field, lang = 'et') => {
    if (!data || !field || !data[field])
        return '';

    var multiLangStr = Object.keys(data[field]);
    if (multiLangStr.length > 0){
        let eeIndex = multiLangStr.indexOf(lang);
        return data[field][eeIndex > -1 ? multiLangStr[eeIndex] : multiLangStr[0]];
    }
    else
        return '';
}

export  const getProductSmallImage = (product) => { 
    if (product.images && product.images.length > 0){
        let foundProduct = product.images.find(i => i.small === true || !i.hasOwnProperty('small'));
        if (!foundProduct){
            foundProduct = product.images[0];
        }
        return foundProduct.url;
    }

    return false
}


export function toLowerCase(o) {
    var newO, origKey, newKey, value
    if (o instanceof Array) {
      return o.map(function(value) {
          if (typeof value === "object") {
            value = toLowerCase(value)
          }
          return value
      })
    } else {
      newO = {}
      for (origKey in o) {
        if (o.hasOwnProperty(origKey)) {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
          if (origKey == 'et' || origKey == 'en' || origKey == 'ru' || origKey == 'PAID'){
            newKey = origKey;
          }
          value = o[origKey]
          
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = toLowerCase(value)
          }
            newO[newKey] = value
        }
      }
    }
    return newO
  }

export function toUpperCase(o) {
    var newO, origKey, newKey, value
    if (o instanceof Array) {
      return o.map(function(value) {
          if (typeof value === "object") {
            value = toUpperCase(value)
          }
          return value
      })
    } else {
      newO = {}
      for (origKey in o) {
        if (o.hasOwnProperty(origKey)) {
          newKey = (origKey.charAt(0).toUpperCase() + origKey.slice(1) || origKey).toString()
          if (origKey == 'et' || origKey == 'en' || origKey == 'ru' || origKey == 'seconds' || origKey == 'nanoseconds'){
            newKey = origKey;
          }
          value = o[origKey]
          
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = toUpperCase(value)
          }
          newO[newKey] = value
        }
      }
    }
    return newO
  }