
import React, { useRef, useState, useEffect } from 'react';
import unitAPI from '../../http/apiUnit';
import { v4 as uuidv4 } from 'uuid';
import { useCompany } from '../../hooks/CompanyProvider';

const SupplyIndex = () => {

    return <>
    <div>
    SupplyIndex
    </div>
    </>
}

export default SupplyIndex;