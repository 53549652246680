import { API_BASE_ENDPOINT, API_BASE_HEADER, parseUser, parse } from '../http/apiConfig';

class UserAPI {
  
    getList = async(role) => {
      
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/user?role=${role}`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return parse(data, [], parseUser);
        }catch(e){
            console.error(e);
            return [];
        }
    }

    create = async(user) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/user`,
                await API_BASE_HEADER('POST', user)
            );
            let data = await response.json();
            return parse(data, {}, parseUser);
        }catch(e){
            console.error(e);
            return null;
        } 
    }

    update = async(user) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/user`,
                await API_BASE_HEADER('PUT', user)
            );
            let data = await response.json();
            return parse(data, {}, parseUser);
        }catch(e){
            console.error(e);
            return null;
        }   
    }

    get = async(userId) => {
        if (!userId)
            return;

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/user/${userId}`,
                await API_BASE_HEADER('GET')
            );
            let data = await response.json();
            return parse(data, {}, parseUser);
        }catch(e){
            console.error(e);
            return {};
        }
    }

    me = async() => {
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/user/me`,
                await API_BASE_HEADER('GET')
            );
            let data = await response.json();
            return parse(data, {}, parseUser);
        }catch(e){
            console.error(e);
            return false;
        }
    }

    roles = async() => {
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/user/roles`,
                await API_BASE_HEADER('GET')
            );
            let data = await response.json();
            return parse(data, [], parseUser);
        }catch(e){
            console.error(e);
            return {};
        }
    }

    toggleActive = async(userId) => {
        if (!userId)
            return;

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/user/${userId}`,
                await API_BASE_HEADER('DELETE')
            );
          
            let data = await response.json();
            return parse(data, {}, parseUser);
        }catch(e){
            console.error(e);
            return {};
        }   
    }

    delete = async(userId) => {
        if (!userId)
            return;

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/user/${userId}`,
                await API_BASE_HEADER('DELETE')
            );
          
            let data = await response.json();
            return parse(data, {}, parseUser);
        }catch(e){
            console.error(e);
            return {};
        }   
    }

   

};

const userAPI = new UserAPI();
export default userAPI;