import { API_BASE_ENDPOINT, API_BASE_HEADER, parseData, parse } from '../http/apiConfig';
import { message } from 'antd';

class ProductPropertyAPI {

    getListProperties = async(companyId) => {
        if (!companyId)
            return [];
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/productproperty/${companyId}`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            if (!data || data.length === 0)
                return [];
            
            return data.map(p => parseData(p));
        }catch(e){
            message.error(e.message);
            console.error(e);
            return [];
        }
    }

    create = async(companyId, property) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/productproperty/${companyId}`,
                await API_BASE_HEADER('POST', property)
            );
            let data = await response.json();
            return parse(data, {}, parseData);
        }catch(e){
            console.error(e);
            message.error(e.message);
            return null;
        } 
    }

    update = async(id, property) => {
        if (!id || !property)
            return;

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/productproperty/${id}`,
                await API_BASE_HEADER('PUT', property)
            );
            let data = await response.json();
            return parse(data, {}, parseData);
        }catch(e){
            console.error(e);
            message.error(e.message);
            return null;
        }   
    }

    delete = async(id) => {
        if (!id)
            return;

        try {
            await fetch(
                `${API_BASE_ENDPOINT}/api/productproperty/${id}`,
                await API_BASE_HEADER('DELETE')
            );
        }catch(e){
            message.error(e.message);
            console.error(e);
            return {};
        }   
    }
};



const productPropertyAPI = new ProductPropertyAPI();
export default productPropertyAPI;