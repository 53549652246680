import { API_BASE_ENDPOINT, API_BASE_HEADER, parse } from './apiConfig';
import { message } from 'antd';

class WarehouseAPI {
  
    getList = async(companyId) => {
      
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/supply/${companyId}`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return parse(data);
        }catch(e) {
            message.error(e);
            return false;
        }
    }

    getCustomList = async(companyId) => {
      
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/supply/custom/${companyId}`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return parse(data);
        }catch(e) {
            message.error(e);
            return false;
        }
    }


    add = async(companyId, supplyObj) => {
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/supply/${companyId}`,
                await API_BASE_HEADER('POST', supplyObj)
            );
            let data = await response.json();
            return parse(data);
        }catch(e){
            console.error(e);
            return null;
        } 
    }

    addCustom = async(companyId, customObj) => {
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/supply/custom/${companyId}`,
                await API_BASE_HEADER('POST', customObj)
            );
            let data = await response.json();
            return parse(data);
        }catch(e){
            console.error(e);
            return null;
        } 
    }
}

const warehouseAPI = new WarehouseAPI();
export default warehouseAPI;