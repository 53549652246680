
import React, { useState, useEffect } from 'react';
import {
    Button,
    Layout,
    Radio,
    Badge,
    Popconfirm,
    Popover,
    QRCode 
  } from 'antd';
  import { DeleteTwoTone, EditTwoTone, SyncOutlined, CloseOutlined, BorderHorizontalOutlined, LinkOutlined, QrcodeOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { useCompany } from '../../hooks/CompanyProvider';
import reservationAPI from '../../http/apiReservation';
import dayjs from 'dayjs';
import ReservationEdit from './ReservationEdit';
import menuAPI from '../../http/apiMenu';
import { getDomainForReservation } from '../Common';

export default function ReservationIndex() {

    const [reservationGroups, setReservationGroups] = useState({});
    const [reservations, setReservations] = useState([]);
    const [tables, setTables] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selectedMenu, setSelectedMenu] = useState();
    const [selectedZone, setSelectedZone] = useState();
    const [selectedLocation, setSelectedLocation] = useState();

    const [selectedReservation, setSelectedReservation] = useState({});
    const [edit, setEdit] = useState(false);
    const [menus, setMenus] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const company = useCompany();

    useEffect(() => { console.log('selectedLocation', selectedLocation)}, [selectedMenu, selectedZone, selectedLocation]);

    useEffect(() => {
        if (company.companyId && company.companyId.length > 0) {
            getReservationList()
            getMenuList();
        }
    }, [company.companyId, refresh]);

    const getMenuList = async() => {
        setLoading(true);

        const menuList = await menuAPI.getListForCompany(company.companyId);

        setMenus(menuList || []);
        selectMenu(selectedMenu ? selectedMenu : (menuList && menuList.length > 0) ? menuList[0].id : null, menuList);
        setTables(menuList.map(m => m.menuLocations).flat());

        setLoading(false);
    }


    const downloadQRCode = () => {
        const canvas = document.getElementById('reservation_qr_code')?.querySelector('canvas');
        console.log('canvas', canvas);
        if (canvas) {
          const url = canvas.toDataURL();
          console.log('url', url);
          const a = document.createElement('a');
          a.download = 'reservation.png';
          a.href = url;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      };

    const getReservationList = async() => {
        setLoading(true);

        const reservationResponse = await reservationAPI.get(company.companyId, null);
        setReservations(reservationResponse || []);

        // group reservations by locationId, then by date and sort by time by using bookDateTime
        let reservationGroups = reservationResponse.reduce((acc, reservation) => {
            reservation.dateObject = dayjs(reservation.bookDateTime);
                const date = reservation.dateObject.format('YYYY-MM-DD');
                if (!acc[date]){
                    acc[date] = [];
                }
                
                reservation.time = reservation.dateObject.format('HH:mm');
                acc[date].push(reservation);
                return acc;
        }, {});

        // sort reservations by date
        Object.keys(reservationGroups).forEach(key => {
            reservationGroups[key] = reservationGroups[key].sort((a,b) => a.dateObject - b.dateObject);
        });

        
        setReservationGroups(reservationGroups);
        setLoading(false);
    }    

    const confirmReservation = async(reservationId) => {
        await reservationAPI.confirm(reservationId);
        await getReservationList();
    }

    const renderReservation = (reservation) => {
        return <div className='flex flex-col shadow-md rounded-lg p-3 h-80 w-full'>
            <div className='pt-3 text-xs underline'>Customer name:</div>
             <div className="text-left">{reservation.data.customerName}</div>
             <div className='pt-3 text-xs underline'>Customer email:</div>
             <div className="text-left truncate overflow-hidden">{reservation.email || reservation.phone}</div>
             <div className='pt-3 text-xs underline'>Customer comment:</div>
             <div className='text-wrap'>{reservation.data.customerComment || 'none'}</div>
             <div className='pt-3 text-xs underline'>My notes:</div>
             <div className='text-wrap flex-grow'>{reservation.data.restaurantComment || 'none'}</div>
             <div className='pt-3 text-xs underline'>Reserved:</div>
             <div className='font-bold'>{tables.find(t => t.id === reservation.data.restaurantTable)?.name ?? 'not reserved' }</div>
            
             <div className="flex justify-between pt-4">
                <div className="pt-2">
                    <EditTwoTone onClick={() => onReservationEdit(reservation)}/>
                </div>
                <div className='flex justify-center cursor-pointer pl-2' onClick={() => confirmReservation(reservation.id)}>
                    <div className={`pt-3 font-bold text-md text-center h-10 w-16 rounded-lg ${reservation.confirmed ? 'bg-green-500' : 'bg-red'} text-white`}>{reservation.time}</div>
                </div>   
                <div>
                    <Popconfirm
                        title="Are you sure to DELETE this reservation?"
                        onConfirm={() => toggleDeleteReservation(reservation.id)}
                    >
                    <Button type="link" icon={<DeleteTwoTone twoToneColor="#eb2f96"/>}></Button>
                    </Popconfirm>
                </div>
             </div>
           
            
        </div>
    }

    const toggleDeleteReservation = async(reservationId) => {
        await reservationAPI.delete(reservationId);
        await getReservationList();
    }

    const onReservationEdit = async(reservation) => {
        setSelectedReservation(reservation);
        setEdit(true);
    }

    const onSuccessSave = () => {
        setEdit(false);
        setSelectedReservation(null);
        getReservationList();
    }

    const selectMenu = (id, externalMenus) => {
        setSelectedMenu(id);
        setSelectedLocation((externalMenus || []).find(m => m.id === id)?.locationId);
        setSelectedZone(null);
    }
    
    return (
        <Layout style={{ height: "100vh", overflow: "auto" }} >
              <PageHeader
                className="site-page-header"
                title={'Reservations'}
                subTitle="Mannage resertvations for your locations"
            >
                 <ReservationEdit 
                    isModalOpen={edit} 
                    setModalOpen={setEdit} 
                    onSuccess={onSuccessSave}
                    companyId={company.companyId}
                    locationId={selectedLocation}
                    zoneId={selectedZone}
                    tables={tables}
                    reservation={selectedReservation}
                    reservationId={(selectedReservation || {}).id}
                />

                <div className='float-right flex gap-2'>
                    { <Button key="3" type='primary' onClick={() => {
                        window.open(`https://${getDomainForReservation()}/reservation/${selectedLocation}`, '_blank')
                    }}>Reservation page <LinkOutlined />
                    </Button>
                    }
                    {/*<Button className="w-40 mr-3" onClick={() => setEdit(true) }  icon={<QrcodeOutlined /> } key="new_reservatoion_button">
                        New Reservation
                </Button>*/}
                    <Popover
                        
                        content={<div onClick={() => downloadQRCode(`https://${getDomainForReservation()}/reservation/${selectedLocation}`) } bgColor="#fff" id={'reservation_qr_code'}><QRCode className='cursor-pointer' value={`https://${getDomainForReservation()}/reservation/${selectedLocation}`} /></div>}
                    >
                        <Button type="primary">QR Code</Button>
                    </Popover>
                </div>
                
                
                
                <div className='pt-3 flex'>
                        <div className="pt-1.5 px-3">
                            <SyncOutlined style={{fontSize: "20px"}} onClick={ () => setRefresh(!refresh)} spin={loading}/>
                        </div>
                { menus && menus.length > 0 && <Radio.Group
                    
                    onChange={(e) => { selectMenu(e.target.value, menus); }}
                    value={selectedMenu}
                    optionType="button"
                    buttonStyle="solid"
                    >
                        {
                            menus.map(l => { return <Badge count={reservations.length > 0 ? reservations.filter(r => r.locationId === l.locationId && !r.confirmed).length : ''}><Radio.Button value={l.id}>{l.name}</Radio.Button></Badge>})
                        }
                        
                    </Radio.Group>
                
                }
                </div>
                {selectedMenu && menus && menus.length > 0 && <div className='pt-3'><Radio.Group
                    options={menus.find(x => x.id === selectedMenu)?.menuZones.map(l => { return { label: l.name, value: l.id} }) || []}
                    onChange={(e) => setSelectedZone(e.target.value)}
                    value={selectedZone}
                    optionType="button"
                    buttonStyle="solid"
                    /></div>
                }
                
            </PageHeader>
            <div className='px-5'>
                {
                    selectedMenu && reservationGroups && Object.keys(reservationGroups).length > 0 && Object.keys(reservationGroups).sort((a,b) => dayjs(a) - dayjs(b)).map(date => { 
                        
                            const r = reservationGroups[date].filter(r => (r.locationId == selectedLocation || !selectedLocation) && (r.zoneId === selectedZone || !selectedZone || r.zoneId == null));
                            if (r.length === 0) return false;
 
                            return <div className='pt-5'>
                                <div className='text-lg font-bold  text-black w-full px-3'>{date}</div>
                                <div className='gap-3 grid 2xl:grid-cols-8 xl:grid-cols-8 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2 grid-spa'>
                                    
                                    {
                                        r.map(reservation => {
                                            return <>
                                                {renderReservation(reservation)}
                                            </>
                                        })
                                    }
                                    </div>
                                </div>
                    })
                }
            </div>
        </Layout>
    );
}