import React, { useEffect, useState } from 'react';
import moment                         from 'moment';
import dayjs                          from 'dayjs';
var duration = require('dayjs/plugin/duration');
dayjs.extend(duration);
const RED = 15;
const YELLOW=10; 
const GREEN=5;

const Timer = (props) => {
    
    //const [HH, setHH] = useState(null);
    //const [MM, setMM] = useState(null);
    //const [SS, setSS] = useState(null);
    
    const [diff, setDiff] = useState(dayjs.duration(0));
    const start = dayjs(props.start);

    useEffect(() => {
        
       /* let now = dayjs();
        let start = dayjs(props.start);
        
        //if (now < start) now = start;
        let d = dayjs.duration(now.diff(start));
        console.log(d);
        setDiff(d);

        let h  = now.diff(start, 'hours');
        let m  = now.diff(start, 'minutes') - (60 * h);
        //let s  = now.diff(start, 'seconds') - (60 * 60 * h) - (60 * m);
        
        const cmp = (h * 60) + m;

        const classColors = getClassColor(cmp);
        //console.log(`ITmer: ${props.id} ${cmp} ${classColors}`);
        if (props.alert && props.id)
            props.alert(props.id, classColors);
        
        let hh = ('0' + h).slice(-2);
        let mm = ('0' + m).slice(-2);
        let ss = ('0' + s).slice(-2);
        */
        
        //if (h > 24) return;

    }, []);

    
    setTimeout(() => {
        //console.log(diff);

        let d = dayjs.duration(dayjs().diff(start));
        if (d.seconds() < 0) d = dayjs.duration(0);

        const classColors = getClassColor(d.minutes());
        //console.log(classColors);
        if (props.alert && props.id)
            props.alert(props.id, classColors);

        setDiff(d);
        //setHH(hh);
        //setMM(mm);
        //setSS(ss);
        
    }, 1000);
    
    return !props.hide && diff && <div>{ `${diff.format('HH:mm:ss')}` }</div>;
    
}


export const getClassColor = (minutes) => {
    //console.log(minutes)
    if (minutes < YELLOW)
        return 'bg-green-500 text-white';
    else if (minutes >= RED)
        return 'bg-red text-white';
    else if (minutes >= YELLOW)
       return 'bg-yellow-400 text-white';
};

export default Timer;