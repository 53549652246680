import App from './App';
import { createRoot } from 'react-dom/client';
import './index.css';
import config from './auth/firebaseConfig';
import { FirebaseAppProvider } from 'reactfire';
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<FirebaseAppProvider firebaseConfig={config} suspense={true}><App /></FirebaseAppProvider>);

