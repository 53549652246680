import React from "react";

//import 'antd/dist/antd.css'; 
import 'antd/dist/reset.css';
//import 'antd/dist/antd.compact.css';

import { useSigninCheck , SuspenseWithPerf, useFirebaseApp, AuthProvider, FirestoreProvider, StorageProvider } from 'reactfire';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import SignInWithPassword from "./auth/SignIn/SignInWithPassword";
import MenuLeftSide from './components/Navigation/MenuLeftSide';
import Dashboard from './components/Dashboard/DashboardIndex';
import MenuIndex from './components/Menu/MenuIndex';
import ProductIndex from './components/Products/ProductIndex';
import SettingIndex from './components/Settings/SettingIndex';
import SettingsStripeReturn from './components/Settings/SettingsStripeReturn';
import UserIndex from './components/Users/UserIndex';
//import OrderIndex from './components/Order/OrderIndex';
import OrderCards from './components/Order/OrderCards';
import OrderItems from './components/Order/OrderItems';
import TableIndex from "./components/QrCode/TableIndex";
import IngridientIndex from "./components/Inventory/IngridientIndex";
import SupplyIndex from "./components/Inventory/SupplyIndex";
import CompanyProvider from './hooks/CompanyProvider';
import UserProvider from './hooks/UserProvider';
import ProductProvider from './hooks/ProductProvider';
import logo from './assets/logo2.png';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import PropertyIndex from "./components/Property/PropertyIndex";

import { Layout, Skeleton, Menu } from 'antd';
import OrderTable from "./components/Order/OrderTable";
import PosSell from "./components/Pos/PosSell";
import PosSettlement from "./components/Pos/PosSettlement";
import { getAuth } from 'firebase/auth'; 
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import DashboardAdmin from "./components/Dashboard/DashboardAdmin";
import ReservationIndex from "./components/Reservation/ReservationIndex";
import WaiterIndex from "./components/Waiter/WaiterIndex";

const { Content, Sider } = Layout;

export const AuthWrapper = ({ children, fallback }) => {
  const { status, data: signInCheckResult } = useSigninCheck();

  if (!children) {
    throw new Error('Children must be provided');
  }
  if (status === 'loading') {
    return  <div className="flex h-screen bg-white flex-col">
    <div className="m-auto w-64">
      <div className="hero container max-w-screen-lg mx-auto pb-10 flex">
        <img className="h-40 rounded-full mx-auto shadow-lg" src={logo}/>
      </div>
      </div>
      </div>;
  } else if (signInCheckResult.signedIn === true) {
    return children;
  }

  return fallback;
};

function App(){

  const handle = useFullScreenHandle();
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const firestore = getFirestore(app);
  const storage = getStorage(app);
  
  return <AuthProvider sdk={auth}>
         <AuthWrapper fallback={<SignInWithPassword />}>
     <FirestoreProvider sdk={firestore}>
     <StorageProvider sdk={storage}>
     <SuspenseWithPerf  fallback={
        <Layout style={{ height: "100vh", overflow: "auto" }}>
          <Sider
            theme="dark"
            breakpoint="lg"
            collapsedWidth="0"
            >  
            <img src={logo} className="logo"/>
            <Menu theme="dark" mode="inline">
              <Skeleton active/>
              </Menu>
            </Sider>
            
              <Content className="site-layout" style={{ padding: '0 50px', marginTop: 5 }}>
                <Skeleton active/>
              </Content>
            
          </Layout>
      }>
     
        <UserProvider>
        <ProductProvider>
        <CompanyProvider>
          <Router>
            <Layout style={{ height: "100vh", overflow: "hidden" }} >
              <MenuLeftSide FullScreenHandle={handle}/>
              <FullScreen handle={handle}>
              <Routes>
                <Route exact path="/" element={ <Dashboard/>} />
                <Route path="/admin" element={ <DashboardAdmin/>} />
                <Route path="/pos" element={<PosSell/>}/>
                <Route path="/settlements" element={<PosSettlement/>} />
                <Route path="/menu" element={<MenuIndex/>} />
                <Route path="/tables" element={<TableIndex/>} />
                <Route path="/waitress" element={<WaiterIndex/>} />
                <Route path="/ingridients" element={<IngridientIndex/>} />
                <Route path="/supply" element={<SupplyIndex/>} />
                <Route path="/products" element={ <ProductIndex/>} />
                <Route path="/types" element={<PropertyIndex />} />
                <Route path="/orders/cards" element={<OrderCards/>} />
                <Route path="/orders/items" element={<OrderItems/>} />
                <Route path="/orders/table" element={ <OrderTable/>} />
                <Route path="/reservations" element={<ReservationIndex/>} />
                <Route path="/users" element={<UserIndex/ >} />
                <Route path="/settings" element={<SettingIndex/>} />
                <Route path="/return" element={<SettingsStripeReturn/>} />
              </Routes>
              </FullScreen>
            </Layout>
            
          </Router>
        </CompanyProvider>
        </ProductProvider>
        </UserProvider>
        
     </SuspenseWithPerf>  
     </StorageProvider>   
     </FirestoreProvider>
     </AuthWrapper>;
    </AuthProvider>
   

}


export default App;

