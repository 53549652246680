import { API_BASE_ENDPOINT, API_BASE_HEADER, parseLocation, parse } from '../http/apiConfig';


class LocationAPI {
  
    getListForCompany = async(companyId) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/location/company/${companyId}/list`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return parse(data, [], parseLocation);
        }catch(e){
            console.error(e);
            return [];
        }
    }

    create = async(companyId, location) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/location/${companyId}`,
                await API_BASE_HEADER('POST', location)
            );
            let data = await response.json();
            return parse(data, {}, parseLocation);
        }catch(e){
            console.error(e);
            return null;
        } 
    }

    update = async(id, location) => {
        if (!id)
            return;
            
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/location/${id}`,
                await API_BASE_HEADER('PUT', location)
            );
            let data = await response.json();
            return parse(data, {}, parseLocation);
        }catch(e){
            console.error(e);
            return null;
        }   
    }

    get = async(id) => {
        if (!id)
            return;

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/location/${id}`,
                await API_BASE_HEADER('GET')
            );
            let data = await response.json();
            return parse(data, {}, parseLocation);
        }catch(e){
            console.error(e);
            return null;
        }
    }

    toggleDelete = async(id) => {
        if (!id)
            return;

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/location/${id}/delete`,
                await API_BASE_HEADER('DELETE')
            );
          
            let data = await response.json();
            return parse(data, {}, parseLocation);
        }catch(e){
            console.error(e);
            return null;
        }   
    }

    changeThemeColorLive = async(id, color) => {
        if (!id || !color)
            return;
        try {
            await fetch(
                `${API_BASE_ENDPOINT}/api/location/${id}/livecolorchange`,
                await API_BASE_HEADER('PUT', { hex: color})
            );
        }catch(e){
            console.error(e);
        } 
    }

};



const locationAPI = new LocationAPI();
export default locationAPI;