
import React, { useState, useEffect } from 'react'
import '@firebase/auth';
import '@firebase/storage';
import {
  Modal,
  Form,
  Input,
  Switch,
  Divider
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
//import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
//import menuAPI from '../../http/apiMenu';
import zoneAPI from '../../http/apiZone';

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

export default function ZoneEdit(props){

    const [form] = Form.useForm(); 
    const [confirmLoading, setConfirmLoading] = useState(false);
    
    useEffect(()=> {
        form.resetFields(); 
       
        if (props.menuZoneId && props.isModalOpen === true){
            console.log(props.menuZone);
            form.setFieldsValue({
                name: props.menuZone.name,
                displayOnReservations: props.MenuZone?.data?.displayOnReservations || false
            });
        }else{
            
        }
    },[props.isModalOpen]);

    const saveMenuZones = async(values) => {
        setConfirmLoading(true);
        
        let apiObj = {
            name: values.name,
            data: { 
                displayOnReservations: values.displayOnReservations
            }
        };

        let result;
        if (!props.menuZoneId){
            result = await zoneAPI.create(props.menuId, apiObj);
        }else{
            result = await zoneAPI.update(props.menuZoneId, apiObj);
        }
        
        setConfirmLoading(false);
        if (result !== false) {
            setConfirmLoading(false);
            form.resetFields(); 
            props.setModalOpen(false);
            props.onSuccess(result);
        }
    }

    const handleOk = () => {
        form
          .validateFields()
          .then(values => {
            saveMenuZones(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      };

    const handleCancel = () => {
        form.resetFields(); 
        props.setModalOpen(false);
    };

    return (
        <Modal
            destroyOnClose={true}
            title={!props.menuZoneId ? "Create" : "Update"}
            open={props.isModalOpen}
            forceRender
            confirmLoading={confirmLoading}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Form
                key={props.id}
                form={form}
                {...formItemLayout}
                name="zone"
                id="zoneForm"
                scrollToFirstError
            >
            <Form.Item
                name="name"
                label={`Name`}
                rules={[
                {
                    required: true,
                    message: 'Please input name',
                },
                ]}
            >
                <Input/>
            </Form.Item>
            
                <Divider plain>
                    Settings
                </Divider>
                <p>
                <Form.Item
                    name="displayOnReservations"
                    valuePropName="checked"
                    initialValue={false}
                    label={`Allow reservations`}
                    rules={[
                    {
                        required: true,
                        message: 'Please choose what ever you want to allow zone for reservations',
                    },
                    ]}
                >
                    <Switch
                        size="small"
                        defaultChecked={false}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Form.Item>
                </p>
                
            </Form>

        </Modal>
    )
}