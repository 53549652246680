import React, { createContext, useContext, useReducer, useEffect, useState  } from "react";
import productAPI from '../http/apiProduct';
import { useCompany } from "./CompanyProvider";

const ProductContext = createContext();
const initialState = { productList: [], productIntegrationList: [], productTypes: []};

const filterProductType = (products) => [...new Set(products.map(p => p.properties.filter(p => p.type === 'TYPE')).flat().map(p => p.data.name))].map(p => {return { text: p, value: p}});

const reducer = (state, action) => {
    switch(action.type) {
      case "delete":{
        return {
            productList: [ ...state.productList.filter(p => p.id !== action.product.id)]
        }
      }
      case "update":{
        const list = [ ...state.productList.map(p => {
          if ( p.id === action.product.id){return { ...action.product }}
          return p;
        })];

        return {
          productTypes: filterProductType(list),
          productList: list,
          productIntegrationList: state.productIntegrationList
        }
      }
    case "add":{
        const list = [ action.product, ...state.productList.filter(p => p.id !== action.product.id)]
        return {
            productTypes: filterProductType(list),
            productList: list,
            productIntegrationList: state.productIntegrationList
        }
      }
      case "set_list":{
        return {
            productTypes: filterProductType(action.productList),
            productList: action.productList,
            productIntegrationList: state.productIntegrationList
        }
      }

      case "set_integration_list":{
        
        return {
            productTypes: state.productTypes,
            productList: state.productList,
            productIntegrationList: action.productList
        }
      }

      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  }

const ProductPovider = ({ children }) => {
    
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <ProductContext.Provider value={{state, dispatch}}>
            {children}
        </ProductContext.Provider>
      );
};

const useProduct  = () => {
    const {state, dispatch} = useContext(ProductContext);
    const [loading, setLoading] = useState(true)
    const company = useCompany();

    useEffect(()=>{
      if (!company.companyId) return;
      fetchData();
      fetchIntegrationData();
    },[company.companyId]);

    async function fetchData(companyId) {
        
        if (state.productList.length > 0 && companyId === company.companyId) {
          setLoading(false);
          return;
        }
        setLoading(true);
       
        if (!companyId)
          return;
        let response = await productAPI.getListForCompany(companyId);
        if (!response) return;
        dispatch({type: "set_list", productList: response});
        setLoading(false);
    }

    async function fetchIntegrationData() {
      //console.log('FetchIntegrationProducts', company.isPoster, state.productIntegrationList.length);
      if ((!company.isPoster && !company.isCompucash) || state.productIntegrationList.length > 0) return;
      
      setLoading(true);
      let response = await productAPI.getIntegrationListForCompany(company.companyId);
      if (!response) return;
      dispatch({type: "set_integration_list", productList: response});
      setLoading(false);
    }

    return {
        loading: loading,
        productList: state.productList,
        productIntegrationList: state.productIntegrationList,
        productTypes: state.productTypes,
        reload: (companyId) => { fetchData(companyId); fetchIntegrationData(); },
        setProductList: (newProductList) => dispatch({type: "set_list", productList: newProductList}),
        appendProduct: (newProduct) => dispatch({type: "add", product: newProduct}),
        updateProduct: (updateProduct) => dispatch({type: "update", product: updateProduct}),
        deleteProduct: (deleteProduct) => dispatch({type: "delete", product: deleteProduct})
      }
  }

export default ProductPovider;
export { useProduct };