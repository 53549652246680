import React, { useState, useEffect } from 'react'
import { useCompany } from '../../hooks/CompanyProvider';
import '@firebase/auth';
import '@firebase/storage';
import dayjs from 'dayjs';
import {
  Modal,
  Form,
  Input,
  Select,
  TimePicker,
  Button,
  message 
} from 'antd';

import categoryAPI from '../../http/apiCategory';
import translateAPI from '../../http/apiTranslate';

const { RangePicker } = TimePicker;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

const NAME_PROPERTY = "name";

export default function MenuConfigurationGroup(props){
    const [form] = Form.useForm(); 
    const company = useCompany();
    const [lang, setLang] = useState();
    const [menuId, setMenuId] = useState(props.menuId);
    const [group, setGroup] = useState()
    const [confirmLoading, setConfirmLoading] = useState(false);

    useEffect(() => { 
      if (props.group && props.group.id && props.isModalOpen === true) {
        setGroup(props.group);
        setFormValues(props.group); 
      }else{
        setGroup({})
      }
      
      setLang(company.language)
    }, [props.isModalOpen]);

    useEffect(() => { setFormValues(group) },[lang]);
    

    function setFormValues(group){
        form.setFieldsValue({
          name: getNameFromGroup(group),
          hours: getGroupHours(group),
          language: lang,
        });
    }

    
    const onAutoTranslateClick = async() => {
      const languages = company.languages.filter(l => l.key !== lang).map(l => l.key);

      const name = group.data[NAME_PROPERTY] ? group.data[NAME_PROPERTY][lang] : null;
      
      for(let i=0; i < languages.length; i++) {
        const langKey = languages[i];
        
        const translateName = name ? await translateAPI.translate(lang, langKey, name) : null;
        
        if (translateName)
          setLocalizedProperty(NAME_PROPERTY, langKey, translateName);
      }
      
      message.success('translation done');
    }

    const selectedLanguage = () => {
      if (lang && lang.length > 0)
        return lang.toUpperCase();

      return '';
    }

    function getNameFromGroup(group) {
        return group && group.data && group.data.name && group.data.name[lang] ? group.data.name[lang] : '';
      }
    
    function getGroupHours(group){
      return group && group.data && group.data.hours && group.data.hours.length === 2 ?  [ dayjs(group.data.hours[0], 'HH:mm'), dayjs(group.data.hours[1], 'HH:mm') ] : [];
    }

    function setLocalizedProperty(key, lang, value){
        let p = group;
        if (!p)
          p = {};

        if (!p['data']){
          p['data'] = {};
        }
  
        if (!p['data'][key]){
          p['data'][key] = {};
        }
        
        if (!p['data'][key][lang]){
          p['data'][key][lang] = '';
        }
  
        p['data'][key][lang] = value;  
        setGroup({
          ...p
        });
      }


    async function SaveGroup(values){ 
        setConfirmLoading(true);

        const apiObj = {
            ...group,
            name: '',
            companyId: company.companyId,
            data: {
                ...group.data,
                hours: values.hours && values.hours.length === 2 ? [values.hours[0].format('HH:mm'), values.hours[1].format('HH:mm')] : [],
            },
            deleted: false
        };
        let result;
        
        if (!group.id){
            result = await categoryAPI.create(menuId, apiObj);
        }else {
            result = await categoryAPI.update(apiObj.id, apiObj);
        }
        setConfirmLoading(false);
        if (!result || result === null || result === 'undefined')
            return props.onFail();

         
        form.resetFields();
        props.onSuccess(result);
    }

    const handleOk = () => {
      form
        .validateFields()
        .then(values => {
          SaveGroup(values);
        })
        .catch(info => {
          console.log('Validate Failed:', info);
        });
    };

  const handleCancel = () => {
      form.resetFields(); 
      props.setModalOpen(false);
  };

    return (
        <Modal
        destroyOnClose={true}
        title={props.group && props.group.id ? "Create" : "Update"}
        open={props.isModalOpen}
        confirmLoading={confirmLoading}
        onOk={handleOk}
        onCancel={handleCancel}  
      >
        <Form
          {...formItemLayout}
          form={form}
          name="group"
          id="groupForm"
          scrollToFirstError
        >
          <Form.Item 
            name="language"
            label="Language"
            rules={[
              {
                required: true,
                message: 'Please select language',
              },
            ]}
          > 
            <Select onChange={(value) => setLang(value)}>
                { company.languages && company.languages.length > 0 
                  ? company.languages.map(o => <Select.Option value={o.key} key={o.key}>{o.name}</Select.Option>)
                  :  ''
                }  
              </Select> 
          </Form.Item>
          <Form.Item
            name="name"
            label={`Name ${selectedLanguage()}`}
            rules={[
              {
                required: true,
                message: 'Please input name',
              },
            ]}
          >
            <Input onChange={(e) => setLocalizedProperty('name', lang, e.target.value)}/>
        </Form.Item>
        <Form.Item 
            label={`Auto Translate`}>
            <Button onClick={(e) => onAutoTranslateClick()}>Click to translate</Button>
        </Form.Item>
        <Form.Item 
          name="hours"
          label="Menu hours"
        >
          <RangePicker secondStep={30} minuteStep={15}/>
        </Form.Item>
        </Form>

        </Modal>
    )
}