import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { Layout, Menu, Select } from 'antd';
import { TeamOutlined, ApartmentOutlined, UserOutlined, CalendarOutlined, LogoutOutlined, SettingFilled, AppstoreOutlined, BankOutlined, PrinterOutlined, OrderedListOutlined, HistoryOutlined, ExpandOutlined, BarChartOutlined, LineChartOutlined, BarcodeOutlined, FilePptOutlined, MenuOutlined, GroupOutlined } from '@ant-design/icons';
import { useAuth } from 'reactfire';
import { useEffect } from 'react';
import userAPI from '../../http/apiUser';
import { useMulleUser } from '../../hooks/UserProvider';
import { useCompany } from '../../hooks/CompanyProvider';
import { ReactComponent as LogoIcon } from '../../assets/mulle_logo_color.svg';
import { ReactComponent as LogoSmileIcon } from '../../assets/mulle_logo_smile_color.svg';
import { useLocalStorage } from '../../hooks/LocalStorageProvider';
import { useProduct } from '../../hooks/ProductProvider';
import MenuCompanyCreate from './MenuCompanyCreate';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function MenuLeftSide({FullScreenHandle}) {

  const company = useCompany();
  const products = useProduct();
  const user = useMulleUser();

  const [compnayModalOpen, setCompanyModalOpen] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selected, setSelected] = useState(1);
  const auth = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useLocalStorage("companyId", company.companyId || false);
  const [openKeys, setOpenKeys] = useState([])

  const pages = user.pages;

  useEffect(() => {
    async function fetchData() {
      let response = await userAPI.me();
      if (!response) return;

      user.setUser(response);
      user.setPages(response.pages);

      const { companies } = response;
      company.setCompanyList(companies);
      setCompanies(companies);

      let companyId = '';
      if (companies && companies.length > 0) {
        if (selectedCompanyId && companies.find(c => c.id === selectedCompanyId)){
          companyId = selectedCompanyId;
        }else{
          companyId = companies[companies.length - 1].id;
        }
        company.setCompanyId(companyId);
        
        
      }

      products.reload(companyId);
    }

		fetchData();
	},[]);

  useEffect(() => {}, [user.pages]);
  
  function getSelectedCompanyName() {
    if (companies.length === 0)
      return;

    const c = companies.find(c => c.id === company.companyId);
    return c && c.name;
  }

  function setSelectedCompany(companyId) {
    company.setCompanyId(companyId);
    setSelectedCompanyId(companyId);
  }

  const closeCompanyList = () => {
    const openedKeyIndex = openKeys.indexOf('6')
        if (openedKeyIndex !== -1) {
            const _openKeys = [...openKeys]
            _openKeys.splice(openedKeyIndex, 1)
            setOpenKeys(_openKeys)
        }
  }

  const onOpenChange = _openKeys => {
    setOpenKeys([..._openKeys])
}

  return (
    <Sider
      breakpoint="xxl"
      collapsedWidth="80"
      onBreakpoint={broken => {
      }}
      onCollapse={(collapsed, type) => {
        setCollapsed(collapsed);
      }}
      style={{ height: '100vh',  position:'sticky', top:0, left:0, position:'sticky', zIndex: 100 }}
    >
        
        <div className="flex justify-center" onClick={FullScreenHandle.enter}>
          <div className={`${collapsed ? 'w-6 h-6' : 'w-20 h-10' } pt-5`}>
          { 
            collapsed ? <LogoSmileIcon /> : <LogoIcon/>
          }
          </div>
        </div>
       
        {
          pages && pages.length > 0

          ? <div className="pt-3">
            <div>
            <Menu 
              theme="dark" 
              mode="inline" 
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              defaultSelectedKeys={[selected]}>
           
              {
                companies.length <= 1 
                ? <Menu.Item key="6" style={{color:'white', fontWeight: "bold"}}>{getSelectedCompanyName()}</Menu.Item>
                : <SubMenu key="6" style={{color:'white', fontWeight: "bold"}}  title={getSelectedCompanyName()}>
                  <div className='flex flex-col'>
                    <div className='h-12'>
                      {
                        companies.length > 1 && <div className=''><Select
                          
                          backgroundColor="black"
                          showSearch
                          style={{ width: '100%', background: 'black', color: 'white' }}
                          
                          onSelect={(value) => { setSelectedCompany(value); closeCompanyList(); }}
                          placeholder="Search to Select"
                          optionFilterProp="children"
                          filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                          }
                          options={[...companies.map(c => ({id: c.id, value: c.id, label: c.name, name: c.name}))]}
                        />
                        </div>
                      }
                    </div>
                    <div className='flex justify-center h-6 cursor-pointer'>
                        <div onClick={() => setCompanyModalOpen(true) } className='text-white'>New bussness</div>
                          <MenuCompanyCreate
                            isModalOpen={compnayModalOpen}
                            onCancel={() => setCompanyModalOpen(false)}
                            onCreated={() => setCompanyModalOpen(false)}
                          />
                      </div>
                  </div>
                  </SubMenu>
              }
                <div className=""></div>
                {
                  pages.includes("Dashboard") && <Menu.Item key="1" onClick={() => setSelected(1)} icon={<LineChartOutlined style={{ color: 'white', fontSize: "20px"}}/>}><Link to='/'>Dashboard</Link></Menu.Item>
                }
                {
                  pages.includes("AdminDashboard") && <Menu.Item key="20" onClick={() => setSelected(20)} icon={<BarChartOutlined style={{ color: 'white', fontSize: "20px"}}/>}><Link to='/admin'>Admin Dashboard</Link></Menu.Item>
                }
                {
                   pages.includes("Pos")
                   ? <SubMenu key="g5" title={`POS`}>
                      <Menu.Item key="12" onClick={() => setSelected(12)} icon={<BarcodeOutlined style={{ color: 'white', fontSize: "20px" }}/>}><Link to='/pos'>Sell</Link></Menu.Item>
                      <Menu.Item key="13" onClick={() => setSelected(13)} icon={<BankOutlined style={{ color: 'white', fontSize: "20px"}}/>}><Link to='/settlements'>Settlements</Link></Menu.Item>
                      <Menu.Item key="14" onClick={() => setSelected(14)} icon={<PrinterOutlined style={{ color: 'white', fontSize: "20px"}}/>}><Link to='/settlements'>Printer</Link></Menu.Item>
                    </SubMenu>
                    : ''
                }
                 
               {
                 pages.includes("Orders")
                 ?<SubMenu key="g4" title={`Orders`}>
                    <Menu.Item key="4" onClick={() => setSelected(4)} icon={<AppstoreOutlined style={{  color: 'white', fontSize: "20px"}}/>}><Link to='/orders/cards'>Kitchen Display</Link></Menu.Item>
                    <Menu.Item key="10" onClick={() => setSelected(10)} icon={<OrderedListOutlined style={{  color: 'white', fontSize: "20px"}}/>}><Link to='/orders/items'>Serving Display</Link></Menu.Item>
                    <Menu.Item key="11" onClick={() => setSelected(11)} icon={<HistoryOutlined style={{  color: 'white', fontSize: "20px"}}/>}><Link to='/orders/table'>Invoices</Link></Menu.Item>
                 </SubMenu>
                 : ''
               }

              {
                 pages.includes("Reservations")
                 ?<SubMenu key="g6" title={`Reservations`}>
                    <Menu.Item key="17" onClick={() => setSelected(4)} icon={<CalendarOutlined style={{  color: 'white', fontSize: "20px"}}/>}><Link to='/reservations'>Reservations</Link></Menu.Item>
                    
                 </SubMenu>
                 : ''
               }
                
                { (pages.includes("Menu") || pages.includes("Tables") ) && <SubMenu key="g1" title={`Configuration`}>
                {
                  pages.includes("Menu")
                  ? <Menu.Item key="2" onClick={() => setSelected(2)} icon={<MenuOutlined style={{ color: 'white', fontSize: "20px"}}/>}><Link to='/menu'>Configuraiton</Link></Menu.Item>
                  : ''
                }
                {
                  pages.includes("Tables")
                  ? <Menu.Item key="3" onClick={() => setSelected(3)} icon={<ExpandOutlined style={{ color: 'white', fontSize: "20px"}}/>}><Link to='/tables'>Tables/QR</Link></Menu.Item>
                  : ''
                }
                 {
                  pages.includes("Tables")
                  ? <Menu.Item key="8" onClick={() => setSelected(3)} icon={<TeamOutlined style={{ color: 'white', fontSize: "20px"}}/>}><Link to='/waitress'>Waitress</Link></Menu.Item>
                  : ''
                }
                
                </SubMenu>
                }
                
                { (pages.includes("Ingridients") || pages.includes("Products")) && <SubMenu key="g2" title={`Inventory`}> 
                        { pages.includes("Products") && <Menu.Item key="5" onClick={() => setSelected(5)} icon={<FilePptOutlined style={{  color: 'white', fontSize: "20px"}}/>}><Link to='/products'>Menu Products</Link></Menu.Item>}
                        { pages.includes("Products") && <Menu.Item key="7" onClick={() => setSelected(6)} icon={<GroupOutlined style={{  color: 'white', fontSize: "20px"}}/>}><Link to='/types'>Types</Link></Menu.Item>}
                        { pages.includes("Ingridients") && <Menu.Item key="15" onClick={() => setSelected(15)} icon={<ApartmentOutlined style={{  color: 'white', fontSize: "20px"}}/>}><Link to='/ingridients'>Ingredients</Link></Menu.Item> }
                </SubMenu>
                }
                 
                {  
                  pages.includes("Settings") ||  pages.includes("Users") 
                    ?
                    <SubMenu key="g3" title={`Settings`}>
                      {
                        pages.includes("Settings")
                        ? <Menu.Item key="settings" icon={<SettingFilled style={{ color: 'white', fontSize: "20px"}}/>} ><Link to="/settings">General</Link></Menu.Item>
                        : ''
                      }
                      {
                        pages.includes("Users")
                        ? <Menu.Item key="users" icon={<UserOutlined style={{ color: 'white', fontSize: "20px"}}/>}><Link to="/users">Users</Link></Menu.Item>
                        : ''
                      }
                    </SubMenu>  
                    : ''
                }
               
                <Menu.Item key="logout"  icon={<LogoutOutlined style={{ color: '#F15B55'}}/>} style={{position:'absolute', bottom: "0px"}}><Link to={'#'} onClick={()=> auth.signOut()}>Logout</Link></Menu.Item>
                
            </Menu>
            </div>
            </div>
          :  <Menu theme="dark" mode="inline" defaultSelectedKeys={[selected]}>
              <Menu.Item key="logout"  icon={<LogoutOutlined style={{ color: '#F15B55'}}/>} style={{position:'absolute', bottom: "0px"}}><Link to={'#'} onClick={()=> auth.signOut()}>Logout</Link></Menu.Item>
            </Menu>
        }

    </Sider>

    
       
    
  );
};