import React, { useRef, useState, useEffect } from 'react';
import unitAPI from '../../http/apiUnit';
import { v4 as uuidv4 } from 'uuid';
import ingridientAPI from '../../http/apiIngridient';
import { useCompany } from '../../hooks/CompanyProvider';
import {
    Form,
    Input,
    Select,
    InputNumber,
    message,
    Divider,
    Button,
    Space
  } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {
  INGRIDIENT_CUSTOM,
  INGRIDIENT_STOCK,
  EMPTY_GUID,
  formatCurrencty,
} from '../Common';

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

const ProductIngridient = ({ id }) => {
    
  const company = useCompany();

  const productId = id;
  const [form] = Form.useForm();
    
  const [units, setUnits] = useState([]);
  const [newIngridientName, setNewIngridientName] = useState('');
  const [ingridients, setIngridients] = useState([]);
  const [productIngridients, setProductIngridients] = useState([]);

  const inputRef = useRef(null);
 
    useEffect(() => {
      getUnits();
    },[]);

    useEffect(() => {
      getIngridients();
      getIngridientsOfProduct();
    }, [company.companyId])

    useEffect(() => {
      getIngridients();
    }, [productId]);

    const getUnits = async() => {
      let apiUnits = await unitAPI.getList();
      setUnits(apiUnits);
    }

    const getIngridients = async() => {
      if (!company.companyId) return;
      let ingridients = await ingridientAPI.getList(company.companyId);
      setIngridients(ingridients);
    }

    const getIngridientsOfProduct = async() => {
      if (!productId) return;
      let productIngridientsExisting = await ingridientAPI.getListForProduct(productId);
      setFormProductIngridients(productIngridientsExisting.ingridients);
      setProductIngridients(productIngridientsExisting);
    }

    const totalPriceIngridients = () => {
      return formatCurrencty(productIngridients.selfPrice, company.currency);
    }

    const totalWeightIngridients = () => {
      return `${productIngridients.selfWeight}`;
    }

    const setFormProductIngridients = (ingridients) => {
      let fields = form.getFieldsValue();
      fields.productIngridiets = (ingridients || []).map(x => {
        return {
          id: x.id,
          price: formatCurrencty(x.price, company.currency),
          ingridientId: x.ingridient.id,
          amount: x.amount,
          unit: x.unit ? JSON.stringify(x.unit) : '',
          amount_net: x.amountNet,
          unit_net: JSON.stringify(x.unitNet)
        };
      });
    
      const { productIngridiets } = fields;
      form.setFieldsValue({ productIngridiets })  
    }

    const submitFromParent = () => form.submit();

    const onSubmit = async(data) => {
        const fields = form.getFieldsValue();
        const { productIngridiets } = fields;
        saveIngridients(productIngridiets)
    };

    const saveIngridients = async(productIngridiets) => {
      let objList = (productIngridiets || []).filter(x => ingridients.find(i => i.id === x.ingridientId)).map(x => {
  
        let foundIngridient = ingridients.find(i => i.id === x.ingridientId);
        
        return {
          name: foundIngridient.name,
          ingridientId: x.ingridientId,
          type: foundIngridient.type,
          amount: x.amount,
          unit: x.unit ? JSON.parse(x.unit): null,
          amountNet: x.amount_net,
          unitNet: JSON.parse(x.unit_net)
        }
      });

      await ingridientAPI.addToProduct(company.companyId, productId, objList);
      getIngridientsOfProduct();

         
      message.success('Ingredients saved');
    }

    const addNewIngridient = async() => {
      if (newIngridientName.length === 0)
        return;

      setIngridients([{ id: uuidv4(), name: newIngridientName, type: INGRIDIENT_STOCK }, ...ingridients]);
      setNewIngridientName('');
    }

    return <>
        
        <Form
            key={`ingridients_${productId}`}
            {...formItemLayout}
            form={form}
            name="ingridients"
            id="ingridientsForm"
            scrollToFirstError
            onFinish={(e) => onSubmit(e)}
          >
            {/* This is the Dynamic room Adder */}
            <Form.List name="productIngridiets">

                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map((field) => (
                          <div className='flex gap-1'>
                            <Form.Item name={[field.key, "id"]} hidden={true} />
                            <Form.Item name={[field.name, "price"]}><Input bordered={false} style={{'width': 60}}/></Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, "ingridientId"]}
                              rules={[
                                { required: true, message: "Missing ingredient" }
                              ]}
                            >
                              <Select
                                    showSearch
                                    style={{ width: 200 }}
                                    placeholder="Select ingredient or product"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    dropdownMatchSelectWidth={false}
                                    dropdownRender={menu => (
                                      <>
                                        <Space style={{ padding: '0 8px 4px' }}>
                                          <Input
                                            placeholder="Name"
                                            ref={inputRef}
                                            value={newIngridientName}
                                            onChange={(e) => setNewIngridientName(e.target.value)}
                                          />
                                          <Button type="text" icon={<PlusOutlined />} onClick={() => addNewIngridient()}>
                                            Create Ingredient
                                          </Button>
                                        </Space>
                                        <Divider style={{ margin: '8px 0' }} />
                                        {menu}
                                      </>
                                    )}
                                    options={
                                      ingridients.map(i => { 
                                        return {
                                          value: i.id, label: `${i.name}` 
                                        }
                                      })
                                    }
                                  />
                                
                            </Form.Item>
                            {/* gross */}
                            <Form.Item 
                                name={[field.name, "amount"]}
                                fieldKey={[field.fieldKey, "amount"]}
                                rules={[
                                { required: true, message: "value" }
                              ]}
                            >
                              <InputNumber 
                                min={0}
                                style={{'width': 70}}
                                placeholder={`Gross`}
                              />
                            </Form.Item>
                            <Form.Item 
                                name={[field.name, "unit"]}
                                rules={[
                                { required: true, message: "Missing unit" }
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder={'Unit gross'}
                                bordered={false}
                                dropdownMatchSelectWidth={false}
                                style={{ width: 120 }}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                options={
                                  Object.keys(units).map(type => 
                                    { return {
                                      label: type,
                                      options: units[type].map(u => { return { value: JSON.stringify(u), label: `${u.abbreviation} (${u.name})`} }),
                                    }
                                  })
                                }
                              />
                            </Form.Item>
                            <Form.Item 
                                name={[field.name, "amount_net"]}
                                rules={[
                                { required: true, message: "value" }
                              ]}
                            >
                              <InputNumber 
                                min={0}
                                style={{'width': 70}}
                                placeholder={`Net`}
                              />
                            </Form.Item>
                            <Form.Item 
                                name={[field.name, "unit_net"]}
                                rules={[
                                { required: true, message: "Missing unit" }
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder={'Unit Net'}
                                dropdownMatchSelectWidth={false}
                                style={{ width: 120 }}
                                bordered={false}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                options={
                                  Object.keys(units).filter(x => x !== 'Quantity').map(type => 
                                    { return {
                                      label: type,
                                      options: units[type].map(u => { return { value: JSON.stringify(u), label: `${u.abbreviation} (${u.name})`} }),
                                    }
                                  })
                                }
                              />
                            </Form.Item>

                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          </div>
                        ))}

                        <Button
                          type="link"
                          width={80}
                          onClick={() => {
                            add();
                          }}
                          
                        >
                          <PlusOutlined /> Add new
                        </Button>
                      </div>
                    );
                  }}
            </Form.List>
        </Form>
        <div className="flex justify-between gap-1 pt-8">
            <div >
              <div className='flex gap-2 '>
                <div className='text-md'>Price:</div> <div className='text-md font-bold'>{totalPriceIngridients()}</div>
              </div>
              <div className='flex gap-2 '>
                <div className='text-md'>Weight:</div> <div className='text-md font-bold'>{totalWeightIngridients()}</div>
              </div>
            </div>
            <Button type="primary" onClick={() => form.submit() }>
              Save Ingredients
            </Button>
        </div>
    </>
}

export default ProductIngridient;
