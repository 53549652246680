import React, { useState, useEffect } from 'react';
import orderAPI from '../../http/apiOrder';
import menuAPI from '../../http/apiMenu';
import productAPI from '../../http/apiProduct';
import settlementAPI from '../../http/apiSettlement';
import productPropertyAPI from '../../http/apiProductProperty';
import posAPI from '../../http/apiPos';
import { useCompany } from '../../hooks/CompanyProvider';
import moment from "moment";
import { 
    formatCurrencty, 
    DELIVERY_RESTAURANT, 
    PAYMENT_POS, 
    PAYMENT_CARD,
    PAYMENT_CASH,
    OPTION_SINGLE, 
    PAYMENT_PAID,
    getMultiLang, 
    getProductSmallImage, 
    OPTION_MULTIPLE, 
    toUpperCase,
    toLowerCase,
    INSERTED,
    CONFIRMED, 
    PROGRESS, 
    READY, 
    DELIVERED,
    CANCELED,
    PICKUP_SELF,
    DELIVERY_TAKEAWAY,
    COMPLETED,
    PRODUCT_TYPE,
    calculateTotal
} from '../Common';
import { LeftOutlined, PlusCircleOutlined  } from '@ant-design/icons';
import { Skeleton, message  } from 'antd';

import { useLocalStorage } from '../../hooks/LocalStorageProvider';
import { v4 as uuidv4 } from 'uuid';
//import { ReactComponent as BagIcon } from '../../assets/mulle_bag_icon.svg';
//import { ReactComponent as TableIcon } from '../../assets/mulle_table_icon.svg';
import PosSellDetails from './PosSellDetails';
import PosSellSettlementOpen from './PosSellSettlementOpen';
import PosSellSettlementClose from './PosSellSettlementClose';
import { useUser, useFirestore,  useFirestoreCollection } from 'reactfire';
import PosSellHeader from './PosSellHeader';
import PosSellVisual from './PosSellVisual';
import PosPayment from './PosPayment';
import { collection, query, where, orderBy, limit } from "firebase/firestore";

export default function PosSell() {

    const { status: userStatus, data: user } = useUser();
   
    const [askRemove, setAskRemove] = useState({});
    const company = useCompany();
    const companyId = company.companyId;
    //const product = useProduct();

    const [showVisual, setShowVisual] = useState(false);

    const [products, setProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [currentOrder, setCurrentOrder] = useLocalStorage(`currentOrder_${companyId}`, { products: [],});
    const [currentOrderId, setCurrentOrderId] = useState('');
    const [unsubmittedOrders, setUnsubmittedOrders] = useLocalStorage(`unsubmittedOrders_${companyId}`, {});
    const [currentTable, setCurrentTable] = useLocalStorage(`currentTable_${companyId}`, {
        table: {},
        showOrders: false
    })

    const [lastSettlement, setLastSettlement] = useState({});
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [qrCodes, setQrCodes] = useState([]);
    const [tableView, setTableView] = useState(true);
    const [paymentView, setPaymentView] = useState(false);
    const [changePaymentType, setChangePaymentType] = useState(false);
    const [packageView, setPackageView] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const [selectedProduct, setSelectedProduct] = useState({});
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('');
    //const [changeTableInProgress, setChangeTableInProgress] = useState(false);
    const [tableChange, setTableChange] = useState({mode:false, orderIds: []});

    const [loading, setLoading] = useState(true);
    const [closePos, setClosePos] = useState(false);
    const [types, setTypes] = useState([]);

    const firestore = useFirestore();
    const firestoreQuery = collection(firestore, 'orders');
    const { status, data: snapshot } = useFirestoreCollection(query(firestoreQuery
        ,where("Company.CompanyId", "==", companyId)
        ,where("CardPaymentInProgress", "==", false)
        ,where("Status", "in", [INSERTED, CONFIRMED, PROGRESS, READY, DELIVERED])
        ,orderBy('Created', 'desc')
        ,limit(100))
        , {
            idField: 'id'
        });
    
    useEffect(() => {
        if (!company.companyId) return;
        initialData();
    },[company.companyId]);

    if (userStatus === 'loading' || !user){
        return <div>Loading</div>
    }

    const initialData = async() => {
        setLoading(true);
        setClosePos(false);
        const productList = await productAPI.getListForCompanyPos(company.companyId, 0);
        setProducts(productList);
        let productsByGroup = productList.reduce((map, obj) => {
            let type = obj.properties.find(x => x.type === PRODUCT_TYPE);
            if (!type) return map;
            let name = type.data.name;
            if (!map[name]) map[name] = [];
            map[name].push(obj);
            return map;
        },{});

        setGroups(productsByGroup);
        setFilteredProducts([]);

        let menuList = await menuAPI.getListForCompany(company.companyId);
            if (qrCodes.length === 0){
            let qrCodes = menuList.map(m => m.menuLocations).flat().sort((a, b) => a.name.localeCompare(b.name, undefined, {
                numeric: true,
                sensitivity: 'base'
            }));
            setQrCodes(qrCodes);
        }
        setSelectedProduct({});

        const lastSettlement = await settlementAPI.getLast(company.companyId, 0);
        setLastSettlement(lastSettlement || {});

        let apiProperties = await productPropertyAPI.getListProperties(company.companyId);
        setTypes(apiProperties.filter(p => p.type === PRODUCT_TYPE).sort((a,b) => (a.data.order ? a.data.order : 99) - ((b.data.order ? b.data.order : 99))).reduce((map, obj) => {
            map[obj.id] = obj;
            return map;
        },{}));

        setLoading(false);
    }
    
    const orders = () => snapshot.docs.map(d => d.data());

    const showSelectedGroupProducts = (group) => {
        setFilteredProducts(groups ? groups[group] : []);
        setSelectedGroup(group);
    }

    const getOrdersByQr = (qr) => { 
        let unsubmitted = unsubmittedOrders;
        let needToUpdate = false;
        snapshot.docs.map(d => {
            let id = d.data().Id;
            if(unsubmitted[id]) { 
                needToUpdate = true;
                delete unsubmitted[id];
            }
        });

        if (needToUpdate)
            setUnsubmittedOrders(unsubmitted);
        
       return snapshot.docs.map(d => d.data()).filter(d => d.Delivery.DeliveryQr === qr)
                .concat(Object.values(unsubmitted).map(o => toUpperCase(o)).filter(d => d.Delivery.DeliveryQr === qr));
    }

    const getOrdersById = (id) => (snapshot.docs.map(d => toLowerCase(d.data())).concat(Object.values(unsubmittedOrders).map(o => o))).find(o => o.id === id);

    const getOrdersByTableId = (id) => (snapshot.docs.map(d => toLowerCase(d.data())).concat(Object.values(unsubmittedOrders).map(o => o))).filter(o => o.delivery.menuLocationId === id);

    const addProductCheckOptions = (product) => {
        const mandatoryOptions = product.options.filter(o => (o.type === OPTION_SINGLE || o.type === OPTION_MULTIPLE) && o.options.length > 0);
        if (mandatoryOptions.length > 0) {
            setSelectedProduct(product);
            setIsModalOpen(true);
        } else {
            addProduct(product);
        }
    }

    const addProduct = (product, options = [], count = 1) => {
        if (!currentOrderId || currentOrderId.length === 0) {
            addNewOrder(currentTable.table.id);
        }

        let order = getOrdersById(currentOrderId);
        if (!order) {
            console.error('order not found')
            return;
        } 

        if (order.status){
            orderInProgressError();
            return;
        }
       
        const id = uuidv4();
        const newProduct = { ...product, id: id, productId: product.id };
        const newProductOptions = options || [];
              
        let newListAfterAdd = [];
        
        const findProduct = (order.products || []).find(p => p.productId === product.id);
        if (findProduct && findProduct.id && (!options || options.length === 0) ) {
            findProduct.count += count; 
            newListAfterAdd = order.products.map(product => {
                if (product.id === findProduct.Id) 
                  return  {...product, count: findProduct.count}; 
                return product;
              });
        }else{
            const productOptions = newProductOptions;//newProduct.Options.filter(o => o.Type === OPTION_SINGLE && o.Options.length > 0);
            const selectedOptions = productOptions.map(o => {
              return {
                id: o.id,
                options: [ ...o.options.filter(oo => oo.selected === true)]
              }
            });

            newListAfterAdd = [...order.products, {...newProduct, count: count, selectedOption: [...selectedOptions ], datetime: new Date().valueOf()} ];
        }

        order.products = [...newListAfterAdd];

        setUnsubmittedOrders({
            ...unsubmittedOrders,
            [currentOrderId]: order
        });
    }

    const deleteUnsubmittedOrder = async(orderId) => {
        let orders = unsubmittedOrders;
        delete orders[orderId];
        setCurrentOrderId('');
        setUnsubmittedOrders(orders);        
    }

    const cancelOrder = async(orderId) => {

        let order = getOrdersById(orderId);
        if (order.status) {
           await orderAPI.changeStatus(order.id, CANCELED)
        } else {
            let orders = unsubmittedOrders;
            delete orders[orderId];
            setUnsubmittedOrders(orders);
        }
        setTableView(true);        
    }

    const showTableOrders = (qrId) => {
        let qr = qrCodes.find(qr => qr.id === qrId);
        setCurrentTable({
            table: { id: qrId, name: qr.name, qr: qr.data.qr, data: qr.data },
            showOrders: true
         });
         
        setTableView(false);
    }
    
    const addNewOrder = (qrId) => {
        let qr = qrCodes.find(qr => qr.id === qrId);
        const newOrderId = uuidv4();
        
        setUnsubmittedOrders({
            ...unsubmittedOrders,
            [qrId]: { 
                id: qrId,
                products: [],
                delivery: {
                    deliveryType: qr.data.isTable === false && qr.data.isAllowTakeaway ? DELIVERY_TAKEAWAY : DELIVERY_RESTAURANT,
                    paymentType: '',
                    menuLocationId: qrId,
                    deliveryQr: qr.data.qr,
                },
                created: {
                    seconds: (new Date().getTime() / 1000)
                }, 
            }
        });

        setCurrentTable({
            table: { id: qrId, name: qr.name, qr: qr.data.qr, data: qr.data },
            showOrders: false,
         });

        setCurrentOrderId(qrId);
        setTableView(false);
    }

    const selectTable = (qrId) => {
        
        let qr = qrCodes.find(qr => qr.id === qrId);
        
        setCurrentTable({
            table: { id: qrId, name: qr.name, qr: qr.data.qr, data: qr.data },
            showOrders: false,
         });

        setTableView(false);
    }

    const changeTable = () => {
        let orderIds = getOrdersByQr(currentTable.table.qr).map(o => o.Id);
        //console.log('changeTable:orderIds', orderIds);
        setTableChange({mode: true, orderIds: orderIds});
        setTableView(true);
        //setChangeTableInProgress(true);
    }

    const changeTableComplete = () => {
        //selectTable(currentTable.table.qr);
        setTableChange({mode: false, orderIds: []});
        setTableView(false);
        message.success({  content: 'Table changed', style: { textAlign: "center" },});
        //setChangeTableInProgress(false);
    }

    const selectOrder = async(orderId) => {
        setCurrentTable({
            ...currentTable,
            showOrders: false
        })
        setCurrentOrderId(orderId);
    }

    const payOrderCash = async() => {
        if (unsubmittedOrders[currentOrderId]){
            let order = unsubmittedOrders[currentOrderId]
            order.delivery.paymentType = PAYMENT_CASH;
            setUnsubmittedOrders({...unsubmittedOrders, [order.id]: order });
        } else {
            await posAPI.setPayed(currentOrderId, PAYMENT_CASH, 0);
        }
        setChangePaymentType(false);
    }

    const payOrderCard = async() => {
        if (unsubmittedOrders[currentOrderId]){
            let order = unsubmittedOrders[currentOrderId]
            order.delivery.paymentType = PAYMENT_CARD;
            setUnsubmittedOrders({...unsubmittedOrders, [order.id]: order });
        } else {
            await posAPI.setPayed(currentOrderId, PAYMENT_CARD, 0);
        }
        setChangePaymentType(false);
    }

    const completeCurrentOrder = async() => {
       
        if (getOrdersById(currentOrderId).delivery.paymentType.length === 0){
            message.error({
                content: 'Order payment type not set CASH or CARD',
                style: { textAlign: "right", paddingRight: "50px" },
              });
            return;
        }

        try{
            setSubmit(true);
            await submitCurrentOrder(true);
            await orderAPI.changeStatus(currentOrderId, COMPLETED);
            deleteUnsubmittedOrder(currentOrderId);
            setCurrentOrderId('');
            setCurrentTable({table:{}, showOrders: false });
            setTableView(true);
        }finally{
            setSubmit(false);
        }
        
    }

    const payOrders = async() => {
        await submitCurrentOrder(false, true);
        setPaymentView(true);
    }

    const submitCurrentOrder = async(overrideSubmitIndicator = false, skipFirebase = false) => {

        if (!currentTable.table.qr){
            message.error({
                    content: 'Please select table',
                    style: { textAlign: "right", paddingRight: "50px" },
                  });
        }

        const order = unsubmittedOrders[currentOrderId];
        if (!order)
            return;

        if (order.products.length === 0) {
            message.error({
                content: 'No product in the order',
                style: { textAlign: "right", paddingRight: "50px" },
              });

              return;
        }

        if (!overrideSubmitIndicator)
            setSubmit(true);

        const o = {
            id: uuidv4(),
            products: order.products.reduce((map, p) => { 
                map[p.id] = {
                    Id: p.id,
                    ProductId: p.productId,
                    Count: p.count,
                    SelectedOption: toUpperCase(p.selectedOption)
                };
                return map; 
            },{}),
            lang: 'et',
            delivery: {
                deliveryType: order.delivery.deliveryType,
                paymentType: order.delivery.paymentType,
                deliveryQr: currentTable.table.qr,
            },
            pos: 0,
            skipFirebase: skipFirebase,
            qr: currentTable.table.qr
        };

        if (currentTable.table.data.isAllowTakeaway) {
            o.delivery.pickupType = PICKUP_SELF;
            o.delivery.takeawayName = 'Takaaway';
            o.delivery.takeawayPhone = 'Takaaway'
        }

        try {
            const response = await orderAPI.submitOrder(o);
            if (response && response.id){
                deleteUnsubmittedOrder(currentOrderId);
                //setCurrentOrderId('');
                //setCurrentTable({table:{}, showOrders: false });

            }
        }catch(e){
            console.error(e)
        } finally {
            if (!overrideSubmitIndicator)
                setSubmit(false);
        }
    }

    const onSearch = (value) => {
        if (!value || value.length < 2){
            setSearchTerm(value);
            setFilteredProducts(products);
            return;
        }

        setSearchTerm(value);

        const filterTable = products.filter(o =>
            Object.keys(o.data.name).filter(k => o.data.name[k]).some(k =>
                o.data.name[k]
                  .toLowerCase()
                  .includes(value.toLowerCase())
              )
           ||
           (o.data.description && Object.keys(o.data.description).filter(k => o.data.description[k]).some(k =>
                o.data.description[k]
                  .toLowerCase()
                  .includes(value.toLowerCase())
              ))
            ||
            o.properties.some(p => p.data.name.toLowerCase().includes(value.toLowerCase()))

          );

        setFilteredProducts(filterTable);
    }

    const removeProduct = (product) => {
        setCurrentOrder({ 
            products: [...currentOrder.products.filter(p => p.id !== product.id)],
        });
    }

    const updateProductCount = (product, newCount) => {
        product.count = newCount;

        setCurrentOrder({ 
            products: [...currentOrder.products.filter(p => p.id !== product.id), {...product}],
        });
    }

    const orderInProgressError = () => {
        message.error({
            content: 'Order order already preparing, can`t add or change. Please submit new order for same table',
            style: { textAlign: "right", paddingRight: "50px" },
          });
        return;
    }

    const plus = (product) => {
        let order = getOrdersById(currentOrderId);
        if (order.status){
            addNewOrder(currentTable.table.id);

        }

        const newCount = product.count + 1;
        updateProductCount(product, newCount);
    }

    const minus = (product) => {
        let order = getOrdersById(currentOrderId);
        if (order.status){
            orderInProgressError();
            return;
        }

        const newCount = product.count - 1;
        if (!newCount || newCount <= 0){
            let ask = { ...askRemove};
            ask[product.id] = { 
                ok: () => { 
                    removeProduct(product);
                },
                cancel: () => { 
                    const newAsk = Object.keys(askRemove).reduce((object, key) => {
                        if (key !== product.id) {
                          object[key] = askRemove[key]
                        }
                        return object
                      }, {});
                      setAskRemove({...newAsk}); 
                } 
            };
            setAskRemove({...ask});
            
        }else{
            updateProductCount(product, newCount);
        }
    }
    
    const toggleProductCheck = (productId) => {
        let selectedCopy = {...selectedProducts};
        if (selectedCopy[productId]){
            selectedCopy[productId] = false;
        }
        else{            
            selectedCopy[productId] = true;
        }
        setSelectedProducts(selectedCopy);
    }

    const getTableProducts = (tableId) => {
        let tableOrders = getOrdersByTableId(tableId);
        //console.log('tableOrders', tableOrders);
        let products = Object.values(tableOrders).sort((a, b) => (a.datetime || 0) - (b.datetime || 0)).map(p => Object.values(p.products)).flat();
        //console.log(products);
        return products;
    }

    
    const getSelectedProductsForPayment = () => {	
        const selectedForPayment = getTableProducts(currentTable.table.id).filter(p => selectedProducts[p.id] === true);
        //let tableOrders = getOrdersByTableId(tableId);
        
        //console.log('Object.values(selectedProducts)', Object.values(selectedProducts), Object.values(selectedProducts).filter(x => x === true));
        //console.log(' selectedForPayment.filter(p => selectedProducts[p.id] === true)',  selectedForPayment.filter(p => selectedProducts[p.id] === true));
        return selectedForPayment;
    }
        


    const showProducts = () => {
        if (currentTable.showOrders === true)
            return showOrders();

        /*
        let order = getOrdersById(currentOrderId);
        if (!order)
            return <div></div>;
        */
       let tableProducts = getTableProducts(currentTable.table.id);
       // 
        
        if (!tableProducts || tableProducts.length === 0) return <div></div>;
        let tableOrders = getOrdersByTableId(currentTable.table.id);
        //console.log('tableOrders', tableOrders);
        /*
        
        const tableOrderProducts = tableOrders.reduce((map, o) => {
                map[o.id] =  Object.values(o.products).map(p => {  return { ...p, orderId: o.id, status: o.status || 'UNSUBMITTED'} }).flat();
                return map;
        }, {});
        */


        //console.log('tableOrderProducts', tableOrderProducts);
        //console.log('Object.values(tableOrderProducts)', Object.values(tableOrderProducts))
        
        return <div className="flex-1 w-full px-4 overflow-auto flex flex-col gap-1">
            { 
            tableProducts.length > 0
            ?
            tableProducts.map(p => 
                !askRemove[p.id] 
                ?
                <div key={`${p.id}_p`} className={`h-20 font-mulle grid grid-cols-4 p-4 rounded-lg bg-gray-${selectedProducts[p.id] ? '800' : '400'} list-item-shadow z-40`} onClick={() => toggleProductCheck(p.id)}>
                        <div className="col-span-2">
                            <div className={`${p.selectedOption && p.selectedOption.length > 0 ? 'h-2' : 'h-4'}`}>&nbsp;</div>
                            <div className="truncate text-white">{getMultiLang(p.data || p, 'name')}</div>
                            <div className="text-2xxs">
                                {
                                    p.selectedOption.map(o => o.options.map(oo => getMultiLang(oo, 'name')).flat().join(", "))
                                }
                                {
                                    /*p.datetime*/
                                }
                            </div>
                        </div>
                        <div className="h-18">
                            <div className="h-4 text-xxs text-center font-mulle pb-2">Quantity</div>
                            <div 
                                className="flex justify-center">
                                <div className="h-8 w-10 text-center font-bold text-2xl rounded-l-lg font-mulle cursor-pointer" onClick={() => minus(p)}>-</div>
                                <div className="h-8 w-6 text-center font-bold text-lg font-mulle">{p.count}</div>
                                <div className="h-8 w-10 text-center font-bold text-2xl rounded-r-lg font-mulle cursor-pointer" onClick={() => plus(p)}>+</div>
                            </div>
                        </div>
                        <div className="">
                            <div className="h-4">&nbsp;</div>
                            <div className="font-mulle font-bold lg:text-xl md:text-base text-right">{formatCurrencty(calculateTotal([p]), p.currency)}</div>
                        </div>
                    </div>
                    :
                    <div key={`${p.id}_ask`} className="h-20 font-mulle flex flex-col px-11 rounded-lg list-item-shadow bg-red">
                        <div className="text-xs text-center pt-2 text-white">Do you want to remove this item from the cart?</div>
                        <div className="flex justify-between font-bold pt-1 text-lg text-white">
                            <div className="cursor-pointer" onClick={() => askRemove[p.id].ok()}>Remove</div><div className="cursor-pointer" onClick={() => askRemove[p.id].cancel()}>Cancel</div>
                        </div>
                    </div>
                    )
                : currentTable.table.id ? <div className='text-center'>Start adding products to order</div> : setTableView(true)
            }
        </div>
    }

    const showTables = () => {
        return <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-3 px-4 pt-5 max-w-3xl w-full">
        {qrCodes.sort((a,b) => b.data.isAllowTakeaway - a.data.isAllowTakeaway).map(qr =>  <div key={`${qr.id}_qr`} className="h-24 font-mulle p-4 rounded-lg bg-gray-500 list-item-shadow z-40 cursor-pointer">
            <div className='text-base '>
                <div className='flex justify-between'>
                    <div className='truncate w-32' onClick={() => showTableOrders(qr.id)}>
                        {qr.name}
                        <div className={`pt-3 text-xl ${getOrdersByQr(qr.data.qr).length === 0 ? 'text-white' : 'text-green-500'} font-bold`}>
                            {getOrdersByQr(qr.data.qr).length}
                        </div>
                    </div>
                    <div className='pt-8 cursor-pointer' onClick={() => addNewOrder(qr.id)}>
                        <PlusCircleOutlined style={{ fontSize: "32px" }}/>
                    </div>
                </div>
               
            </div>
        </div>)}
        </div>
    }

    const showPackages = () => {
        return <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-3 px-4 pt-5 max-w-3xl w-full">
        {
            products.filter(p => p.properties.find(pr => pr.type ==='TYPE' && pr.data.name === 'Package')).length > 0
            ? products.filter(p => p.properties.find(pr => pr.type ==='TYPE' && pr.data.name === 'Package')).map(p =>  <div key={`${p.id}_package`} className="h-20 font-mulle grid grid-cols-4 p-4 rounded-lg bg-gray-500 list-item-shadow z-40 cursor-pointer" onClick={() => { addProduct(p); setPackageView(false);}}>
                <div className='text-center text-2xl'>{getMultiLang(p.data, 'name')}</div>
            </div>)
            : <div className='text-white text-xl text-center'>No packages found</div>
        }
        </div>
    }

    const showOrders = () => {

        return <div><div className='text-white pt-3 px-4 font-bold text-base'>Orders</div><div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-3 px-4 pt-5 max-w-3xl w-full'>
            
        {
            getOrdersByQr(currentTable.table.qr).length > 0
            ? getOrdersByQr(currentTable.table.qr).sort((a, b) => (a.Created).seconds - b.Created.seconds).map(o => <div className='text-white h-20 font-mulle grid grid-cols-4 p-4 rounded-lg bg-black border-2 list-item-shadow z-40 cursor-pointer'
                onClick={() => selectOrder(o.Id)}
                >
                    <div className='text-left'>
                        <div className='text-xs'>Created</div>
                        <div className='font-bold'>{moment(new Date((o.Created).seconds * 1000)).format('HH:mm')}</div>
                    </div>
                    <div className="">
                        <div className='text-xs'>Products</div>
                        <div className='font-bold'>{Object.keys(o.Products).length}</div>
                    </div>
                    <div>
                        <div className='text-xs'>Payment</div>
                        <div className={`${o.Payment && o.Payment[PAYMENT_PAID] ? 'text-white' : 'text-red-back'} text-left rounded-b-md font-bold cursor-pointer`}>
                            {
                            o.Payment && o.Payment[PAYMENT_PAID] 
                            ? <div>{formatCurrencty(o.Status ? o.TotalGross : calculateTotal(toLowerCase(o.Products)), company.currency)} <div>PAID</div></div>
                            : <div>{formatCurrencty(o.Status ? o.TotalGross : calculateTotal(toLowerCase(o.Products)), company.currency)} <div>UNPAID</div></div>
                            }
                        </div> 
                        </div>
                    <div className='text-right'>
                        <div className='text-xs'>Status</div>
                        <div className='font-bold'>{o.Status || 'UNSUBMITTED'}</div>
                    </div>
                </div>)
            : <div className='text-center'>No orders on {currentTable.table.name}</div>
        }    
        </div>
        </div>
    }

    const resetOrder = async() => {
        await cancelOrder(currentOrderId);
    }

    const doneDetailsEdit = (options) => {
        setIsModalOpen(false);
        addProduct(selectedProduct, options);
    }

    const cancelDetailsEdit = () => {
        setIsModalOpen(false);
    }

    const isUnsubmittedOrder = (orderId) => {
        return unsubmittedOrders[orderId] !== undefined;
    }

    const findFirstImage = (productList) => {
        let foundWithImages = productList && productList.length > 0 ? productList.find(x => x.data && x.data.images && x.data.images.length > 0) : false;
        return foundWithImages;
    }

    const showPos = () => {
        return <div className='bg-black text-white min-h-screen h-full'>
        { isModalOpen && selectedProduct.id ? <PosSellDetails isModalOpen={isModalOpen} product={selectedProduct} onDone={doneDetailsEdit} onCancel={cancelDetailsEdit} /> : ''  }
        
        {
            tableView
            ? <div className='bg-black z-40'><PosSellVisual tableChange={tableChange} onTableChangeComplete={() => changeTableComplete()} addNewOrder={addNewOrder} selectLocation={selectTable} qrLocations={qrCodes} snapshot={snapshot} types={types}/></div>
            : paymentView ? <PosPayment close={ () => setPaymentView(false) } products={getSelectedProductsForPayment()}/> : showOrderComposition()
        }
        </div>;
    }

    const showOrderComposition = () => {
        return  !tableView && <div className='flex-grow flex'>
       
            <div className='flex flex-col h-full  max-w-lg'>
    
                
                <div className="rounded-lg flex flex-col h-full bg-gray-300">
                    <div className='rounded-3xl flex flex-col h-screen shadow'>
                        <PosSellHeader lastSettlement={lastSettlement} onVisualView={() => setTableView(!tableView)}/>
                        {/*<PosChangeTablePopup currentTable={currentTable}/>*/}
                        <div className='text-xl font-bold text-center p-3 flex justify-center'>
                                {currentTable.table.name} <div className='px-3 text-sm text-red cursor-pointer' onClick={() => changeTable()}>change</div>
                        </div>
                       
                        <div className='flex-1 flex flex-col overflow-auto'>
                            {
                                showProducts()
                            }
                        </div>
                        <div className='select-none h-auto w-full text-center pt-3 pb-4 px-4 bg-gray-400 rounded-lg'>
                        {
                            submit 
                            ? <div className="text-center">
                                    <div className={`mx-auto h-10 w-10 rounded-full pt-1.5`}>
                                        <svg className="animate-spin mr-3 h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </div>
                                </div>
                            : <div className='h-42'>
                                <div className="h-16 flex justify-between px-7 pt-2 cursor-pointer">
                                    <div className='text-2lg'>Total</div>
                                    <div className='text-2lg font-bold'>{ 
                                         formatCurrencty(calculateTotal(getTableProducts(currentTable.table.id)), company.currency) 
                                    }
                                    </div>
                                </div>
                                <div className='flex justify-between pt-2 gap-3'>
                                {
                                    isUnsubmittedOrder(currentOrderId)
                                    ? <div className="h-16 text-center pt-2.5 cursor-pointer bg-yellow-400 rounded-lg flex-grow" onClick={() => submitCurrentOrder()}>
                                            <div className='pt-1 text-2xl font-bold'>TO KITCHEN</div>
                                        </div>
                                    : <></>
                                }
                                
                                <div className="h-16 text-center px-7 pt-2.5 cursor-pointer bg-green-600 rounded-lg" onClick={() => payOrders() }>
                                        <div className='pt-1 text-2xl font-bold'>PAY {getOrdersById(currentOrderId) && getOrdersById(currentOrderId).status ? `(${getOrdersById(currentOrderId).status})`: ''}</div>
                                    </div>
                                </div>
                                {/*
                                    isUnsubmittedOrder(currentOrderId) || !(getOrdersById(currentOrderId).payment && getOrdersById(currentOrderId).payment[PAYMENT_PAID]) || changePaymentType
                                    ? <div className='flex justify-between pt-2 gap-3'>
                                            <div className={`h-16 flex-grow text-center pt-2 cursor-pointer ${ getOrdersById(currentOrderId).delivery.paymentType === PAYMENT_CASH ? 'bg-yellow-600 text-white' : 'bg-white text-black'} rounded-lg`} onClick={() => payOrderCash()}>
                                                <div className='pt-1 text-2xl font-bold'>CASH</div>
                                            </div>
                                            <div className={`h-16 flex-grow text-center pt-2 cursor-pointer ${ getOrdersById(currentOrderId).delivery.paymentType === PAYMENT_CARD ? 'bg-blue text-white' : 'bg-white text-black'} rounded-lg`} onClick={() => payOrderCard()}>
                                                <div className='pt-1 text-2xl font-bold'>CARD</div>
                                            </div>
                                        </div>
                                    : <div className='flex pt-2'>
                                        <div className={`h-16 flex-grow w-100 text-center pt-2 bg-green-500 rounded-lg cursor-pointer`} onClick={() => setChangePaymentType(true)}>
                                                <div className='pt-1 text-2xl font-bold'>PAID {formatCurrencty(getOrdersById(currentOrderId).totalGross, company.currency)} ({getOrdersById(currentOrderId).delivery.paymentType})</div>
                                            </div>
                                    </div>
                            */}
                                
                            </div>
                        }
                        </div>
                    </div>
                   
                </div>
                
              
               
            </div>
            <div className='flex flex-col h-screen w-full'>
                
                <div className='h-full overflow-hidden mt-4'>
                    <div className='h-full px-2 overflow-y-auto '>
                        <div>
                            <input type="search" className='text-black w-full px-3 h-10 rounded-md border-0 text-lg focus: outline-none' placeholder="input search text" onChange={(e) => onSearch(e.target.value)}/>
                        </div>
                        <div className='grid lg:grid-cols-5 md:grid-cols-2 xs:grid-cols-1 gap-7 pt-5 '>
                        {
                            selectedGroup 
                            ? [<div 
                                className='cursor-pointer bg-gray-500 h-52 font-mulle flex flex-col rounded-lg list-item-shadow'
                                onClick={() => showSelectedGroupProducts(null) }
                                >     
                                <div className='h-40 bg-gray-500'><LeftOutlined style={{ fontSize: "32px" }} className="px-3 pt-3"/></div> 
                                <div className='px-3 pb-3 pt-3 h-8 text-2xl'>{'Back'}</div>
                                <div>
                                    <div className="w-full flex justify-end px-3 pb-2">
                                        <div className="font-bold text-base float-left h-8 pt-1"></div>
                                        
                                    </div>
                                </div>
                            </div>].concat((filteredProducts.length == 0 ? products : filteredProducts).map(p => <div 
                                        className='cursor-pointer bg-gray-500 h-52 font-mulle flex flex-col rounded-lg list-item-shadow'
                                        onClick={() => addProductCheckOptions(p) }
                                        >     
                                    {
                                        p.data && p.data.images.length > 0 
                                        ? <div className="bg-cover h-32 bg-center rounded-lg" style={{backgroundImage: `url(${getProductSmallImage(p.data)})`}}></div>
                                        
                                        : <div className='h-32 bg-gray-500'></div> 
                                    }
                                    <div className='px-3 pb-3 pt-3 h-14'>{getMultiLang(p.data, 'name')}</div>
                                    <div>
                                        <div className="w-full flex justify-end px-3 pb-2">
                                            <div className="font-bold text-base float-left h-8 pt-1">{ formatCurrencty(p.data.gross, company.currency) }</div>
                                            
                                        </div>
                                    </div>
                                </div>))
                                : groups && Object.keys(groups).length > 0 && Object.keys(groups).map(g => <div 
                                    className='cursor-pointer bg-gray-500 h-52 font-mulle flex flex-col rounded-lg list-item-shadow'
                                    onClick={() => showSelectedGroupProducts(g)}
                                    >
                                {
                                    findFirstImage(groups[g]) 
                                    ? <div className="bg-cover h-40 bg-center rounded-lg" style={{backgroundImage: `url(${getProductSmallImage(findFirstImage(groups[g]).data)})`}}></div>
                                    : <div className='bg-gray-500 h-40 rounded-lg'></div> 
                                }
                                <div className='px-3 pb-3 pt-3 h-8 text-xl'>{g}</div>
                                </div>)
                            }
                            
                        </div>
                    </div>
                </div>
            </div>   
       
    </div>
    }

    const onPosOpen = (settlement) => {
        setLastSettlement(settlement);
    }

    const onPosClose = () => {
        initialData();
    }

    return <div >
        { 
        loading 
            ? <Skeleton/>
            : closePos 
                ? <PosSellSettlementClose companyId={company.companyId} onPosClose={onPosClose}/> 
                : lastSettlement && lastSettlement.startTime && lastSettlement.endTime == null 
                    ? showPos() 
                    : <PosSellSettlementOpen companyId={company.companyId} onPosOpen={onPosOpen}/> 
        }
    </div>
}