import { API_BASE_ENDPOINT, API_BASE_HEADER } from '../http/apiConfig';
import { message } from 'antd';

class ZoneAPI {

    getListForCompany = async(companyId) => {
        if (!companyId)
            return [];

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menuzone/${companyId}/list`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return data;
        }catch(e){
            message.error(e.message);
            message.error(e);
        }
    }

    create = async(menuId, zone) => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menuzone/${menuId}`,
                await API_BASE_HEADER('POST', zone)
            );
            let data = await response.json();
            return data;
        }catch(e){
            message.error(e.message);
            console.error(e);
        } 
    }

    update = async(id, zone) => {
        if (!id)
            return;
            
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/menuzone/${id}`,
                await API_BASE_HEADER('PUT', zone)
            );
            let data = await response.json();
            return data;
        }catch(e){
            message.error(e.message);
            console.error(e);
        }   
    }

    delete = async(id) => {
        if (!id)
            return;

        try {
            await fetch(
                `${API_BASE_ENDPOINT}/api/menuzone/${id}`,
                await API_BASE_HEADER('DELETE')
            );
            
        }catch(e){
            message.error(e.message);
            console.error(e);
        }   
    }

    assign = async(id, menuLocationId) => {        
        try {
            await fetch(
                `${API_BASE_ENDPOINT}/api/menuzone/location/${menuLocationId}?zoneId=${id}`,
                await API_BASE_HEADER('PATCH')
            );
        }catch(e){
            message.error(e.message);
            console.error(e);
        } 
    }

    deleteAssigment = async(id, menuLocationId) => {        
        try {
            await fetch(
                `${API_BASE_ENDPOINT}/api/menuzone/${id}/location/${menuLocationId}`,
                await API_BASE_HEADER('DELETE')
            );
        }catch(e){
            message.error(e.message);
            console.error(e);
        } 
    }

};

const zoneAPI = new ZoneAPI();
export default zoneAPI;