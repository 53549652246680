import {
    Modal
  } from 'antd';

import React, { useState, useEffect } from 'react';
import { useCompany } from '../../hooks/CompanyProvider';
import { formatCurrencty, DELIVERY_RESTAURANT, PAYMENT_POS, OPTION_SINGLE, OPTION_MULTIPLE, getMultiLang, getProductSmallImage } from '../Common';
import { Popconfirm, Radio, Space  } from 'antd';

export default function PosSellDetails({product, isModalOpen, onDone, onCancel}) {
   
    const company = useCompany();
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(
            getOptionsCopy()
         );
    }, [])

    const checkMandatoryOptions = () => {
        let canAdd = true;

        const mandatoryOptions = product.options.filter(o => o.type === OPTION_SINGLE && o.options.length > 0);
        if (mandatoryOptions.length > 0) {
            for(let i= 0; i < mandatoryOptions.length; ++i){
                const isNotSelected = isOptionSelected(mandatoryOptions[i].id) === false;
                if (isNotSelected === true && canAdd === true){
                   canAdd = false;
                }
                setOptions(
                    [...options.map(o => {
                        if (o.id === mandatoryOptions[i].Id)
                            o.highlight = isNotSelected;

                        return o;
                    })]
                )
            }
        }

        return canAdd;
    }

    const updateProductOption = (optionId, optionOptionId, checked) => {
       
        const option = options.find(o => o.id === optionId);
        
        option.options = option.options.map(oo => {
            if (oo.id === optionOptionId) {
                oo.selected = option.type === OPTION_SINGLE ? true : checked;
            } else if (option.Type === OPTION_SINGLE) {
                oo.selected = false;
            }
            return oo;
        })
        setOptions([...options.map(o => {
            if (o.Id === optionId){
                option.highlight = false;
                return option;
            }
            return o;
        })]);
    }

    const isOptionSelected = (optionId, optionOptionId) => {
        if (!options || options.length === 0) return false;
        const optionInLocal = options.find(o => o.id === optionId);
        
        let selectedOption;
        if (optionInLocal.type === OPTION_SINGLE) {
            selectedOption = optionInLocal.options.find(oo => oo.selected === true);
        } else {
            selectedOption = optionInLocal.options.find(oo => oo.id == optionOptionId && oo.selected === true);
        }

        return selectedOption ? selectedOption.id : false;
    }

    const getOptionsCopy = () => {
        return JSON.parse(JSON.stringify(product.options.filter(o => o.options.length > 0).map(o => { 
            if (o.type === OPTION_SINGLE && !o.Id)
                o.highlight = true; 
            return o;
        })));
    }
   
    const getLocalSelectedOptions = () => {
        if (!options || options.length === 0) return [];
        return JSON.parse(JSON.stringify(options)).map(o => {
            o.options = o.options.filter(o => o.Selected === true)
            return o;
        });
    }

    const productOptions = (index) => {
        return options && options.length > 0
        ?
        <div className="pb-10">
            <div className={`px-3 pt-1 ${ index !== undefined ? 'border-t border-red rounded-lg' : ''}`}>
                <div className="">
                    <div className="text-base font-bold text-right">{ index !== undefined ? index + 1 : ""}</div>
                </div>
            </div>
            {
                 options.map(o => 
                    <div className="px-3 rounded-lg" key={`${o.id}_${getMultiLang(o, 'name', company.language)}`}>
                        <div className="text-lg font-bold pb-2 flex justify-between space-x-2">
                            <div>{getMultiLang(o, 'name', company.language)}</div>
                            <div className="text-red-back text-xs pt-2">{o.highlight ? 'Required' : ''}</div>
                        </div>
                        <div>
                           
                            {
                                o.type === OPTION_SINGLE 
                                ?
                                <div className={`px-3 font-mulle w-full rounded-lg text-lg pt-1 cursor-pointer`}>
                                     <Radio.Group onChange={(e) => updateProductOption(o.id, e.target.value)} defaultValue={isOptionSelected(o.id, null)}>
                                        <Space direction="vertical">
                                        { 
                                            o.options.map(oo => <Radio.Button key={oo.id} value={oo.id}>{getMultiLang(oo, 'name', company.language) }</Radio.Button>)
                                        }  
                                        </Space>
                                    </Radio.Group>
                                 {/*  <select 
                                        className="w-full focus:outline-none bg-white text-base" 
                                        value={isOptionSelected(o.id, null)}
                                        onChange={(e) => updateProductOption(o.id, e.target.value)}>
                                        { 
                                            isOptionSelected(o.id, null) === false ?  <option key={""} value={""}>{'Select'} {getMultiLang(o, 'name', company.language).toLowerCase()}</option> : '' 
                                        }
                                        {
                                            o.options.map(oo => <option key={oo.id} value={oo.id}>
                                                <span class="flex justify-between">
                                                    <span>{ getMultiLang(oo, 'name', company.language) }</span>
                                                    <span>{(oo.gross || oo.net) > 0 ? ` - ${formatCurrencty((oo.gross || oo.net) || 0, product.data.currency)}` : ''}</span>
                                                </span>
                                            </option>)
                                        }
                                    </select>
                                    */} 
                                </div>
                                :
                                <div>
                                    {
                                        o.options.map(oo => 
                                            <div key={oo.id} className="flex pt-3 pl-2">
                                                <div><input key={`${oo.id}_check`} type="checkbox" checked={isOptionSelected(o.id, oo.id)} className="h-5 w-5" onChange={(e) => updateProductOption(o.id, oo.id, e.target.checked)}/></div>
                                                <div className="pl-3 text-base w-full ">{getMultiLang(oo, 'name', company.language)}</div>
                                                <div className="text-right text-lg font-bold pr-5">{formatCurrencty(oo.gross || oo.net, product.data.currency)}</div>
                                            </div>)
                                    }
                                </div>
                            }
                            
                        </div>
                    </div>
                    )
                
            }
        </div>
        :
        <div></div>
    }

    const handleOk = () => {
        if (checkMandatoryOptions())
            onDone(options);
    }

    const handleCancel = () => {
        onCancel();
    }

    return (
        <Modal
            destroyOnClose={true}
            title={getMultiLang(product.data, 'name', company.language)}
            open={isModalOpen}
            confirmLoading={false}
            onOk={handleOk}
            onCancel={handleCancel}
        >
         <div
            className="flex flex-col xl:pt-0 h-full w-full">
                <div className="xl:max-w-4xl w-full xl:flex">
                        <div className="px-3 w-full flex-grow">
                            <div className="text-sm pl-3 pr-3 pt-2 whitespace-pre-line">
                                { product.data.description && getMultiLang(product.data, 'descriptionme', company.language)}
                            </div>
                            {
                                productOptions()
                            }
                        </div>
                    </div>
               
        </div>
        </Modal>
       
       
        );
}