import { API_BASE_ENDPOINT, API_PDF_ENDPOINT, API_BASE_HEADER } from '../http/apiConfig';
import { message } from 'antd';

class OrderAPI {
   
    submitOrder = async(order) => {
        if (!order){
            console.log('order is empty');
            return '';
        }

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/order`,
                await API_BASE_HEADER('POST', order)
            );
            let data = await response.json();
            return data;
        }catch(e){
            message.error(e);
        }
    }

    getOrders = async(companyId, filters) => {
        if (!companyId || !filters)
            return [];

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/order/${companyId}/company`,
                await API_BASE_HEADER('POST', filters)
            );
            let data = await response.json();
            return data;
        }catch(e){
            message.error(e);
        }
    }

    changeStatus = async(id, status) => {
        if (!id)
            return false;

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/order/${id}/status${status ? `/${status}` : ''}`,
                await API_BASE_HEADER('PUT')
            );
            let data = await response.json();
            return data;
        }catch(e){
            message.error(e);
        }
    }

    changeItemStatus = async(id, itemId, status) => {
        if (!id || !itemId)
            return false;

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/order/${id}/item/${itemId}/status${status ? `/${status}` : ''}`,
                await API_BASE_HEADER('PUT')
            );
            let data = await response.json();
            return data;
        }catch(e){
            message.error(e);
        }
    }

    changePaymentStatus = async(id, status) => {
        
        if (!id || !status)
            return false;

        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/payment/${id}/status/${status}`,
                await API_BASE_HEADER('PUT')
            );
            let data = await response.text();
            return data;
        }catch(e){
            message.error(e);
        }
    }

    
    getPdfInvoice = async(order) => {
        if (!order) return;
        try {
            const response = await fetch(
                `${API_PDF_ENDPOINT}/api/pdf/`,
                {
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    method: 'POST', // *GET, 
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', //
                    body: JSON.stringify(order)
                }
            );
            let data = await response.blob();
            return data;
        }catch(e){
            console.error(e);
            return false;
        }
    }

}


const orderAPI = new OrderAPI();
export default orderAPI;