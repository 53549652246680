import React, { useState } from 'react'
import MenuLocationList from './MenuLocation';

import { Skeleton, Layout  } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';



export default function MenuIndex(props) {
    const [loading, setLoading] = useState(false);
  

    return (
        <Layout style={{ height: "100vh", overflow: "auto" }} >
        
        <div  style={{ padding: '15px 20px', marginTop: 5, height: '100vh', width: "100%"}}>
            <PageHeader
            className="site-page-header"
            title="Restaurants and menu"
            subTitle="rastaurants, menus, QR codes, publish menus to public"
            extra={
                [  
                    <>
                    </>
                ]
            }
        >
            
            </PageHeader>
                
                { loading 
                    ? <Skeleton active />  
                    : <MenuLocationList />
                }
            </div>
        </Layout>
        
    )
}