import { API_BASE_ENDPOINT, API_BASE_HEADER, parse, parseCompany } from '../http/apiConfig';

class CompanyAPI {
  
    getList = async() => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/company`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return parse(data, [], parseCompany);
        }catch(e){
            console.error(e);
        }
        
    }

    

    update = async(company) => {
        if (!company.id)
            return;
            
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/company/${company.id}`,
                await API_BASE_HEADER('PUT', company)
            );
            let data = await response.json();
            return parse(data, {}, parseCompany);
        }catch(e){
            console.error(e);
            return null;
        }   
    }

    createStripeAccount = async(companyId) => {
        if (!companyId)
            return;

            try {
                const response = await fetch(
                    `${API_BASE_ENDPOINT}/api/payment/account`,
                    await API_BASE_HEADER('POST', companyId)
                );
                let data = await response.text();
                return data;
            }catch(e){
                console.error(e);
                return null;
            }   
    
    }

    register = async(company) => {
        if (!company)
            return;

            try {
                const response = await fetch(
                    `${API_BASE_ENDPOINT}/api/company/register`,
                    await API_BASE_HEADER('POST', company)
                );
                let data = await response.text();
                return data;
            }catch(e){
                console.error(e);
                return null;
            }   
    
    }
};

const companyAPI = new CompanyAPI();
export default companyAPI;