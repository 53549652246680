
import React, { useState, useEffect } from 'react'
import {
  Modal,
  Form,
  Input,
  Select,
  Divider,
  InputNumber,
  TimePicker,
  DatePicker 
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid'

import reservationAPI from '../../http/apiReservation';
import TextArea from 'antd/es/input/TextArea';
 import dayjs from 'dayjs';

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

export default function ReservationEdit(props){

    const [form] = Form.useForm(); 
    const [confirmLoading, setConfirmLoading] = useState(false);
    
    useEffect(()=> {
        form.resetFields(); 
        if (props.reservationId && props.isModalOpen === true){
            
            form.setFieldsValue({
                count: props.reservation.count,
                customerName: props.reservation.data.customerName,
                customerEmail: props.reservation.email,
                date: dayjs(props.reservation.bookDateTime),
                time: dayjs(props.reservation.bookDateTime),
                customerPhone: props.reservation.phone,
                bookingDateTime: props.reservation.bookDateTime,
                customerComment: props.reservation.data.customerComment,
                restaurantComment: props.reservation.data.restaurantComment,
                restaurantTable: props.reservation.data.restaurantTable
            });
        }else{
            
        }
    },[props.isModalOpen]);

    const saveRerervation = async(values) => {
        setConfirmLoading(true);
        console.log('values', values);
        const reservation = {
            id: props.reservationId,
            locationId: props.locationId,
            count: values.count,
            companyId: props.companyId,
            bookingDateTime: values.bookingDateTime,
            email: values.customerEmail,
            phone: values.customerPhone,
            bookDateTime: dayjs(values.date).format('YYYY-MM-DD') + 'T' + dayjs(values.time).format('HH:mm'),
            data: {
                customerName: values.customerName,
                customerComment: values.customerComment,
                restaurantComment: values.restaurantComment,
                restaurantTable: values.restaurantTable
            }
        }

        console.log('reservation', reservation);

        await reservationAPI.save(reservation);

        setConfirmLoading(false);

        props.onSuccess();
    }

    const handleOk = () => {
        
        form
          .validateFields()
          .then(values => {
            saveRerervation(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });

       
      };

    const handleCancel = () => {
        form.resetFields(); 
        props.setModalOpen(false);
    };


    return (
        <Modal
            destroyOnClose={true}
            title={!props.reservationId ? "Create" : "Update"}
            open={props.isModalOpen}
            forceRender
            confirmLoading={confirmLoading}
            onOk={handleOk}
            onCancel={handleCancel}
        >
           
            <Form
                key={props.id}
                form={form}
                {...formItemLayout}
                name="reservation"
                id="reservationForm"
                scrollToFirstError
            >
            <Form.Item
                name="customerName"
                label={`Name`}
                rules={[
                {
                    required: true,
                    message: 'Please input name',
                },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="count"
                label={`Guests`}
                rules={[
                {
                    required: true
                },
                ]}
                >
                <InputNumber/>
            </Form.Item>
            <Form.Item
                name="date"
                label={`Date`}
                rules={[
                {
                    required: true
                },
                ]}
                >
                <DatePicker/>
            </Form.Item>
            <Form.Item
                name="time"
                label={`Time`}
                rules={[
                {
                    required: true
                },
                ]}
                >
                <TimePicker/>
            </Form.Item>
            <Form.Item
                name="customerEmail"
                label={`Email`}
                rules={[
                {
                    required: true,
                    message: 'Please input email',
                },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="customerComment"
                label={`Customer Comment`}
                rules={[
                {
                    required: false,
                    message: 'Please input email',
                },
                ]}
            >
                <TextArea/>
            </Form.Item>
            <Divider/>
            <Form.Item
                name="restaurantTable"
                label={`Table`}
                rules={[
                {
                    required: true,
                    message: 'Please select table',
                },
                ]}
            >
                <Select 
                    showSearch 
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={props.tables.map(t => { return { value: t.id, label: t.name}})}
                />
            </Form.Item>
            <Form.Item
                name="restaurantComment"
                label={`Staff Comment`}
            >
                <TextArea/>
            </Form.Item>
            
            </Form>
        </Modal>);
}