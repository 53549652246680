import React, { useState, useEffect } from 'react';
import {
    Modal,
    Form,
    Input,
    Select,
    Upload,
    Divider,
    Button,
    message,
    Descriptions,
    Tabs,
    Popconfirm,
    InputNumber,
    TimePicker,
    Switch,
    Space,
    Checkbox
  } from 'antd';
import moment from "moment";
import dayjs from 'dayjs';
import { DeleteTwoTone, EditTwoTone, FileAddTwoTone, CheckOutlined, CloseOutlined, PlusOutlined, FacebookOutlined, TwitterOutlined, InstagramOutlined, YoutubeOutlined  } from '@ant-design/icons';

import { useStorage } from 'reactfire';
import {ref, uploadBytes, getDownloadURL} from 'firebase/storage';

import { useCompany } from '../../hooks/CompanyProvider';
import locationAPI from '../../http/apiLocation';
import MenuList from './MenuMenu';
import { SketchPicker   } from 'react-color';
import { getDomainForReservation } from '../Common';

const { TabPane } = Tabs;
const { Option } = Select;

const initialState = {
    id: '',
    name: '',
    data: {
        address: {
            city: '',
            street: '',
            postal: '',
        },
        email: '',
        phone:'',
        open: ''
    }
}

export default function MenuLocationList(){

    const company = useCompany();
    const format = "HH:mm";

    const [locations, setLocations] = useState([]);
    const [activeLocation, setActiveLocation] = useState('');
    const [newRestaurantActive, setNewRestaurantActive] = useState(false);

    useEffect(() => {
        if (!company.companyId) return;
        getLocations();
    }, [company.companyId]);

    const getLocations = async() => {
        if (!company.companyId)
            return;
            
        let apiLocations = await locationAPI.getListForCompany(company.companyId);
        setLocations(apiLocations);
        setActiveLocation(apiLocations && apiLocations.length > 0 ? apiLocations[0].id : '');
    }

    const createLocation = () =>{
        setLocations([...locations, {...initialState, name: 'New Retaurant'}]);
        setNewRestaurantActive(true);
        setActiveLocation('');
    }

    const onChange = newActivePostion => {
        
        setActiveLocation(newActivePostion);
    }

    const onLocationCreate = (location) => {
        setLocations([...locations.filter(l => l.name !== 'New Retaurant'), {...location}]);
        setNewRestaurantActive(false);
        setActiveLocation(location.id);
    }

    const onLocationUpdate = (location) => {
        setLocations([...locations.map(l => {
            if (l.id === location.id)
                return {...location};
            
            return l;
        })]);
        setActiveLocation(location.id);
        setNewRestaurantActive(false);
    }

    const onDeleteLocation = async(locationId) => {
        await locationAPI.toggleDelete(locationId);
        const newLocations = locations.filter(l => l.id !== locationId);
        if (newLocations.length > 0)
            setActiveLocation(newLocations[0].id);
        setLocations([...newLocations]);
    }

    const onCancelCreate = async(locationId) => {
        await locationAPI.toggleDelete(locationId);
        setLocations([...locations.filter(l => l.id !== '')]);
        if (locations.length > 0)
            setActiveLocation(locations[0].id);
        
        setNewRestaurantActive(false);
    }
/*
    const waringNoProducts = () => {
        const link = <Link to='/products'>PRODUCTS</Link>;
        return <div>Before creating menu for your Restaurant you have to create {link}</div>
    }*/

    return (
           
          <Tabs
          hideAdd
          onChange={onChange}
          activeKey={activeLocation}
          style={{padding: "10px 30px", backgroundColor: 'white'}}
          tabBarExtraContent={{
            right: <Button style={{width:"150px"}} onClick={createLocation} type="ghost" disabled={newRestaurantActive} icon={<FileAddTwoTone />}>New Retaurant</Button>
            }}
        >
         {locations.map((pane, index) => (
            <TabPane 
                tab={pane.name} 
                key={pane.id} 
            >
                { (activeLocation === pane.id) 
                    ? <MenuLocation locationId={pane.id} createCallBack={onLocationCreate} updateCallBack={onLocationUpdate} cancelCreate={onCancelCreate} deleteLocation={onDeleteLocation}/> 
                    : <></>}
                

            </TabPane>
            ))}
            </Tabs>
    );
}

export function MenuLocation(props){
    const [form] = Form.useForm();
    const timeFormat = 'HH:mm';

    const storage = useStorage();

    const company = useCompany();
    const [location, setLocation] = useState(initialState);
    const [edit, setEdit] = useState(props.locationId ? false : true);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [lang, setLang] = useState();
    const [imageCoverUrl, setImageCoverUrl] = useState();
    const [imageCoverUrlLoading, setImageCoverUrlLoading] = useState();
    const [imageLogoUrl, setImageLogoUrl] = useState();
    //const [whiteLabelLogoUrl, setWhiteLabelLogoUrl] = useState();
    const [imageLogoUrlLoading, setImageLogoUrlLoading] = useState();
    const [imageFaviconUrlLoading, setImageFaviconUrlLoading] = useState();
    const [imageFaviconUrl, setImageFaviconUrl] = useState();
    const [themeColor, setThemeColor] = useState({hex: '#F15B55'});
    
    const weekArray = moment.weekdays();

    useEffect(() => {
        if (themeColor.hex != '#F15B55')
            locationAPI.changeThemeColorLive(props.locationId, themeColor.hex);
    }, [themeColor]);

    useEffect(() => {
        getLocation(props.locationId);
        setLang(company.language);
    }, [props.locaitonId])

    useEffect(() => {
        form.setFieldsValue({
            description: getDescription(location)
          });
    }, [lang])


    /*const FetchImage = ({ storagePath }) => {
        const storage = useStorage();
      
        const { status, data: imageURL } = useStorageDownloadURL(ref(storage, storagePath));
        if (status === 'loading') {
          return '';
        }
      
        return imageURL;
    };*/


    async function getLocation(id) {
        
        if (!id) return;
        let apiLocation = await locationAPI.get(id);
        if (!apiLocation) return;
        
        setLocation(apiLocation);
        SetFormValues(apiLocation);
    }

    function getDescription(location) {
        return location && location.data && location.data.description && location.data.description[lang] ? location.data.description[lang] : '';
    }
/*
    function getGroupHours(location){
        return location && location.data && location.data.hours && location.data.hours.length === 2 ? [ dayjs(location.data.hours[0], timeFormat), dayjs(location.data.hours[1], timeFormat) ] : [];
    }
*/
    function getOpenFrom(location){
        return location && location.data && location.data.hours && location.data.hours.length === 2 ? dayjs(location.data.hours[0], timeFormat) : '';
    }

    function getOpenTo(location){
        return location && location.data && location.data.hours && location.data.hours.length === 2 ? dayjs(location.data.hours[1], timeFormat) : '';
    }

    const SetFormValues = (location) => {
        
        form.setFieldsValue({
            name: location.name,
            city: location.data.address.city,
            postal: location.data.address.postal,
            street: location.data.address.street,
            email: location.data.email,
            www: location.data.www,
            prefix: location.data.phone.split(' ')[0],
            phone: location.data.phone.split(' ')[1],
            language: lang,
            welcomeScreenText: location.data.welcomeScreenText,
            description: getDescription(location),
            hoursFrom: getOpenFrom(location),
            hoursTo: getOpenTo(location),
            hoursAlwasyOpen: location.data.hoursAlwasyOpen || false,
            isWithImages: location.data.isWithImages || false,
            isTwoCol: location.data.isTwoCol || false,
            isFlags: location.data.isFlags || false,
            facebook: location.data.facebook || false,
            twitter: location.data.twitter,
            youtube: location.data.youtube,
            instagram: location.data.instagram,
            avgCookingTime: location.data.avgCookingTime,
            avgDishPrice: location.data.avgDishPrice,
            deliveryPrice: location.data.deliveryPrice,
            finalDiscount: location.data.finalDiscount,
            logoWidth: location.data.logoWidth || 144,
            isLogo: location.data.isLogo || true,
          });

        
        if (location.data.themeColor)
            setThemeColor({ hex: location.data.themeColor });

        setImageCoverUrl(location.data.coverImageUrl);
        setImageLogoUrl(location.data.logoImageUrl);
        setImageFaviconUrl(location.data.faviconImageUrl)
        //setWhiteLabelLogoUrl(location.data.whiteLabelLogoUrl)
    }


    const Edit = () => {
        const isEdit = edit === true ? false : true;
        setEdit(isEdit);
        
        if (isEdit === true){
            getLocation(props.locationId);
        }
    }

    const Delete = () => {
    }

    const onCancel =()=> {
        if (!location.id)
            props.cancelCreate()
        else
            setEdit(false);

        setConfirmLoading(false);
    }

    const selectedLanguage = () => {
        if (lang && lang.length > 0)
          return lang.toUpperCase();
  
        return '';
    }

    function setLocalizedProperty(key, lang, value){
        let p = location;
        
        if (!p){
          p = {};
        }
  
        if (!p['data']){
          p['data'] = {};
        }
  
        if (!p['data'][key]){
          p['data'][key] = {};
        }
        
        if (!p['data'][key][lang]){
          p['data'][key][lang] = '';
        }
  
        p['data'][key][lang] = value; 
        setLocation({
          ...p
        });
    }

    const SaveLocation = async(values) => {
        setConfirmLoading(true);
        
        const apiObj = {
            id: location.id,
            name: values.name,
            deleted: false,
            data: {
                address: {
                    city: values.city,
                    postal: values.postal,
                    street: values.street
                },
                www: values.www,
                email: values.email,
                phone: values.prefix + ' ' + values.phone,
                description: { ...location.data.description },
                welcomeScreenText: values.welcomeScreenText,
                coverImageUrl: imageCoverUrl,
                logoImageUrl: imageLogoUrl,
                faviconImageUrl: imageFaviconUrl,
                isWithImages: values.isWithImages,
                isTwoCol: values.isTwoCol,
                isFlags: values.isFlags,
                facebook: values.facebook,
                twitter: values.twitter,
                instagram: values.instagram,
                youtube: values.youtube,
                hours: [values.hoursFrom.format('HH:mm:ss'), values.hoursTo.format('HH:mm:ss')],
                hoursAlwasyOpen: values.hoursAlwasyOpen,
                avgCookingTime: values.avgCookingTime,
                avgDishPrice: values.avgDishPrice,
                deliveryPrice: values.deliveryPrice,
                finalDiscount: values.finalDiscount,
                logoWidth: values.logoWidth,
                themeColor: themeColor.hex,
                isLogo: values.isLogo
            }
        };
        
        let result = null;
        if (location.id){
            result = await locationAPI.update(location.id, apiObj);
            if (result) props.updateCallBack(result);
        }
        else {
            result = await locationAPI.create(company.companyId, apiObj);
            if (result) props.createCallBack(result);
        }
        
        setConfirmLoading(false);
        
        if (result !== false)
            setEdit(false);
    }

    const handleOk = () => {
        form
          .validateFields()
          .then(values => {
           
            SaveLocation(values);

          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });

    }

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select style={{ width: 90 }}>
            <Option value="+372">+372</Option>
            <Option value="+371">+371</Option>
            <Option value="+358">+358</Option>
            <Option value="+234">+234</Option>
            <Option value="+1">+1</Option>
          </Select>
        </Form.Item>
      );

      
      function UploadImage({file, onSuccess, type }) {

        file.status = "uploading";
        if (type === 'cover')
            setImageCoverUrlLoading(true);
        else if (type === 'logo')
            setImageLogoUrlLoading(true);
        else if (type === 'favicon')
            setImageFaviconUrlLoading(true);
        else if (type === 'white')
            {}

        const newRef = ref(storage, `${company.companyId}/location/${file.uid}_${file.name}`);
        const uploadTask = uploadBytes(newRef, file);
        uploadTask.then((e) => {
            //console.log('upload complete', e);
                getDownloadURL(newRef)
                .then((url) => {
                    //console.log(url)
                    const downloadURL = url;
                    if (type === 'cover') {
                        setImageCoverUrlLoading(false);
                        setImageCoverUrl(downloadURL);
                    }
                    else if (type === 'logo') {
                        setImageLogoUrlLoading(false);
                        setImageLogoUrl(downloadURL);
                    }
                    else if (type === 'favicon') {
                        setImageFaviconUrlLoading(false);
                        setImageFaviconUrl(downloadURL);
                    }
                    else if (type === 'white') {
                       // setWhiteLabelLogoUrl(downloadURL);
                    }
                })
           
                
            
            onSuccess("ok")
          });
        /*uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            snapshot => {
                file.percent =  ((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                //setFileProgress(file.uid, ((snapshot.bytesTransferred / snapshot.totalBytes) * 100))
                },
                error => { 
                    console.log(error.code); file.staus = "error"; 
                    setImageCoverUrlLoading(false);
                    setImageLogoUrlLoading(false);
                },
                () => {
                    
                    uploadTask.snapshot.ref
                            .getDownloadURL()
                            .then((downloadURL)=> {
                                
                                if (type === 'cover') {
                                    setImageCoverUrlLoading(false);
                                    setImageCoverUrl(downloadURL);
                                }
                                else if (type === 'logo') {
                                    setImageLogoUrlLoading(false);
                                    setImageLogoUrl(downloadURL);
                                }
                                else if (type === 'favicon') {
                                    setImageFaviconUrlLoading(false);
                                    setImageFaviconUrl(downloadURL);
                                }
                                else if (type === 'white') {
                                    setWhiteLabelLogoUrl(downloadURL);
                                }
                                    
                                
                                onSuccess("ok")
                            });
                });*/

        Promise.resolve(uploadTask);
    }

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          //message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return true;//isJpgOrPng && isLt2M;
      }



      const uploadButton = (
        <div>
          {<PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      );

    return (
        <>
        <Modal
            destroyOnClose={true}
            title={!location.id ? "Create Restaurant" : "Update Retaurant"}
            open={edit}
            confirmLoading={confirmLoading}
            width={1100}
            onOk={handleOk}
            onCancel={onCancel}
        >
            <Form
                    key={location.id}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18}}
                    form={form}
                    name="loationForm"
                    id="loationForm"
                    scrollToFirstError
                >
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <Form.Item 
                                name="name"
                                label="Name"
                                rules={[
                                    {
                                    required: true,
                                    message: 'Please input name',
                                    },
                                ]}
                                >
                                    <Input placeholder='Your retaurant name'/>
                                </Form.Item>
                                <Form.Item 
                                name="city"
                                label="City"
                                rules={[
                                    {
                                    required: true,
                                    message: 'Please input city of your restaurant',
                                    },
                                ]}
                                >
                                    <Input style={{width: "200px"}} placeholder='City'/>
                                </Form.Item>
                            
                                    <Form.Item
                                        name="street"
                                        label="Address"
                                        rules={[
                                            {
                                            required: true,
                                            message: 'Please input street of your restaurant',
                                            },
                                        ]}
                                    >
                                        <Input style={{width: "200px"}} placeholder='Street'/>
                                    </Form.Item>
                                    <Form.Item 
                                        name="postal"
                                        label="Postal"
                                        rules={[
                                            {
                                            required: true,
                                            message: 'Please input postal code of your retaurant',
                                            },
                                        ]}
                                    >
                                        <Input style={{width: "100px"}} placeholder='Postal code'/>
                                    </Form.Item>
                                    
                                
                                <Form.Item 
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                    required: true,
                                    message: 'Please input email',
                                    },
                                ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item 
                                name="www"
                                label="Webpage"
                                rules={[
                                    {
                                    required: true,
                                    message: 'Please input webpage',
                                    },
                                ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="phone"
                                    label="Phone"
                                    rules={[{ required: true, message: 'Please input phone number' }]}
                                >
                                <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item label="Working hours">
                                <Space.Compact>
                                    <Form.Item 
                                    name="hoursFrom"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select open from',
                                        },
                                        ]}
                                    >
                                        <TimePicker format={timeFormat} minuteStep={15} width={22}/>
                                    </Form.Item>
                                    &nbsp; -&nbsp; 
                                    <Form.Item 
                                        name="hoursTo"
                                        size={2}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select close from',
                                            },
                                            ]}
                                        >
                                        <TimePicker  format={timeFormat} minuteStep={15} />
                                    </Form.Item>
                                    &nbsp;&nbsp;&nbsp;
                                    <Space.Compact>
                                    <Form.Item
                                        name="hoursAlwasyOpen"
                                        valuePropName="checked"
                                        label={<small>Always open</small>}
                                    >
                                        <Switch
                                            defaultChecked={true}
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                        />
                                    </Form.Item>
                                </Space.Compact>
                                </Space.Compact>
                                
                                
                            </Form.Item>
                           
                        
                        </div>
                        <div>
                        
                            
                            <Form.Item 
                                name="avgCookingTime"
                                label="Avg cooking time"
                                >
                                <InputNumber 
                                    formatter={value => `${value}min`}
                                    parser={value => value.replace('min', '')}
                                />
                            </Form.Item>
                            <Form.Item 
                                name="avgDishPrice"
                                label="Avg dish price"
                                >
                                <InputNumber 
                                
                                />
                            </Form.Item>
                            <Form.Item 
                                name="deliveryPrice"
                                label="Delivery price"
                                >
                                <InputNumber 
                                />
                            </Form.Item>
                            <Form.Item 
                                name="finalDiscount"
                                label="Final discount %"
                                >
                                <InputNumber 
                                />
                            </Form.Item>
                            <Form.Item
                                name="isWithImages"
                                valuePropName="checked"
                                label={`With images?`}
                                rules={[
                                {
                                    required: true,
                                    message: 'Please set if menu will contain images',
                                },
                                ]}
                            >
                                <Switch
                                    defaultChecked={true}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </Form.Item>
                            <Form.Item
                                name="isTwoCol"
                                valuePropName="checked"
                                label={`Two columns?`}
                                rules={[
                                {
                                    required: true,
                                    message: 'Please set if menu two columns or one',
                                },
                                ]}
                            >
                                <Switch
                                    defaultChecked={false}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </Form.Item>
                            <Form.Item
                                name="isFlags"
                                valuePropName="checked"
                                label={`Display flags?`}
                                rules={[
                                {
                                    required: true,
                                    message: 'Please set if menu flags would be displayed on splash screen',
                                },
                                ]}
                            >
                                <Switch
                                    defaultChecked={true}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <Divider />
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                name="facebook"
                                label="Facebook"
                            >
                                <Input placeholder="Do you have facebook?" prefix={<FacebookOutlined />}/>
                            </Form.Item>
                            <Form.Item
                                name="instagram"
                                label="Instagram"
                            >
                                <Input placeholder="Do you have instagram?" prefix={<InstagramOutlined/>}/>
                            </Form.Item>
                            <Form.Item
                                name="twitter"
                                label="Twitter"
                            >
                                <Input placeholder="Do you have Twitter?" prefix={<TwitterOutlined />}/>
                            </Form.Item>
                            <Form.Item
                                name="youtube"
                                label="Youtube"
                            >
                                <Input placeholder="Do you have youtube?" prefix={<YoutubeOutlined />}/>
                            </Form.Item>
                        </div>
                        <div>
                        <Form.Item 
                                name="language"
                                label="Language"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please select language',
                                },
                                ]}
                            > 
                                <Select onChange={(value) => setLang(value)}>
                                    { company.languages && company.languages.length > 0 
                                    ? company.languages.map(o => <Select.Option value={o.key} key={o.key}>{o.name}</Select.Option>)
                                    :  ''
                                    }  
                                </Select> 
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label={`Description ${selectedLanguage()}`}
                                rules={[
                                ]}
                                >
                                <Input.TextArea style={{height:"200px"}} onChange={(e) => setLocalizedProperty('description', lang, e.target.value)}/>
                            </Form.Item>
                            <Form.Item
                                name="welcomeScreenText"
                                label={`Welcome screen`}
                                rules={[
                                ]}
                                >
                                <Input.TextArea style={{height:"200px"}} />
                            </Form.Item>
                        </div>
                    </div>
                    <Divider/>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <Form.Item
                                name="logoImage"
                                label="Logo"
                            >
                            <Upload
                                    name="logo"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                                    customRequest={(e) => UploadImage({...e, type: 'logo'})}
                                >
                                    {imageLogoUrl ? <img src={imageLogoUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                                    <Form.Item 
                                    name="logoWidth"
                                    label="Logo Size"
                                    >
                                    <InputNumber 
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="isLogo"
                                    valuePropName="checked"
                                    label={`Display logo?`}
                                    rules={[
                                    {
                                        required: false,
                                        message: 'Please set if logo would be displayed on splash screen',
                                    },
                                    ]}
                                    >
                                    <Switch
                                        defaultChecked={true}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item
                                name="favImage"
                                label="Favicon"
                            >
                            <Upload
                                    name="favicon"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                                    customRequest={(e) => UploadImage({...e, type: 'favicon'})}
                                >
                                    {imageFaviconUrl ? <img src={imageFaviconUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </Form.Item>
                            {/*<Form.Item
                                name="whiteLabelImage"
                                label="White label logo"
                            >
                                <Upload
                                    name="white"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                                    customRequest={(e) => UploadImage({...e, type: 'white'})}
                                >
                                    {whiteLabelLogoUrl ? <img src={whiteLabelLogoUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </Form.Item>*/}
                            <Form.Item
                                name="coverImage"
                                label="Cover image"
                            >
                                <Upload
                                    name="cover"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                                    customRequest={(e) => UploadImage({...e, type: 'cover'})}
                                >
                                    {imageCoverUrl ? <img src={imageCoverUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item 
                                name="themColor" 
                                label="Main color">
                                <SketchPicker  onChange={ (e) => {setThemeColor(e); } } color={themeColor.hex}/>
                            </Form.Item>
                        </div>
                    </div>
                    
                    
                </Form>
        </Modal>
        <div>
        { location.data.logoImageUrl ?
                        <img
                        className="h-16"
                        src={location.data.logoImageUrl}
                    /> : <></> }
        </div>
        <Descriptions 
            key={`location_${location.id}`}
            size="small" 
            title="Info" 
            style={{paddingTop: "10px"}}
            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
            extra={[
                    <Button key="1" style={{width: "50px"}} type="link" icon={<EditTwoTone />} onClick={Edit}></Button>,
                    <Popconfirm
                        title="Are you sure to delete this Restaurant?"
                        onConfirm={() => props.deleteLocation(props.locationId)}
                    >
                        <Button key="2" style={{width: "50px"}} danger type="link" icon={<DeleteTwoTone twoToneColor="#eb2f96"/>} onClick={Delete}></Button>
                    </Popconfirm>,  
                    <Button key="3" type='primary' onClick={() => {
                        window.open(`https://${getDomainForReservation()}/reservation/${location.id}`, '_blank')
                    }}>Reservation Link</Button>
                ]
            }>

            <Descriptions.Item key={`location_${location.id}_name`} label="Name">{`${location.name}`}</Descriptions.Item>
            <Descriptions.Item key={`location_${location.id}_address`} label="Address">{`${location.data.address.city} ${location.data.address.street} ${location.data.address.postal}`}</Descriptions.Item>
            <Descriptions.Item key={`location_${location.id}_email`} label="Email"><a href="mailto:{location.email}">{location.data.email}</a></Descriptions.Item>
            <Descriptions.Item key={`location_${location.id}_phone`} label="Phone"><a href="tel:{location.phone}">{location.data.phone}</a></Descriptions.Item>
            
        </Descriptions>

        <MenuList locationId={location.id} data={location}/>
        </>
    );
}