import React, { useState } from 'react'
import { useEffect } from 'react';

import { useCompany } from '../../hooks/CompanyProvider';

import { useMulleUser } from '../../hooks/UserProvider';
import DashboardWaitress from './DashboardWaitress';
import DashboardCompany from './DashboardCompany';


export default function Dashboard(props) {
    const user = useMulleUser();
    const company = useCompany();
    
   
    useEffect(() => {
              
    },[company.companyId]);


    return  <div>
                {
                    (user.isCompanyAdmin || user.isSuperAdmin) === true
                    ? <DashboardCompany {...props} />
                    : <DashboardWaitress />
                }
            </div>;

}