import { getAuth } from "firebase/auth";
import { message } from 'antd';

//const API_BASE_ENDPOINT = 'http://localhost:5000';
const API_BASE_ENDPOINT = 'https://api.mulle.ee';

//const API_PDF_ENDPOINT = 'http://localhost:8081';
const API_PDF_ENDPOINT = 'https://api.mulle.ee:444';

async function API_BASE_HEADER (method, body) {
  const auth = getAuth();
  let token = '';
  if (auth.currentUser) {
    token = await auth.currentUser.getIdToken();
    // do something with token - e.g. send as a header with your api requests
  }

  let header = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    method: method, // *GET, 
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', //
  };

  if (body){
    header['body'] = JSON.stringify(body)
  }

  return header;
}

  const parseCompany = company => {
    if (!company)
      return null;

      company.data = JSON.parse(company.data);
      return company;

  }
  const parseMenu = menu => {
    if (!menu)
      return null;

    menu.data = JSON.parse(menu.data); 

    if (menu.categories && menu.categories.length > 0){
        menu.categories = menu.categories.map(m => {
            if (m.products && m.products.length > 0){
                m.products = m.products.map(p => { p.data = JSON.parse(p.data); return p; });
            }
            m.data = JSON.parse(m.data); 
            return m;
        });
    }

    return menu;
}

const parseMenuLocation = menuLocation => {
  //menuLocation.data = JSON.parse(menuLocation.data);
  return menuLocation;
}

 const parseLocation = location => {
    if (!location)
      return null;

    location.data = JSON.parse(location.data); 

    if (location.menus && location.menus.length > 0){
        location.menus = location.menus.map(m => {m.data = JSON.parse(m.data); return m;});
    }

    if (location.categories && location.categories.length > 0){
        location.categories = location.categories.map(c => {
            c.data = JSON.parse(c.data);
            if (c.products){
                c.products = c.products.map(p => { p.data = JSON.parse(p.data); return p; });
            }
            return c;
        });
    }
    return location;
}


const parseCategory = category => {
    if (!category)
      return null;

    category.data = JSON.parse(category.data); 

    if (category.products && category.products.length > 0){
        category.products = category.products.map(m => {m.data = JSON.parse(m.data); return m;});
    }

    return category;
}

const parseData = item => {
    if (!item)
      return null;

    item.data = JSON.parse(item.data); 
    return item;
}

const parseProduct = product => {
    if (!product)
      return null;

    if (product.data)
      product.data = JSON.parse(product.data); 
      
    if (product.properties)
        product.properties = product.properties.map(p => { p.data = JSON.parse(p.data); return p;})

    return product;
} 

const parseOrder = order => {
  if (!order)
    return null;

  order.data = JSON.parse(order.data);
  return order;
} 

const parseUser = user => {
  if (user.companies){
    user.companies = user.companies.map(c => {c.data = JSON.parse(c.data); return c; })
  }
  return user;
}

const parse = (response, def, func) => {
  if (!response)
    return false;
   
  if (response.code && response.message) { 
    message.error(response.message);
    return false;
  }

  if (!func)
    return response;

  if (Array.isArray(response))
    return response.map(r => func(r));
  else{
    return func(response);
  } 
}

export { 
  parse,
  parseCompany,
  parseMenu,
  parseLocation,
  parseMenuLocation,
  parseCategory,
  parseProduct,
  parseUser,
  parseOrder,
  parseData,
  API_BASE_ENDPOINT,
  API_PDF_ENDPOINT,
  API_BASE_HEADER
};


