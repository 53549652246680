import { API_BASE_ENDPOINT, API_BASE_HEADER, parse } from '../http/apiConfig';
import { message } from 'antd';

class CallWaiterAPI {
  
    getMyTables = async() => {
        
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/calltowaiter/mytables`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return data;
        }catch(e){
            console.error(e);
            return [];
        }
    }

    resolveCallWaiterCall = async(eventId, firebaseId) => {
        try {
            
            await fetch(
                `${API_BASE_ENDPOINT}/api/calltowaiter/${eventId}/${firebaseId}`,
                await API_BASE_HEADER('PUT')
            );
            
        }catch(e){
            message.error(e);
            
            return false;
        }
    }

    assigenWaiterToTable = async(waiterId, tableId) => {
        try {
            
            await fetch(
                `${API_BASE_ENDPOINT}/api/calltowaiter/table/${tableId}?waiterId=${waiterId}`,
                await API_BASE_HEADER('PUT')
            );
            
        }catch(e){
            message.error(e);
            
            return false;
        }
    }


}

const callWaiterAPI = new CallWaiterAPI();
export default callWaiterAPI;