import { API_BASE_ENDPOINT, API_BASE_HEADER } from '../http/apiConfig';
import { message } from 'antd';

class PosAPI {
  
    setPayed = async(orderId, paymentType, pos = 0) => {
        try {
            await fetch(
                `${API_BASE_ENDPOINT}/api/pos/order/${orderId}/paymenttype/${paymentType}/pos/${pos}`,
                await API_BASE_HEADER('PUT')
            );
           
        }catch(e){
            console.error(e);
            message.error(e.message);
            return null;
        } 
    }

    changeTable = async(orderIds, newTableId) => {
        try {
            await fetch(
                `${API_BASE_ENDPOINT}/api/pos/changetable/${newTableId}`,
                await API_BASE_HEADER('PUT', orderIds)
            );
           
        }catch(e){
            console.error(e);
            message.error(e.message);
            return null;
        } 
    }

    setItemsPay = async(orderItemIds) => {
        try {
            await fetch(
                `${API_BASE_ENDPOINT}/api/pos/orderitems`,
                await API_BASE_HEADER('POST', orderItemIds)
            );
           
        }catch(e){
            console.error(e);
            message.error(e.message);
            return null;
        } 
    }

}

const posAPI = new PosAPI();
export default posAPI;