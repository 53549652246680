import React, { useState } from 'react'
import { 
    formatCurrencty,
    DELIVERY_TAKEAWAY,
    DELIVERY_RESTAURANT,
    PAYMENT_PAID,
    INSERTED,
    DELIVERED,
    CANCELED,
    COMPLETED,
    OREDER_STATUS_BY_ORDER,
    OREDER_STATUS_BY_NAME,
    PAYMENT_CARD,
    PAYMENT_CASH,
    PAYMENT_STRIPE,
 } from '../Common';
 //import { useCompany } from '../../hooks/CompanyProvider';
 import orderAPI from '../../http/apiOrder';
 import posAPI from '../../http/apiPos';
 import { ReactComponent as BagIcon } from '../../assets/mulle_bag_icon.svg';
 import { ReactComponent as TableIcon } from '../../assets/mulle_table_icon.svg';
 import { ReactComponent as ClockIcon } from '../../assets/mulle_clock_icon.svg';
 import { ReactComponent as HomeIcon } from '../../assets/mulle_home_icon.svg';
 import { ReactComponent as UserIcon } from '../../assets/mulle_profile_icon.svg';
 
 //import { useFirestore,  useFirestoreCollection } from 'reactfire';
 import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
 import { Select, Tag, Popconfirm, Skeleton } from 'antd';
 //import zoneAPI from '../../http/apiZone';
 //import productPropertyAPI from '../../http/apiProductProperty';
 import Timer, {getClassColor} from './OrderTimer';
 //import { useLocalStorage } from '../../hooks/LocalStorageProvider';
 import { itemStatusColor, getOrderTime } from './OrderCommon';
 //import ringer from "../../assets/media/new_order.mp3";

 //const { Option } = Select;
 //const { CheckableTag } = Tag;

export default function OrderCard ({o, types, company, hideHeader}) {
    const [changeOrderStatusLoading, setChangeOrderStatusLoading] = useState({});
    //const [loading, setLoading] = useState(true);
    const [chaningStatusOfItem, setChaningStatusOfItem] = useState({});
    const [orderAlerts, setOrderAlerts] = useState({});

    const changeOrderStatus = async(orderId, status) => {
        setChangeOrderStatusLoading({ ...changeOrderStatusLoading, [orderId]: true});
        const order = await orderAPI.changeStatus(orderId, status);
        if (!order){
            console.log(`order: ${orderId} status update failed`);
        }
        setChangeOrderStatusLoading({...changeOrderStatusLoading, [orderId]: false})
    }

    const changeItemStatus = async(orderId, itemId) => {
        const key = `${orderId}_${itemId}`;

        setChaningStatusOfItem({ ...chaningStatusOfItem, [key]: true });

        const order = await orderAPI.changeItemStatus(orderId, itemId);
        if (!order) {
            console.log(`order: ${orderId} status update failed`);
        }
        
        const newChaningStatusOfItem = { ...chaningStatusOfItem };
        delete newChaningStatusOfItem[key];
        setChaningStatusOfItem(newChaningStatusOfItem);
    }

    const getOrderNextStatus = (o) => {
    const orderStatusOrder = OREDER_STATUS_BY_NAME[o.Status];
    const nextStatus = OREDER_STATUS_BY_ORDER[orderStatusOrder + 1];

    return nextStatus;
    }

    const changePaymentStatus = async(o, paymentType) => {
        await posAPI.setPayed(o.Id, paymentType, 0);
        if (o.Status === DELIVERED) 
            changeOrderStatus(o.Id, COMPLETED);
    }

    const orderAlertColor = (id, classes) => {
        
        if (orderAlerts[id] === classes)
            return;
        
        const newOrderAlerts = {...orderAlerts, [id]: classes};
        setOrderAlerts(newOrderAlerts);
    }

    const getOrderColor = (id, datetime) => {
        let color = orderAlerts[id];
        if (!color && datetime){
            color = getClassColor((new Date().getTime() - datetime.getTime())/60000)
        }
        return color;
    }

    const productsforCard = (o) => {
        if (!o.Products)
            return <></>;
        const productsArray =  Object.values(o.Products);
        const productsGroupedByType = productsArray.reduce((map, product) => {
            if (!map[product.Type])
                map[product.Type] =[];

            map[product.Type].push(product);
            map[product.Type].sort((a, b) => (a.Id > b.Id) ? 1 : ((b.Id > a.Id) ? -1 : 0))
            return map;
        },{});
        
        let ret = [];
        Object.keys(types).forEach(key => {
            const value = productsGroupedByType[key];

            if (!value || value.length === 0)
                return;
            
            ret.push(value.map((i, index) => 
                <div key={`${o.Id}-${i.Id}`} className={`${o.Status !== INSERTED && index != 0 ? 'pt-3' : 'pt-1' } text-sm font-light`}>
                    { index === 0 ? <div className="text-base border-0 border-solid border-gray-300 border-b-2 pt-2">{ types[i.Type] ? types[i.Type].data.name : ""}</div> : <></> }
                    <div className="flex space-x-2 pt-1.5" onClick={() => changeItemStatus(o.Id, i.Id)}>
                        {  
                            o.Status !== INSERTED 
                            ? <div className={`h-5 w-5 rounded-full ${itemStatusColor(i)}`} >
                                <div className='pl-1.5 text-white font-bold'>{i.Stage}</div>
                                {
                                    chaningStatusOfItem[`${o.Id}_${i.Id}`] === true
                                    ?<svg className="animate-spin mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    : ''
                                }
                            </div> 
                            : <div className='w-5'></div> 
                        }
                        <div className={`${o.Status !== INSERTED ? 'w-28' : 'w-28' } cursor-pointer`}>{i.Count} x {i.Name[company.language]}</div>
                        <div className=''> { formatCurrencty(i.TotalGross, i.Currency) }</div>
                    </div>
                    
                    {
                        i.SelectedOption.map(op => 
                            <div>
                            { op.Options.map(opp => <div key={`${o.Id}-${opp.Name[company.language] }`} className="pl-6 text-2xxs text-yellow-900">{opp.Name[company.language] || opp.Name['et']}</div>)  }
                            </div>
                            )
                        
                    }
                </div>
            ))
        });
        
        return ret.flat();
    }

    return <div className="p-2 h-full w-60 z-50" key={o.Id}><div className="border border-solid border-gray-300 rounded-lg bg-white shadow-md">
        {
            hideHeader === true
            ? '' :  <div className={`h-7 ${''} text-center  font-bold cursor-pointer pt-0 h-12 rounded-t-md flex flex-col`} >
                <div className="flex px-3 pt-3">
                    {
                        changeOrderStatusLoading[o.Id] === true
                        ?  <div className="w-1/2 text-center">
                                <div className={`h-5 w-5 rounded-full pl-5`}>
                                    <svg className="animate-spin mr-3 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </div>
                            </div>
                        :<div className="text-green-500 w-1/2 text-center">
                            {
                                    o.Status === INSERTED
                                    ? <CheckOutlined style={{fontSize:"20px"}} onClick={(e) => changeOrderStatus(o.Id, getOrderNextStatus(o))}/>
                                    : <div onClick={(e) => changeOrderStatus(o.Id, getOrderNextStatus(o))}>{getOrderNextStatus(o)}</div>
                            }
                            
                            </div>
                    }
                    <div className="text-red text-center w-1/2">
                        <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={(e) => changeOrderStatus(o.Id, CANCELED)}>
                            <DeleteOutlined style={{fontSize:"20px"}} />
                        </Popconfirm>
                    </div>
                </div>
            </div>  
        }
    <div className="">
        {
            hideHeader === true 
            ?  <div  key={`${o.Id}-header`} className={`${getOrderColor(o.Id, getOrderTime(o))} rounded-t-lg`}>
                    <div className="flex justify-between h-8 text-center px-3 pt-1 pb-1">
                        <div className="font-bold"><Timer start={getOrderTime(o)} id={o.Id} alert={orderAlertColor} hide={false}/></div>
                    </div>
            </div>
            :  <div  key={`${o.Id}-header`} className={`${getOrderColor(o.Id, getOrderTime(o))} rounded-t-lg`}>
                    <div className="flex justify-between h-8 text-center px-3 pt-1 pb-1">
                        <div className="font-bold"><Timer start={getOrderTime(o)} id={o.Id} alert={orderAlertColor} hide={false}/></div>
                        {
                        o.Delivery.DeliveryType === DELIVERY_TAKEAWAY ?
                        <div className="h-8 flex justify-center px-3 pt-0.5">
                        <div className="flex space-x-1"><ClockIcon className="stroke-current text-black"/><div className="pb-1">{o.Delivery.DeliveryCustomTime || 'now'}</div></div>
                        </div>
                        : <></>
                        }
                    </div>
                    {
                        o.AdditionalUserData && (o.AdditionalUserData.UserName || o.AdditionalUserData.UserPhone || o.AdditionalUserData.UserSeat)
                        ?  <div className="flex space-x-1 px-3 pb-2">
                        <div className="pt-1 flex">
                            <UserIcon className="h-5 fill-current stroke-current text-black"/>
                            <div className='pl-2'>
                                { o.AdditionalUserData && o.AdditionalUserData.UserName ? <div className="flex space-x-1"><div className="">{`${o.AdditionalUserData.UserName}`}</div></div> : ''}        
                                { o.AdditionalUserData && o.AdditionalUserData.UserPhone ? <div className="flex space-x-1"><div className="">{`${o.AdditionalUserData.UserPhone}`}</div></div> : '' }                            
                                { o.AdditionalUserData && o.AdditionalUserData.UserSeat ? <div className="flex space-x-1"><div className="">{`${o.AdditionalUserData.UserSeat}`}</div></div> : '' }                            
                            </div>
                        </div>
                        </div>
                        : <></>
                    }
                  
                    <div className=" flex border-b px-1 pt-1 pb-1">
                    {
                        o.Delivery.DeliveryType === DELIVERY_RESTAURANT
                        ? <div className="flex">
                            <div className=""><TableIcon className="h-3.5 fill-current stroke-current text-black"/></div>
                            <div className="">{o.Delivery.MenuLocationName}</div>
                        </div>
                        : <div className="flex space-x-1">
                            <div className="pt-1">
                                <div className="flex space-x-1"><div className="pt-1"><BagIcon className="h-3.5 fill-current stroke-current text-black"/></div> <div className="">{`${o.Delivery.TakeawayName}`}</div></div>
                                <div className="flex space-x-1"><div className="pt-1"><UserIcon className="h-4 fill-current stroke-current text-black"/></div><div className="">{`${o.Delivery.TakeawayPhone}`}</div></div>
                                {
                                    o.Delivery.TakeawayStreet 
                                    ? <div className="flex space-x-1"><div className="pt-1"><HomeIcon className="h-4 fill-current stroke-current text-black"/></div><div className="">{`${o.Delivery.TakeawayStreet} ${o.Delivery.TakeawayBuilding} ${o.Delivery.TakeawayApt ? "-" + o.Delivery.TakeawayApt : ''}`}</div></div>
                                    : ''
                                }
                                </div>
                        </div>
                    }
                    </div>
            </div>
        }
           
    <div key={`${o.Id}-card`} className={`pl-3 ${ hideHeader === true ? 'pt-0.5' : 'pt-2' } pb-4 text-black text-left`}>
        { productsforCard(o) }
    </div>    
    <div className="flex justify-between border-t px-3 pt-1 pb-1"> 
        <div>
            <div className={`rounded-b-md text-center font-bold`}>
                {
                    o.Delivery.PaymentType !== PAYMENT_STRIPE
                    ? <Popconfirm
                            placement="bottom"
                            title={`Choose payment method`}
                            onConfirm={() => changePaymentStatus(o, PAYMENT_CARD)}
                            onCancel={() => changePaymentStatus(o, PAYMENT_CASH)}
                            okText={`${PAYMENT_CARD}`}
                            cancelText={`${PAYMENT_CASH}`}
                        >
                        <div className='cursor-pointer'>
                        {
                            o.Payment && (o.Payment[PAYMENT_PAID]) 
                            ? <div className='text-green-500'>PAID <small className='text-black pl-1'>{o.Delivery.PaymentType}</small></div>
                            : <div className='text-red'>UNPAID</div>
                        }
                        </div>
                        </Popconfirm>
                    : <div className='text-green-500'>PAID <small className='text-black pl-1'>{o.Delivery.PaymentType}</small></div>
                }
                
            </div> 
        </div>
        <div className="text-black">
            {formatCurrencty(o.TotalGross, o.Company.Currency)}
        </div>
      </div>
      {
            o.DiscountPercent && o.DiscountPercent > 0 &&  o.TotalGrossWithDiscount && o.TotalGrossWithDiscount > 0 
            ? <div className='px-3'><div className="text-black flex justify-between">
                    <div>{` -${o.DiscountPercent}%`}</div>
                    <div>{formatCurrencty(o.TotalGrossWithDiscount && o.TotalGrossWithDiscount > 0 ? o.TotalGrossWithDiscount : o.TotalGross, o.Company.Currency)}</div>
                </div>
              </div>
            : <></>
        }
      
      {
         /* o.Status !== INSERTED 
          ?
            <div className="border-t px-3 pt-3 pb-1">   
                <Popconfirm title="Are you sure you want to delete an order？" okText="Yes" cancelText="No" onConfirm={(e) => changeOrderStatus(o.Id, CANCELED)}>
                    <div className="text-red flex justify-center cursor-pointer"><DeleteOutlined style={{fontSize:"20px"}} /></div>
                </Popconfirm>
            </div>
          : ''*/
      }

      
    </div>     
      
      
  </div></div>
  }

