import React, { useState } from 'react'
import { useEffect } from 'react';

import { useCompany } from '../../hooks/CompanyProvider';

import statsAPI from '../../http/apiStatistics';


import { formatCurrencty } from '../Common';
import { Layout, Skeleton, Table, DatePicker } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { SyncOutlined } from '@ant-design/icons';


import dayjs from 'dayjs';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    ArcElement
  } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import { useMulleUser } from '../../hooks/UserProvider';
import ChartDataLabels from 'chartjs-plugin-datalabels';
  
ChartJS.register(
    ChartDataLabels,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
  );

const { RangePicker } = DatePicker;

const {  Content } = Layout;


export default function DashboardCompany(props) {
    const user = useMulleUser();

    const [loading, setLoading] = useState(true);
    
    const company = useCompany();
    const [stats, setStats] = useState();
    
    const [fromTo, setFromTo] = useState([dayjs().startOf('day'), dayjs().endOf('day')]);
    const [refresh, setRefresh] = useState(false);
   
    useEffect(() => {
        
        async function getStats() {
            setLoading(true)
            const result = await statsAPI.getStatsForDashboard(fromTo[0], fromTo[1], company.companyId);
            
            if (result !== false) {
                setStats(result);
            }
                
            setLoading(false);
            
        }

        if (company.companyId){
            getStats();
        }
              
    },[fromTo, refresh, company.companyId]);


    const onRangeChange = (e) => {
        
        setFromTo([e[0], e[1]]);
    }

    const StatisticsCard = ({title, value, suffix}) => {
       
        return <div className="p-3" key={title}>
                <div className="rounded-lg bg-white shadow-md p-3 h-20">
                    <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                    {title}
                    </p>
                    <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                    {`${value ? value : '0'} ${suffix ? suffix : ''}`}
                    </p>
                </div>
                </div>
    }

    const getRandomColor = (num) => { //generates random colours and puts them in string
        var colors = [];
        for (var i = 0; i < num; i++) {
          var letters = '0123456789ABCDEF'.split('');
          var color = '#';
          for (var x = 0; x < 6; x++) {
            color += letters[Math.floor(Math.random() * 16)];
          }
          colors.push(color);
        }
        return colors;
      }

    const columns = [
        {
          title: 'Name',
          key: 'name',
          render: (text, record) =>  record.name[company.language]
        },
        {
          title: 'Count',
          dataIndex: 'count',
          key: 'count',
        },
        {
          title: 'Gross',
          key: 'totalGross',
          render: (text, record) =>  formatCurrencty(record.totalGross, company.currency)
        },
        {
            title: 'VAT',
            key: 'totalVat',
            render: (text, record) =>  formatCurrencty(record.totalVat, company.currency)
          },
          {
            title: 'Net',
            key: 'totalVat',
            render: (text, record) =>  formatCurrencty(record.totalGross - record.totalVat, company.currency)
          },
      ];

      const columnsOrders = [
        /*{
            title: 'Location',
            key: 'restaurantName',
            render: (text, record) =>  record.restaurantName
        },*/
        {
            title: 'Time',
            dataIndex: 'created',
            key: 'created',
            render: (text, record) => <div>{`${dayjs(record.created).format('HH:mm')}`}<small>{` ${dayjs(record.created).format('DD/MM/YYYY')}`}</small></div>
        },
        {
          title: 'Table',
          key: 'name',
          render: (text, record) =>  record.location
        },
        {
            title: 'Gross',
            key: 'totalGross',
            render: (text, record) =>  formatCurrencty(record.totalGross, company.currency)
        },
        
        /*{
            title: 'Delivery',
            dataIndex: 'deliveryType',
            key: 'deliveryType',
        }*/
      ];

      const expandable = { expandedRowRender: record => <p>{record.products.map(p => <div>{p.count}x {p.name[company.language]}</div>)}</p> };
    
      const formatDate = 'DD-MM-YYYY hh:mm'

    return (
        <Layout style={{ height: "100vh", overflow: "auto" }}>    
            <Content className="site-layout" style={{ padding: '0 50px', marginTop: 5 }}>
            <PageHeader
                className="site-page-header"
                title="Dashboard"
                subTitle="watch your stats"
                extra={[
                    <div className="flex space-x-4">
                        <RangePicker 
                            className="h-9 shadow-md"
                            showTime 
                            onChange={onRangeChange}
                            defaultValue={[fromTo[0], fromTo[1]]}
                        />
                        <div className="pt-1.5">
                            <SyncOutlined style={{fontSize: "20px"}} onClick={ () => setRefresh(!refresh)}/>
                        </div>
                    </div>
                ]}
                />
                { loading 
                    ? <Skeleton active></Skeleton>  
                    : <div>
                         <>
                        {/* company admin */}
                        {
                            stats 
                            ? <div className="site-statistic-demo-card">
                                    <div className="flex justify-between">
                                        <div className="pt-3 pb-3">
                                        
                                        </div>
                                    
                                    </div>
                                    {
                                        user.pages.includes("Orders")
                                        ?  <div>
                                                <div className="grid xl:grid-cols-5 md:grid-cols-3">
                                                    <StatisticsCard title={'Revenue (Inc VAT)'} value={formatCurrencty(stats.revenueGross, company.currency)}/>
                                                    <StatisticsCard title={'Revenue (Exl VAT)'} value={formatCurrencty(stats.revenueGross - stats.vat, company.currency)} />
                                                    <StatisticsCard title={'Orders'} value={stats.ordersCount} />
                                                    <StatisticsCard title={'Average serving time'} value={stats.orderAverageServeTime} />
                                                    <StatisticsCard title={'Average Order Price'} value={formatCurrencty(stats.orderAvgPrice, company.currency)} />
                                                </div>
                                                <div>
                                                    <div  className="" >
                                                        <Bar
                                                            height={70}
                                                            data={{
                                                                labels: stats.statisticsOrderByPrices.map(o => dayjs(o.date).format('dd DD/MM HH:mm')),
                                                                datasets: [
                                                                    {
                                                                        label: company.currency,
                                                                        data: stats.statisticsOrderByPrices.map(o => o.price),
                                                                        //barThickness: 10,  // number (pixels) or 'flex'
                                                                        maxBarThickness: 100,
                                                                        backgroundColor: '#5dbf97', 
                                                                    }
                                                                ]
                                                            }}
    
                                                            options={{
                                                                scales: {
                                                                
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    
                                                </div>
                                        </div>
                                        : <></>
                                    }
                                   
                                    <div className={`grid xl:grid-cols-5 md:grid-cols-3 space-x-5 pt-10`}>
                                        <div>
                                            <div className='text-lg pt-3 pb-5 font-bold text-center'>Views by product</div>
                                           {/* <Doughnut
                                                    height={50}
                                                    data={{
                                                        labels: stats.statisticsCountByProductViews.map(o => o.productName[company.language]),
                                                        datasets: [
                                                            {
                                                                backgroundColor: getRandomColor(stats.statisticsCountByProductViews.length),
                                                                label: 'Product views',
                                                                data: stats.statisticsCountByProductViews.map(o => o.count),
                                                            }
                                                        ]
                                                    }}
                                                    options={{
                                                        plugins: {
                                                            datalabels: {
                                                                color: 'white',
                                                              },
                                                            legend: { display: false },
                                                            tooltip: {
                                                                callbacks: {
                                                                  label: (context) => {
                                                                    return context.chart.data.labels[context.dataIndex];
                                                                  }
                                                                }
                                                            }
                                                        }
                                                    }}
                                                />*/}
                                                <div>
                                                    <Table 
                                                            size="small"
                                                            rowKey="id" 
                                                            showHeader={true}
                                                            dataSource={stats.statisticsCountByProductViews.sort((a,b) => b.count - a.count)} 
                                                            columns={[
                                                            
                                                                {
                                                                title: 'Product',
                                                                key: 'product',
                                                                render: (text, record) => <p>{record.productName[company.language]}</p>
                                                                },
                                                                {
                                                                    title: 'Views count',
                                                                    key: 'count',
                                                                    align: 'right',
                                                                    render: (text, record) =>  record.count
                                                                },
                                                            ]} 
                                                            pagination={true} 
                                                        />
                                                </div>
                                        </div>
                                        <div>
                                            <div className='text-lg pt-3 pb-5 font-bold text-center'>Views by categroy</div>
                                           {/* <Doughnut
                                                    height={50}
                                                    data={{
                                                        labels: stats.statisticsCategoryViews.map(o => o.categoryName[company.language]),
                                                        datasets: [
                                                            {
                                                                backgroundColor: getRandomColor(stats.statisticsCategoryViews.length),
                                                                label: 'Category views',
                                                                data: stats.statisticsCategoryViews.map(o => o.count),
                                                            }
                                                        ]
                                                    }}
                                                    options={{
                                                        plugins: {
                                                            datalabels: {
                                                                color: 'white',
                                                                formatter: function(value, context) {
                                                                  return context.chart.data.labels[context.dataIndex];
                                                                }
                                                              },
                                                            legend: { display: false }
                                                        }
                                                    }}
                                                />*/}
                                                <div>
                                                    <Table 
                                                            size="small"
                                                            rowKey="id" 
                                                            showHeader={true}
                                                            dataSource={stats.statisticsCategoryViews.sort((a,b) => b.count - a.count)} 
                                                            columns={[
                                                            
                                                                {
                                                                title: 'Category',
                                                                key: 'category',
                                                                render: (text, record) => <p>{record.categoryName[company.language]}</p>
                                                                },
                                                                {
                                                                    title: 'Views count',
                                                                    key: 'count',
                                                                    align: 'right',
                                                                    render: (text, record) =>  record.count
                                                                },
                                                            ]} 
                                                            pagination={true} 
                                                        />
                                                </div>
                                        </div>
                                        <div>
                                            <div className='text-lg pt-3 pb-5 font-bold text-center'>Qr scans by table</div>
                                            <div>
                                                <Table 
                                                        size="small"
                                                        rowKey="id" 
                                                        showHeader={true}
                                                        dataSource={stats.statisticsScanViews.sort((a,b) => b.count - a.count)} 
                                                        columns={[
                                                        
                                                            {
                                                            title: 'Table',
                                                            key: 'category',
                                                            render: (text, record) => <p>{record.locationName}</p>
                                                            },
                                                            {
                                                                title: 'Qr scan count',
                                                                key: 'count',
                                                                align: 'right',
                                                                render: (text, record) =>  record.count
                                                            },
                                                        ]} 
                                                        pagination={true} 
                                                    />
                                            </div>
                                            {/*<Doughnut
                                                    height={50}
                                                    data={{
                                                        labels: stats.statisticsScanViews.map(o => o.locationName),
                                                        datasets: [
                                                            {
                                                                backgroundColor: getRandomColor(stats.statisticsScanViews.length),
                                                                label: 'QR Scans',
                                                                data: stats.statisticsScanViews.map(o => o.count),
                                                            }
                                                        ]
                                                    }}
                                                    options={{
                                                        plugins: {
                                                            datalabels: {
                                                                color: 'white',
    
                                                                formatter: function(value, context) {
                                                                  return context.chart.data.labels[context.dataIndex];
                                                                }
                                                              },
                                                            legend: { display: false }
                                                        }
                                                    }}
                                                />*/}
                                        </div>
                                      
                                        {/*<div>
                                            <div className='text-lg pt-3 pb-5 font-bold text-center'>Call waiter per table</div>
                                            <div>
                                                <Table 
                                                        size="small"
                                                        rowKey="id" 
                                                        showHeader={true}
                                                        dataSource={stats.statisticsCallToWaiterByTables.sort((a,b) => b.count - a.count)} 
                                                        columns={[
                                                        
                                                            {
                                                            title: 'Table',
                                                            key: 'category',
                                                            render: (text, record) => <p>{record.table}</p>
                                                            },
                                                            {
                                                                title: 'Calls count',
                                                                key: 'count',
                                                                align: 'right',
                                                                render: (text, record) =>  record.count
                                                            },
                                                        ]} 
                                                        pagination={true} 
                                                    />
                                            </div>
                                        </div>*/}
                                        {
                                             user.pages.includes("Orders")
                                             ? <div>
                                             <div className='text-lg pt-3 pb-5 font-bold text-center'>Revenue per table</div>
                                             <div>
                                                 <Table 
                                                         size="small"
                                                         rowKey="id" 
                                                         showHeader={true}
                                                         dataSource={stats.statisticsServedOrdersPerTable.sort((a,b) => b.count - a.count)} 
                                                         columns={[
                                                         
                                                             {
                                                                 title: 'Table',
                                                                 key: 'category',
                                                                 render: (text, record) => <p>{record.table}</p>
                                                             },
                                                             {
                                                                 title: 'Gross',
                                                                 align: 'right',
                                                                 key: 'totalGross',
                                                                 render: (text, record) =>  formatCurrencty(record.totalGross, company.currency)
                                                             },
                                                             {
                                                                 title: 'Orders Count',
                                                                 key: 'count',
                                                                 align: 'right',
                                                                 render: (text, record) =>  record.count
                                                             },
                                                         ]} 
                                                         pagination={true} 
                                                     />
                                             </div>
                                             
                                         </div>
                                         : <></>
                                        }
                                          {
                                             user.pages.includes("Orders") ? <div>
                                            <div className='text-lg pt-3 pb-5 font-bold text-center'>Waiter orders served</div>
                                            <div>
                                                <Table 
                                                        size="small"
                                                        rowKey="id" 
                                                        showHeader={true}
                                                        dataSource={stats.statisticsCallToWaiterOrdersByTable.sort((a,b) => b.count - a.count)} 
                                                        columns={[
                                                        
                                                            {
                                                            title: 'Waiter',
                                                            key: 'category',
                                                            render: (text, record) => <p>{record.waiter}</p>
                                                            },
                                                            {
                                                                title: 'Orders count',
                                                                key: 'count',
                                                                align: 'right',
                                                                render: (text, record) =>  record.count
                                                            },
                                                        ]} 
                                                        pagination={true} 
                                                    />
                                            </div>
                                        </div>
                                        : <></>
                                        }
                                        
                                    </div>
                                    {
                                          user.pages.includes("Orders")
                                          ? <div className="grid xl:grid-cols-2 md:grid-cols-1 space-x-5 pt-10">
                                            <div className="">
                                                <div className='text-lg pt-3 pb-5 font-bold'>Popular products</div>
                                                {
                                                    stats.productsSummary && stats.productsSummary.length > 0 
                                                    ? <Table 
                                                    size="small"
                                                    dataSource={stats.productsSummary} 
                                                    columns={columns} 
                                                    pagination={true} 
                                                    
                                                    />
                                                    : ''
                                                }
                                            </div>
                                            <div>
                                            <div className='text-lg pt-3 pb-5 font-bold'>Last orders</div>
                                                {
                                                    stats.lastOrders && stats.lastOrders.length > 0 
                                                    ? <Table 
                                                        size="small"
                                                        rowKey="id" 
                                                        expandable={expandable}
                                                        dataSource={stats.lastOrders} 
                                                        columns={columnsOrders} 
                                                        pagination={true} 
                                                        
                                                    />
                                                    : ''
                                                }
                                            </div>   
                                        </div>
                                        : <></>
                                    }
                                    
                                </div>
                            : ''
                        }
                        </>
                    </div>
                }
            </Content>
        </Layout>
    )
}