import React, { useState, useEffect } from 'react';
import tableAPI from '../../http/apiTable';
import { useCompany } from '../../hooks/CompanyProvider';
import { Rnd } from "react-rnd";
import Timer from '../Order/OrderTimer';
import { getOrderTime } from '../Order/OrderCommon';
import OrderCard from "../Order/OrderCard";
import { PlusCircleOutlined  } from '@ant-design/icons';
import posAPI from '../../http/apiPos';

export default function PosSellVisual({qrLocations, addNewOrder, selectLocation, snapshot, types, tableChange, onTableChangeComplete}) {

    const SCENE_BASE_WIDTH = 800;
    const SCENE_BASE_HEIGHT = 600;

    const company = useCompany();
    const [tableShow, setTableShow] = useState('');
    const [locations, setLocations] = useState([]);
    const [selectedId, setSelectedId] = useState(null);   
    const [editMode, setEditMode] = useState(false);

    const [size, setSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight
    });

    const companyId = company.companyId;
    const scale = size.width / SCENE_BASE_WIDTH;

    useEffect(() => {
      const checkSize = () => {
        setSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      };
  
      window.addEventListener("resize", checkSize);
      return () => window.removeEventListener("resize", checkSize);
    }, []);
 
    useEffect(() => {
        if (!company.companyId) return;
        initialData();
    },[company.companyId]);


    const initialData = async() => {
        //setLoading(true);
       
        /*let menuList = await menuAPI.getListForCompany(companyId);
        let qrCodes = menuList.map(m => m.menuLocations).flat().sort((a, b) => a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base'
          }));*/
          setLocations(qrLocations);
        //setLoading(false);
    }

    const showTableOrders = async(id) => {
      if (tableChange && tableChange.mode === true){
        await changeTable(id);
      }
      selectLocation(id);
    }
    
    const changeTable = async(id) => {
      await posAPI.changeTable(tableChange.orderIds, id);
      if (onTableChangeComplete) onTableChangeComplete();
    }

    const checkDeselect = (e) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            setSelectedId(null);
        }
    };

    const onUpdateLocation = async(id, obj) => {
      await tableAPI.update(id, obj);
    }
    
    const getOrdersByQr = (qr) => { 
      return snapshot.docs.map(d => d.data()).filter(d => d.Delivery.DeliveryQr === qr);
    }
  
    return <div className='max-h-screen' style={{overflow: 'auto'}}>
          { !tableChange.mode  && <div className='p-3 cursor-pointer' onClick={() => setEditMode(!editMode)}>{editMode ? 'Exit edit mode' : 'Edit table position'}</div> }
          { tableChange.mode === true && <div className='p-3 cursor-pointer text-xl'>Select new table</div>}

          { locations.map((l, i) =>
              <div className=''>
                <MulleTable 
                    key={i}
                    id={l.id}
                    qrCode={l}
                    editMode={editMode}
                    addNewOrder={addNewOrder}
                    scale={scale}
                    isSelected={l.id === selectedId}
                    company={company}
                    types={types}
                    onTableClick={(id) => showTableOrders(id) }
                    orders={getOrdersByQr(l.data.qr)}
                    onChange={(newAttrs) => {
                        const rects = locations.slice();
                        rects[i] = { ...rects[i], data: { ...newAttrs.data} };
                        setLocations(rects);
                        onUpdateLocation(rects[i].id, rects[i]);
                    }}
                />
                
            </div>         
            ) 
          }
    </div>;
}

function MulleTable({qrCode, addNewOrder, id, isSelected, onChange, orders, onTableClick, editMode }) {

    const shapeRef = React.useRef();

    const [orderColor, setOrderColor] = useState('bg-green-700');
    React.useEffect(() => {
    }, [isSelected]);
    
    const onOrderColorChange = (id, color) => {
      setOrderColor(color);
    } 

    return <>
          <Rnd
            ref={shapeRef}
            key={qrCode.id}
            className="z-40"
            size={{ width: `${qrCode.data.width || 50}px`, height: `${qrCode.data.height || 50}px` }}
            position={{ x: qrCode.data.x || 50, y: qrCode.data.y || 50 }}
            disableDragging={!editMode}
            enableResizing={editMode}
            onDragStop={(e, d) => {
              onChange({
                ...qrCode,
                data: {
                  ...qrCode.data,
                  x: d.x,
                  y: d.y,
                }
                
              });
            }}
            allowAnyClick={editMode}
            onResizeStop={(e, direction, ref, delta, position) => {
              onChange({
                  ...qrCode,
                  data: {
                    ...qrCode.data,
                    width: parseFloat(ref.style.width.replace('px', '')),
                    height: parseFloat(ref.style.height.replace('px', '')),
                  }
                });
            }}
        >
          <div className={`h-full ${orders && orders.length > 0 ? 'bg-yellow-300' : 'bg-green-500'} text-black relative rounded-lg`} onClick={() => { if (!editMode) onTableClick(id); } }>
            { orders && orders.length > 0 ? <div className='bg-red rounded-full h-5 w-5 absolute text-center text-white font-bold right-1 top-1'>{orders.length}</div> : '' }
            <div className="text-center border-b-2 font-bold pt-1 pb-1">{qrCode.name}</div>
            <div className='grid grid-flow-col gap-2 pt-1 px-3 pb-1'>
            {
              orders && orders.length > 0 
              ?  orders.map((o, i) => <div className='cursor-pointer w-7'><div className={`${orderColor} rounded-full text-center w-7 h-7`}>
                  <Timer start={getOrderTime(o)} hide={true} id={o.Id} alert={onOrderColorChange}/>
                </div>
                </div>)
              : ''
            }
            </div>
            <div className='bg-gray-500 rounded-full h-10 w-10 absolute text-center text-white font-bold right-1 bottom-1 cursor-pointer'><div className='' onClick={() => addNewOrder(qrCode.id)}><PlusCircleOutlined style={{ fontSize: "39px" }} /></div></div>
            
         </div>
        </Rnd>
        </>
  }