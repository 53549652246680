import { Button } from "antd";
import moment from "moment";
import { useState } from "react";
import { useCompany } from "../../hooks/CompanyProvider";
import { 
    formatCurrencty,
} from '../Common';
//import PosSellVisual from "./PosSellVisual";


export default function PosSellHeader({lastSettlement, onVisualView}) {

    const company = useCompany();
    
    
    const [closePos, setClosePos] = useState(false);

    const StatisticsCard = ({title, value, suffix}) => {
        return <div className="p-3 w-28">
                <div className="rounded-lg bg-black shadow-md p-3 h-18">
                    <p className="mb-2 text-base font-medium text-white dark:text-gray-400">
                    {title}
                    </p>
                    <p className="text-lg font-semibold text-white dark:text-gray-200">
                    {`${value ? value : '0'} ${suffix ? suffix : ''}`}
                    </p>
                </div>
                </div>
    }

    return <>
            <div className="grid grid-cols-6 px-3">
               
                {
                    lastSettlement
                    ? <>
                       <div className="pt-7 w-48">
                            <Button className="bg-green-500 h-10 w-32 rounded-md text-lg text-white font-bold hover:bg-green-600 hover:text-white hover:scale-105" onClick={() => onVisualView()}>{`< Tables`}</Button>
                        </div>

                {/*}
                        <StatisticsCard title={'Orders'} value={lastSettlement.orderCount} suffix={''}/>
                        <StatisticsCard title={'Stripe'} value={formatCurrencty(lastSettlement.appSales, company.currency)} suffix={''}/>
                        <StatisticsCard title={'Cash'} value={formatCurrencty(lastSettlement.cashSales, company.currency)} suffix={''}/>
                        <StatisticsCard title={'Cards'} value={formatCurrencty(lastSettlement.cardSales, company.currency)} suffix={''}/>
                */}
                    </>
                    : <></>

                }
                 {/*
                 <div className='pt-5 cursor-pointer text-center hover:scale-105' onClick={() => setClosePos(true)}>
                    <div>
                        {moment(lastSettlement.startTime).format('DD-MMM HH:MM')}
                    </div>
                    <div className='text-lg text-green-500 font-bold pt-1'>OPEN</div>
                    <div className='text-xs pt-2'>press to close</div>
                </div>
                 */}
            </div>
            
    </>;
}