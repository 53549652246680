import React, { useState, useEffect } from 'react';
import {
    Modal,
    Form,
    Input,
    Button,
    message,
    Tabs,
    Descriptions,
    Popconfirm,
  } from 'antd';
  import { DeleteTwoTone, EditTwoTone, FileAddTwoTone } from '@ant-design/icons';
import MenuConfiguration from './MenuConfiguration';
import menuAPI from '../../http/apiMenu';


const { TabPane } = Tabs;

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

export default function MenuMenuList(props){
    const [menus, setMenus] = useState([]);
    const [activeMenu, setActiveMenu] = useState(1);
    const [locationId, setLocationId] = useState(props.locationId);
    const [edit, setEdit] = useState(false);


    useEffect(() => {
        setLocationId(props.locationId);
    }, [props.locationId])

    useEffect(() => {
        getMenuList(locationId);
    },[locationId])

    const getMenuList = async(locationId) => {
        const result = await menuAPI.getListForLocation(locationId);
        if (result){
            setMenus(result);
            if (result.length > 0)
                setActiveMenu(result[0].id);
        }
    }

    const createMenu = () =>{
        setMenus([...menus, { id: '', name: 'New Menu'}]);
        setEdit(true);
        setActiveMenu('');
    }

    const onCancelCreate = () => {
        
        setMenus([...menus.filter(m => m.id !== '')]);
        if (menus.length > 0)
            setActiveMenu(menus[0].id);
        
        setEdit(false);
    }


    const onChange = activeMenu => {
        setActiveMenu(activeMenu);
      };


    const onMenuSave = (menu) => {
        
        if (menus.find(m => m.id === menu.id)){
            setMenus([...menus.map(m => {
                if (m.id === menu.id)
                    return {...menu};
                
                return m;
            })]);
        }else{
            setMenus([...menus.filter(m => m.name !== 'New Menu'), {...menu}])
        }
        
        setEdit(false);
        setActiveMenu(menu.id);
    }

    const onMenuDelete = async(menuId) => {
        const newMenuList = menus.filter(m => m.id !== menuId);
        if (newMenuList.length > 0)
            setActiveMenu(menus[0].id);

        setMenus([...newMenuList]);
    }

    return (
        <div >
        
        <Descriptions 
            title=""
            style={{paddingTop: "70px"}}
            wrapperCol={{ span: 24}}
            column={1} 
        >
            <Descriptions.Item contentStyle={{width:"100%"}}>
            
            <Tabs
                activeKey={activeMenu}
                onChange={onChange}
                
                tabBarExtraContent={{
                    right: <Button type="ghost" style={{width:"150px"}} icon={<FileAddTwoTone />} onClick={() => createMenu() }>New Menu</Button>
                    }}
            >
                {
                 menus.map((pane, index) => (
                    <TabPane 
                        tab={pane.name} 
                        key={pane.id} 
                    >
                        {
                            activeMenu === pane.id 
                            ? <div>
                            <MenuMenu locationId={locationId} menuId={pane.id} edit={edit} setEdit={setEdit} data={pane} onCancelCreate={onCancelCreate} onMenuSave={onMenuSave} onMenuDelete={onMenuDelete} />
                            
                            { pane.id ? <MenuConfiguration key={pane.id} data={pane} /> : '' }
                            </div>
                            : <></>
                        }
                    </TabPane>
                ))
                }
            </Tabs>
            
            </Descriptions.Item>
        </Descriptions>
        </div>

    )
        
}

export function MenuMenu(props){
    const [form] = Form.useForm(); 
    const [confirmLoading, setConfirmLoading] = useState(false);
    
    useEffect(()=> {
       
            form.setFieldsValue({
                name: props.data.name
            });
        
    },[]);

    const saveMenu = async(values) => {
        setConfirmLoading(true);
        
        let apiObj = {
            locationId: props.locationId,
            name: values.name,
            data: {},
            deleted: false
        };
        
        let result;
        if (!props.menuId){
            result = await menuAPI.create(apiObj);
        }else{
            result = await menuAPI.update(props.menuId, apiObj);
        }
        
        setConfirmLoading(false);
        if (result) {
            form.resetFields(); 
            props.onMenuSave(result);
        }
    }

    const editMenu = () => {
        props.setEdit(true);
    }

    const deleteMenu = async(menuId) => {
        await menuAPI.toggleDelete(menuId);
        props.onMenuDelete(menuId)
    }

    const publishMenu = async () => {
        await menuAPI.publish(props.data.id);
    }

    const handleOk = () => {
        
        form
          .validateFields()
          .then(values => {
           
            saveMenu(values);

          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });

       
      };

    const handleCancel = () => {
        props.setEdit(false);
        form.resetFields(); 
        if (!props.menuId)
            props.onCancelCreate();
    };

    return (
        <>
        <Modal
            destroyOnClose={true}
            title={!props.id ? "Create" : "Update"}
            open={props.edit}
            forceRender
            confirmLoading={confirmLoading}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Form
                key={props.id}
                form={form}
                 {...formItemLayout}
                name="menu"
                id="menuForm"
                scrollToFirstError
            >
            <Form.Item
                name="name"
                label={`Name`}
                rules={[
                {
                    required: true,
                    message: 'Please input name',
                },
                ]}
            >
                <Input/>
            </Form.Item>
            </Form>

        </Modal>
        <Descriptions 
            
            style={{paddingTop: "0px"}}
            wrapperCol={{ span: 24}}
            column={1} 
            extra={[ 
                
                <Button style={{width: "50px"}} onClick={editMenu} type="link" icon={<EditTwoTone />} key="group_button"></Button>,
                <Popconfirm
                    title="Are you sure to delete this menu?"
                    onConfirm={() => deleteMenu(props.data.id)}
                >
                     <Button type="link" style={{width: "50px"}} danger icon={<DeleteTwoTone twoToneColor="#eb2f96"/>}></Button>  
                </Popconfirm>,
                <Popconfirm
                    title="All the changes would be available to public. Are you want to publish menu?"
                    onConfirm={(e, api) => {
                        publishMenu().then(() => {
                            message.success('Menu successfully published!')
                            api.close();
                        });
                      }}

                    
                >
                    <Button type="primary" style={{width: "150px"}}>Publish</Button>
                </Popconfirm>
            ]}
        >
           
        </Descriptions>
        </>
    )
}