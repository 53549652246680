
import React, { useRef, useState, useEffect } from 'react';
import unitAPI from '../../http/apiUnit';
import { v4 as uuidv4 } from 'uuid';
import warehouseAPI from '../../http/apiWarehouse';
//import Ingridient from './Ingridient';
import { useCompany } from '../../hooks/CompanyProvider';
import { 
    INGRIDIENT_CUSTOM,
    INGRIDIENT_STOCK,
    formatCurrencty,
  } from '../Common';
import { DeleteTwoTone, EditTwoTone, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {
    Modal,
    Form,
    Input,
    Button,
    message,
    Select,
    Table,
    Descriptions,
    Divider,
    Space,
    Popconfirm,
    InputNumber,
  } from 'antd';

const IngridientIndex = () => {
    
    const company = useCompany();
    const [units, setUnits] = useState([]);

    const [supply, setSupply] = useState([]);
    const [customSupply, setCustomSupply] = useState([]);
    

    useEffect(() => {
      getUnits();
    },[]);

    useEffect(() => {
        if (!company.companyId) return;
        getSupply();
        getCustomSupply();
    }, [company.companyId]);

    const getUnits = async() => {
      let apiUnits = await unitAPI.getList();
      setUnits(apiUnits);
    }

    const onSearch = (text) => {

    }

    const getSupply = async() => {
      if (!company.companyId) return;
      let supplyItems = await warehouseAPI.getList(company.companyId);
      setSupply(supplyItems);
    }

    const getCustomSupply = async() => {
      if (!company.companyId) return;  
      let supplyItems = await warehouseAPI.getCustomList(company.companyId);
      setCustomSupply(supplyItems);
    }

    return <>
        <div className='px-9'>
            <div className='pt-9'>
                <div className='font-bold text-xl'>Ingredients</div>
            </div>
            <div className='pt-3 pb-3'>
                <input type="search" className='text-black w-full px-3 h-10 rounded-md border-0 text-lg focus: outline-none' placeholder="input search text" onChange={(e) => onSearch(e.target.value)}/>
            </div>
            <CustomIngridients onUpdate={() => getCustomSupply()} ingridients={supply} customIngridients={customSupply} units={units}/>
            <Divider className="pt-5 pb-5"></Divider>
            <WarehouseIngridients onUpdate={() => getSupply()} ingridients={supply} units={units}/>
        </div>
    </>
}

const CustomIngridientModal = ({ingridientCustom, ingridients, units, isModalOpen, setModalOpen, onUpdate}) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const [form] = Form.useForm(); 
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [ingridientsInCustom, setIngridientsInCustom] = useState([]);
  const company = useCompany();

  useEffect(()=> {
      form.resetFields(); 
      if (ingridientCustom.id && isModalOpen){
          
          form.setFieldsValue({
              name: ingridientCustom.name,
          });

          setFormProductIngridients(ingridientCustom.recipe);
      }
  },[isModalOpen]);

  const save = async(values) => {
    setConfirmLoading(true);
    //console.log(values);
    let apiObj = {
        id: ingridientCustom.id,
        name: values.name,
        recipe: values.customIngridientIngridiets.map(x => { x.unit = JSON.parse(x.unit); x.unitNet = JSON.parse(x.unit_net); x.amountNet = x.amount_net; return x;})
    };

    let result = await warehouseAPI.addCustom(company.companyId, apiObj);
    
    setConfirmLoading(false);
    onUpdate();

    if (result) {
        form.resetFields(); 
        setModalOpen(false);
    }
  }

  const setFormProductIngridients = (ingridients) => {
    let fields = form.getFieldsValue();
    fields.customIngridientIngridiets = (ingridients || []).map(x => {
      return {
        id: x.id,
        itemWareHouseId: x.itemWareHouseId,
        amount: x.amount,
        unit: JSON.stringify(x.unit),
        amount_net: x.amountNet,
        unit_net: JSON.stringify(x.unitNet),
      };
    });

    const { customIngridientIngridiets } = fields;
    form.setFieldsValue({ customIngridientIngridiets })  
  }


  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        save(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

const handleCancel = () => {
    form.resetFields(); 
    setModalOpen(false);
};


  return <Modal
    destroyOnClose={true}
    title={(ingridientCustom || {}).id ? "Update" : "Create"}
    open={isModalOpen}
    forceRender
    width={750}
    confirmLoading={confirmLoading}
    onOk={handleOk}
    onCancel={handleCancel}
  >
    <Form
            key={ingridientCustom.id || uuidv4()}
            form={form}
            {...formItemLayout}
            name="customItem"
            id="customItem"
            scrollToFirstError
        >
        <Form.Item
            name="name"
            label={`Name`}
            rules={[
            {
                required: true,
                message: 'Please input name',
            },
            ]}
        >
            <Input/>
        </Form.Item>
        <Form.Item label={'Receipe'}>
            <Form.List name="customIngridientIngridiets">

                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map((field) => (
                          <Space
                            key={field.key}
                            style={{ display: "flex" }}
                            align="start"
                          >
                            <Form.Item name={[field.key, "id"]} hidden={true} />
                            <Form.Item
                              {...field}
                              name={[field.name, "itemWareHouseId"]}
                              fieldKey={[field.fieldKey, "itemWareHouseId"]}
                              rules={[
                                { required: true, message: "Missing ingridient" }
                              ]}
                            >
                              <Select
                                    showSearch
                                    style={{ width: 150 }}
                                    placeholder="Select ingridient"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    dropdownMatchSelectWidth={false}
                                    dropdownRender={menu => (
                                      <>
                                        <Divider style={{ margin: '8px 0' }} />
                                        {menu}
                                      </>
                                    )}
                                    options={
                                      ingridients.map(i => { 
                                        return {
                                          value: i.id, label: `${i.name}` 
                                        }
                                      })
                                    }
                                  />
                                
                            </Form.Item>
                            <Form.Item 
                                name={[field.name, "amount"]}
                                fieldKey={[field.fieldKey, "amount"]}
                                rules={[
                                { required: true, message: "value" }
                              ]}
                            >
                              <InputNumber 
                                min={0}
                                style={{'width': 80}}
                                placeholder={`Gross`}
                              />
                            </Form.Item>
                            <Form.Item 
                                name={[field.name, "unit"]}
                                fieldKey={[field.fieldKey, "unit"]}
                                rules={[
                                { required: true, message: "Missing unit" }
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder={'Select unit'}
                                bordered={false}
                                dropdownMatchSelectWidth={false}
                                style={{ width: 120 }}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                options={
                                  Object.keys(units).map(type => 
                                    { return {
                                      label: type,
                                      options: units[type].map(u => { return { value: JSON.stringify(u), label: `${u.abbreviation} (${u.name})`} }),
                                    }
                                  })
                                }
                              />
                            </Form.Item>
                            <Form.Item 
                                name={[field.name, "amount_net"]}
                                fieldKey={[field.fieldKey, "amount_net"]}
                                rules={[
                                { required: true, message: "value" }
                              ]}
                            >
                              <InputNumber 
                                min={0}
                                style={{'width': 80}}
                                placeholder={`Net`}
                              />
                            </Form.Item>
                            <Form.Item 
                                name={[field.name, "unit_net"]}
                                fieldKey={[field.fieldKey, "unit_net"]}
                                rules={[
                                { required: true, message: "Missing unit" }
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder={'Unit Net'}
                                dropdownMatchSelectWidth={false}
                                style={{ width: 120 }}
                                bordered={false}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                options={
                                  Object.keys(units).filter(x => x !== 'Quantity').map(type => 
                                    { return {
                                      label: type,
                                      options: units[type].map(u => { return { value: JSON.stringify(u), label: `${u.abbreviation} (${u.name})`} }),
                                    }
                                  })
                                }
                              />
                            </Form.Item>
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                                //console.log(field);
                              }}
                            />
                          </Space>
                        ))}
                          
                        <Button
                          type="link"
                          style={{width: "100px"}}
                          onClick={() => {
                            add();
                          }}
                          block
                        >
                          <PlusOutlined /> Add new
                        </Button>
                      </div>
                    );
                  }}
            </Form.List>
        </Form.Item>
        
    </Form>
  </Modal>;
}


const CustomIngridients = ({ingridients, customIngridients, units, onUpdate}) => {

    const [ingridientModalOpen, setIngridientModalOpen] = useState(false);
    const [ingridientSelected, setIngridientSelected] = useState({});
    const company = useCompany();

    const columns = [
      {
          title: "Name",
          render: (record) => (
            <div className="">
              {record.name}
            </div>
          )
      },
      {
        title: "Price",
        render: (record) => (
          <div className="">
            { formatCurrencty(record.recipePrice, company.currency) }
          </div>
        )
      },
      {
        title: "Weight",
        render: (record) => (
          <div className="">
            { `${record.recipeAmount} ${record.recipeUnit}` }
          </div>
        )
      },
      {
          title: "Ingredients",
          render: (record) => (
            <div className="">
              <a onClick={() => showIngridients(record.id)}>{(record.recipe || {}).length}</a>
            </div>
          )
      },
      {
          title: 'Action',
          key: 'action',
          width: 100,
          align: 'right',
          
          render: (text, record) => (
              <Space size="large">
                  <a onClick={() => onEditCustomIngridient(record)}><EditTwoTone /></a>
                  <Popconfirm title="Are you sure？This type could be used on products you have" okText="Yes" cancelText="No" onConfirm={(e) => this.onDelete(record.id) }>
                      <a><DeleteTwoTone twoToneColor="#eb2f96" /></a>
                  </Popconfirm>
              </Space>
          )
        },
    ];

    const onEditCustomIngridient = (ingridient) => {
      setIngridientSelected(ingridient);
      setIngridientModalOpen(true);
    };
  
    const onDeleteCustomIngridient = (ingridientId) => {
  
    }

    const showIngridients = (id) => {

    }

    return <>
        <div className='flex justify-end'>
          <Button type='primary' onClick={() => setIngridientModalOpen(true)}>Add New Prepared Item</Button>
        </div>
        <CustomIngridientModal units={units} onUpdate={onUpdate} isModalOpen={ingridientModalOpen} setModalOpen={setIngridientModalOpen} ingridients={ingridients} ingridientCustom={ingridientSelected} />
        <Table size="small" 
            columns={columns} 
            dataSource={customIngridients} 
            pagination={false} 
            loading={false} 
            rowKey={record => record.id} 
             />
    </>
}

const WarehouseIngridientModal = ({ingridient,  units, isModalOpen, setModalOpen, onUpdate}) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const [form] = Form.useForm(); 
  const [confirmLoading, setConfirmLoading] = useState(false);
  const company = useCompany();

  useEffect(()=> {
      form.resetFields(); 
      if (ingridient.id && isModalOpen){
          
          form.setFieldsValue({
              name: ingridient.name,
              lastPrice: ingridient.lastPrice,
              quantityStock: ingridient.quantityStock,
              unit: JSON.stringify(ingridient.unit)
          });
      }
  },[isModalOpen]);

  const save = async(values) => {
    setConfirmLoading(true);
    
    let apiObj = {
        id: ingridient.id,
        name: values.name,
        quantityStock: values.quantityStock,
        lastPrice: values.lastPrice,
        unit: JSON.parse(values.unit)
    };

    let result = await warehouseAPI.add(company.companyId, apiObj);
    
    setConfirmLoading(false);
    onUpdate();

    if (result) {
        setConfirmLoading(false);
        form.resetFields(); 
        setModalOpen(false);
    }
}

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        save(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

const handleCancel = () => {
    form.resetFields(); 
    setModalOpen(false);
};


  return <Modal
    destroyOnClose={true}
    title={(ingridient || {}).id ? "Update" : "Create"}
    open={isModalOpen}
    forceRender
    confirmLoading={confirmLoading}
    onOk={handleOk}
    onCancel={handleCancel}
  >
    <Form
            key={ingridient.id || uuidv4()}
            form={form}
            {...formItemLayout}
            name="warehoseItem"
            id="warehoseItem"
            scrollToFirstError
        >
        <Form.Item
            name="name"
            label={`Name`}
            rules={[
            {
                required: true,
                message: 'Please input name',
            },
            ]}
        >
            <Input/>
        </Form.Item>
        <Form.Item
            name="lastPrice"
            label={`Price Net`}
            rules={[
            {
                required: true,
                message: 'Please input name',
            },
            ]}
        >
            <InputNumber min={0}/>
        </Form.Item>
        <Form.Item
            name="quantityStock"
            label={`Stock`}
            rules={[
            {
                required: true,
                message: 'Please input stock',
            },
            ]}
        >
            <InputNumber min={0} style={{'width': 70}}/>
        </Form.Item>
        <Form.Item 
          name={"unit"}
          label={"Unit"}
          rules={[
          { required: true, message: "Missing unit" }
        ]}
        >
          <Select
            showSearch
            placeholder={'Unit gross'}
            bordered={false}
            dropdownMatchSelectWidth={false}
            
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
            options={
              Object.keys(units || {}).map(type => 
                { return {
                  label: type,
                  options: units[type].map(u => { return { value: JSON.stringify(u), label: `${u.abbreviation} (${u.name})`} }),
                }
              })
            }
          />
      </Form.Item>
    </Form>
  </Modal>;
}

const WarehouseIngridients = ({ingridients, units, onUpdate}) => {

  const [ingridientModalOpen, setIngridientModalOpen] = useState(false);
  const [ingridient, setIngridient] = useState({});
  const company = useCompany();

  const onEditWarehouseIngridient = (ingridient) => {
    setIngridient(ingridient);
    setIngridientModalOpen(true);
  };

  const onDeleteWarehouseIngridient = (ingridientId) => {

  }

  const columns = [
      {
          title: "Name",
          render: (record) => (
            <div className="">
              {record.name}
            </div>
          )
      },
      {
        title: "Price",
        render: (record) => (
          <div className="">
            { formatCurrencty(record.lastPrice, company.currency) }
          </div>
        )
    },
      {
          title: "Stock",
          render: (record) => (
            <div className="">
            <strong className='text-md'>{record.quantityStock}</strong>  { `${record.unit.abbreviation}` }
            </div>
          )
      },
      {
          title: 'Action',
          key: 'action',
          width: 100,
          align: 'right',
          
          render: (text, record) => (
              <Space size="large">
                  <a onClick={() => onEditWarehouseIngridient(record)}><EditTwoTone /></a>
                  <Popconfirm title="Are you sure？This type could be used on products you have" okText="Yes" cancelText="No" onConfirm={(e) => onDeleteWarehouseIngridient(record.id) }>
                      <a><DeleteTwoTone twoToneColor="#eb2f96" /></a>
                  </Popconfirm>
              </Space>
          )
        },
  ];

  return <>
        <div className='flex justify-end'>
          <Button type='primary' onClick={() => setIngridientModalOpen(true)}>Add New Warehouse Item</Button>
        </div>
        <WarehouseIngridientModal units={units} onUpdate={onUpdate} isModalOpen={ingridientModalOpen} setModalOpen={setIngridientModalOpen} ingridient={ingridient} />
        <Table size="small" 
                columns={columns} 
                dataSource={ingridients} 
                pagination={false} 
                loading={false} 
                rowKey={record => record.id} 
                />
    </>
}

export default IngridientIndex;