import { API_BASE_ENDPOINT, API_BASE_HEADER, parse } from '../http/apiConfig';
import { message } from 'antd';

class UnitAPI {
  
    getList = async() => {
      
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/unit`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return parse(data);
        }catch(e) {
            message.error(e);
            return false;
        }
    }
}

const unitAPI = new UnitAPI();
export default unitAPI;