import { API_BASE_ENDPOINT, API_BASE_HEADER } from '../http/apiConfig';
import { message } from 'antd';

class ReservatonAPI {
  
    get = async(companyId, locationId) => {
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/reservation/${companyId}/list`,
                await API_BASE_HEADER('GET')
            );

            const data = await response.json();
            return data;
           
        }catch(e){
            console.error(e);
            message.error(e.message);
            return null;
        } 
    }

    save = async(reservation) => {
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/reservation?timezoneOffset=${new Date().getTimezoneOffset()}`,
                await API_BASE_HEADER('POST', reservation)
            );
            let data = await response.json();
            return data;
        }catch(e){
            message.error(e.message);
            console.error(e);
        } 
    }

    confirm = async(reservationId) => {
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/reservation/${reservationId}/confirm`,
                await API_BASE_HEADER('PUT')
            );
            let data = await response.json();
            return data;
        }catch(e){
            console.error(e);
            return null;
        }   
    }

    delete = async(reservationId) => {
        try {
            await fetch(
                `${API_BASE_ENDPOINT}/api/reservation/${reservationId}`,
                await API_BASE_HEADER('DELETE')
            );
        }catch(e){
            console.error(e);
            return null;
        }   
    }
}

const reservationAPI = new ReservatonAPI();
export default reservationAPI;