
import { Result, Button } from 'antd';
import { Link } from "react-router-dom";

export default function SettingsStripeReturn() {
    return <div className="m-auto">
        <Result
            status="success"
            title="Stripe has been configured!"
            extra={<Button type="primary"><Link to="/settings">Next</Link></Button>}
        />

    </div>
}