import React, { createContext, useContext, useReducer  } from "react";

const UserMulleContext = createContext();
const initialState = { user: {} , pages: [] };

const reducer = (state, action) => {
    switch(action.type) {
      case "set_user":
        return {
            user: action.user,
            pages: state.pages,
        }
      case "set_pages":
        return {
            user: state.user,
            pages: [...action.pages],
        }
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  }

const UserMullePovider = ({ children }) => {
    
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <UserMulleContext.Provider value={{state, dispatch}}>
            {children}
        </UserMulleContext.Provider>
      );
};

const useMulleUser  = () => {
    const {state, dispatch} = useContext(UserMulleContext)

    return {
        user: state.user,
        role: state.user.role,
        pages: state.pages,
        isCompanyAdmin: state.user.role === 'ORDERS' || state.user.role === 'COMPANY_ADMIN' || state.user.role === 'ADMIN',
        isSuperAdmin: state.user.role === 'SUPER_ADMIN',
        isWaitress: state.user.role === 'WAITER',
        isFeature: (feature) => state.pages.includes(feature),
        setUser: (newUser) => dispatch({type: "set_user", user: newUser}),
        setPages: (newPages) => dispatch({type: "set_pages", pages: newPages}),
      }
  }

export default UserMullePovider;
export { useMulleUser };