
import React, { useState, useEffect } from 'react'
import '@firebase/auth';
import '@firebase/storage';
import {
  Modal,
  Form,
  Input,
  Switch,
  Divider,
  InputNumber, 
  Select,
  Timeline 
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { nanoid } from 'nanoid'
import { 
  formatCurrencty,
  DELIVERY_RESTAURANT,
  PAYMENT_POS,
  PAYMENT_STRIPE
} from '../Common';
import moment from "moment";
import orderAPI from '../../http/apiOrder';

const { Option } = Select;


export default function OrderTableEdit(props){

   
    const [confirmLoading, setConfirmLoading] = useState(false);
    const orderId = props.orderId;
    const order = props.order;
    const types = props.types;
    const company = props.company;
    const statuses = props.statuses;

    useEffect(()=> {
       
    },[props.isModalOpen]);

    const changeStatus = async(status) => {
        setConfirmLoading(true);
       
        const order = await orderAPI.changeStatus(orderId, status);
        if (!order){
            console.log(`order: ${orderId} status update failed`);
        }
        
        setConfirmLoading(false);
    }

    const productsforCard = (o) => {
      
      if (!o || !o.data.clientOrder.products)
          return <></>;

      const productsArray =  Object.values(o.data.clientOrder.products);
      const productsGroupedByType = productsArray.reduce((map, product) => {
          if (!map[product.type])
              map[product.type] =[];

          map[product.type].push(product);
          map[product.type].sort((a, b) => (a.Id > b.Id) ? 1 : ((b.Id > a.Id) ? -1 : 0))
          return map;
      },{});

      let ret = [];
      Object.keys(types).forEach(key => {
          const value = productsGroupedByType[key];
          if (!value || value.length === 0)
              return;

          ret.push(value.map((i, index) => 
              <div key={`${o.id}-${i.id}`} className={`pt-3`}>
                  
                  <div className="flex justify-between pt-1.5" >
                      <div className={``}>{i.count} x {i.name[company.language]}</div>
                      <div className=''> { formatCurrencty(i.totalGross, i.currency) }</div>
                  </div>
                  {
                      i.selectedOption.map(op => 
                          <div>
                          { op.options.map(opp => <div key={`${o.Id}-${opp.name[company.language] }`} className="pl-6 text-2xxs text-yellow-900">{opp.name[company.language] || opp.name['et']}</div>)  }
                          </div>
                          )
                  }
              </div>
          ))
      });
      
      return ret.flat();
  }

    const handleOk = () => {
        
        props.setModalOpen(false);
    };

    const handleCancel = () => {
       
        props.setModalOpen(false);
    };

    return (
        <Modal
            destroyOnClose={true}
            title={`Order: ${order && order.shortId}`}
            open={props.isModalOpen}
            forceRender
            confirmLoading={confirmLoading}
            onOk={handleOk}
            onCancel={handleCancel}
        >
          
          { order ? 
           <div key={`${orderId}-card`} className="pl-3 pt-2 pb-4">
                
                <div className=''>{ productsforCard(order) }</div>
                <div className='pt-5 '>
                  <div className="font-bold border-t-2 pt-1 flex justify-between">
                    <div>Total:</div>
                    <div>{order.totalGross}</div>
                  </div>
                </div>
                <div className='pt-7 flex justify-between'>
                  <div>Payment:</div>
                  <div>
                  {
                        order.data.clientOrder.delivery.paymentType === PAYMENT_POS
                        ? PAYMENT_POS
                        : PAYMENT_STRIPE
                    }
                  </div>
                </div>
                <div className='pt-7 flex justify-between'>
                  <div>Location:</div>
                  <div>
                      { order.data.clientOrder.delivery.deliveryType === DELIVERY_RESTAURANT
                        ? <div>{order.data.companyData.menuLocationName}</div>
                        : <div className="flex pl-3"></div>
                      }
                  </div>
                </div>
                <div className='pt-7 flex justify-between'>
                  <div>Change status:</div>
                  <div className='w-1/2'>
                    <Select
                    value={order.status.status}
                      initialValue={order.status.status}
                      onSelect={ (e) => changeStatus(e)}
                      style={{ width: '100%' }}
                    >
                      { statuses.map(a => <Option key={a} value={a}>{a}</Option>) }
                    </Select>
                  </div>
                </div>
                <div className='pt-7'>
                  <div className='pb-7 text-center font-bold'>Status change history</div>
                  <Timeline mode={`left`}>
                    {
                      order.statuses.map(s => <Timeline.Item label={moment.utc(s.created).format('DD-MM-YYYY HH:mm')}>{s.status}</Timeline.Item>)
                    }
                  </Timeline>
                </div>
            </div>
            : ''
          }
          

        </Modal>
    )
}