
import React from 'react';
import { Space, Table, Select } from 'antd';
import callWaiterAPI from '../../http/apiCallWaiter';
import zoneAPI from '../../http/apiZone';

export default function WaiterTableList({tableList, waiterList, zoneList, refresh}){



    return <>
        <div>
            <Table size="small" 
                columns={[
                    {
                        title: 'Table',
                        dataIndex: 'table',
                        key: 'table',
                        render: (text, record) => <>{record.name}</>
                    },
                    {
                        title: 'Waiter',
                        key: 'waiter',
                        render: (text, record) => {
                            console.log(record) 
                            
                        return <Space size="middle">
                                <Select
                                    showSearch
                                    defaultValue={record?.user?.id}
                                    onChange={(e) => callWaiterAPI.assigenWaiterToTable(e, record.id).then(() => refresh()) }
                                    style={{width: 200}}
                                    placeholder="select waiter"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={[{ label: '- Unassign -', value: ''}].concat(waiterList.map(l => { return { label: l.name, value: l.id} }))}
                                />
                            </Space>
                        },
                    },
                    {
                        title: 'Zone',
                        key: 'zone',
                        render: (text, record) => {
                            console.log(record) 
                            
                        return <Space size="middle">
                                <Select
                                    showSearch
                                    defaultValue={record?.menuZone?.id}
                                    onChange={(e) => zoneAPI.assign(e, record.id).then(() => refresh()) }
                                    style={{width: 200}}
                                    placeholder="select zone"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={[{ label: '- Unassign -', value: ''}].concat(zoneList.map(l => { return { label: l.name, value: l.id} }))}
                                />
                            </Space>
                        },
                    },
                    
                 
                ]} 
                dataSource={tableList} 
                pagination={false} 
                rowKey={record => record.id}  />
        </div>
    </>;
}