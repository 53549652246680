import { API_BASE_ENDPOINT, API_BASE_HEADER, parse } from './apiConfig';
import { message } from 'antd';

class IngridientAPI {
  
    getList = async(companyId) => {
      
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/ingridient/${companyId}`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return parse(data);
        }catch(e) {
            message.error(e);
            return false;
        }
    }

    getListForProduct = async(productId) => {
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/ingridient/${productId}/product`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return parse(data);
        }catch(e) {
            message.error(e);
            return false;
        }
    }

    addToProduct = async(companyId, productId, ingridientObj) => {
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/ingridient/${companyId}/product/${productId}`,
                await API_BASE_HEADER('POST', ingridientObj)
            );
            let data = await response.json();
            return parse(data);
        }catch(e){
            console.error(e);
            return null;
        } 
    }
}

const ingridientAPI = new IngridientAPI();
export default ingridientAPI;