import { API_BASE_ENDPOINT, API_BASE_HEADER, parse } from '../http/apiConfig';
import { message } from 'antd';

class StatisticsAPI {
  
    getStatsForDashboard = async(from, to, companyId) => {
       
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/dashboard?companyId=${companyId}&from=${encodeURIComponent(from.format('YYYY-MM-DDTHH:mm:ssZ'))}&to=${encodeURIComponent(to.format('YYYY-MM-DDTHH:mm:ssZ'))}`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return parse(data);
        }catch(e){
            message.error(e);
            
            return false;
        }
    }

    getStatsForDashboardAdmin = async(from, to) => {
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/dashboard/admin?from=${encodeURIComponent(from.format('YYYY-MM-DDTHH:mm:ssZ'))}&to=${encodeURIComponent(to.format('YYYY-MM-DDTHH:mm:ssZ'))}`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return parse(data);
        }catch(e){
            message.error(e);
            
            return false;
        }
    }

    getStatsForProductsMonth = async(companyId) => {
        if (!companyId)
            return;
            
        try {
            const response = await fetch(
                `${API_BASE_ENDPOINT}/api/dashboard/${companyId}/products`,
                await API_BASE_HEADER('GET')
            );
            const data = await response.json();
            return parse(data);
        }catch(e) {
            message.error(e);
            return false;
        }
    }
}

const statsAPI = new StatisticsAPI();
export default statsAPI;