import React, { createContext, useContext, useReducer  } from "react";

const CompanyContext = createContext();
const initialState = {companyId: '', companyList: [], languages:[], language: ''};

const reducer = (state, action) => {
    switch(action.type) {
      case "set":
        return {
            companyId: action.companyId,
            companyList: state.companyList,
            languages: companyLanguages(action.companyId, state.companyList),
            language: defualtLanguage(action.companyId, state.companyList),
            currency: defaultCurrency(action.companyId, state.companyList),
            vat: defaultVat(action.companyId, state.companyList),
            isCompucash: defaultCompucashEnabled(action.companyId, action.companyList),
            isPoster: defaultPosterEnabled(action.companyId, action.companyList),
        }
      case "set_list":
        return {
            companyId: state.companyId,
            companyList: [...action.companyList],
            languages: companyLanguages(state.companyId, action.companyList),
            language: defualtLanguage(state.companyId, action.companyList),
            currency: defaultCurrency(state.companyId, action.companyList),
            vat: defaultVat(state.companyId, action.companyList),
            isCompucash: defaultCompucashEnabled(state.companyId, action.companyList),
            isPoster:defaultPosterEnabled(state.companyId, action.companyList),
        }
      case "set_company":
        const newList = state.companyList.map(c => {
          if (c.id === action.company.id) 
            return  {...action.company}; 
          return c;
        });
        return {
            companyId: state.companyId,
            companyList: [...newList],
            languages: companyLanguages(state.companyId, newList),
            language: defualtLanguage(state.companyId, newList),
            currency: defaultCurrency(state.companyId, newList),
            vat: defaultVat(state.companyId, newList),
            isCompucash: defaultCompucashEnabled(state.companyId, newList),
            isPoster:defaultPosterEnabled(state.companyId, newList),
        }
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  }

  const defaultVat = (id, list) => {
    if (!id || !list || list.length === 0)
    return '';
  
    const selected = list.find(c => c.id === id);
    if (selected && selected.data){
      return parseFloat(selected.data.vat) + parseFloat((selected.data.isVat2 && selected.data.vat2 > 0 ? selected.data.vat2 : 0));
    }
    
    return '';
  }

const companyLanguages = (id, list) => {
  if (!id || !list || list.length === 0)
    return [];

  const selected = list.find(c => c.id === id);
  if (selected && selected.data)
    return [...selected.data.languages];
  
  return [];

}

const defaultCurrency = (id, list) => {
  if (!id || !list || list.length === 0)
  return '';

  const selected = list.find(c => c.id === id);
  if (selected && selected.data)
    return selected.data.currency;

  return '';
}

const defaultCompucashEnabled = (id, list) => {
  if (!id || !list || list.length === 0)
  return '';

  const selected = list.find(c => c.id === id);
  if (selected && selected.data)
    return selected.data.compucashClientId && selected.data.isCompucashEnabled;

  return '';
}

const defaultPosterEnabled = (id, list) => {
  if (!id || !list || list.length === 0)
  return '';

  const selected = list.find(c => c.id === id);
  if (selected && selected.data)
    return selected.data.posterToken && selected.data.isPosterEnabled && selected.data.posterClientId;

  return '';
}

const defualtLanguage = (id, list) => {
  if (!id || !list || list.length === 0)
  return '';

const selected = list.find(c => c.id === id);
if (selected && selected.data)
  return selected.data.language;

return '';
}

const CompanyPovider = ({ children }) => {
    
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <CompanyContext.Provider value={{state, dispatch}}>
            {children}
        </CompanyContext.Provider>
      );
};

const useCompany  = () => {
    const {state, dispatch} = useContext(CompanyContext)

    return {
        companyList: state.companyList,
        companyId: state.companyId,
        languages: state.languages,
        language: state.language,
        currency: state.currency,
        vat: state.vat,
        data: state.data,
        isCompucash: state.isCompucash,
        isPoster: state.isPoster,
        setCompanyId: (newCompanyId) => dispatch({type: "set", companyId: newCompanyId}),
        setCompanyList: (newCompanyList) => dispatch({type: "set_list", companyList: newCompanyList}),
        updateCompany: (company) =>  dispatch({type: "set_company", company: company}),
      }
  }

export default CompanyPovider;
export { useCompany };