import React, { useEffect } from 'react'
import { Space, Table, Popconfirm, Divider } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';

export default function PropertyList(props){

    useEffect(()=>{ },[props.list])

    const columns = [
        {
            title: "Name",
            render: (record) => (
              <div className="">
                {record.data.name}
              </div>
            )
        },
        {
          title: "Order",
          render: (record) => (
            <div className="">
              {record.data.order ? record.data.order : '-'}
            </div>
          )
      },
        {
            title: "Type",
            render: (record) => (
              <div className="">
                {record.type}
              </div>
            )
        },
        {
            title: 'Action',
            key: 'action',
            width: 100,
            align: 'right',
            
            render: (text, record) => (
                record.companyId ?
                <Space size="large">
                    <a onClick={() => props.onEdit(record)}><EditTwoTone /></a>
                    <Popconfirm title="Are you sure？This type could be used on products you have" okText="Yes" cancelText="No" onConfirm={(e) => props.onDelete(record.id) }>
                        <a><DeleteTwoTone twoToneColor="#eb2f96" /></a>
                    </Popconfirm>
                </Space>
              : <></>
            )
          },
      ];

    return <div>
        <Divider orientation="left">User defined</Divider>
       <Table size="small" 
            columns={columns} 
            dataSource={props.list.filter(x => x.companyId)} 
            pagination={false} 
            loading={false} 
            rowKey={record => record.id} 
             />
        <Divider orientation="left">System default</Divider>
        <Table size="small" 
            columns={columns} 
            dataSource={props.list.filter(x => x.companyId===null).sort((a, b) => a.type.localeCompare(b.type))} 
            pagination={false} 
            loading={false} 
            rowKey={record => record.id} 
             />
    </div>
}