import React, { useState } from 'react';
import { useForm } from "react-hook-form"
import { 
  formatCurrencty, 
  toLowerCase,
  calculateTotal,
  getMultiLang
} from '../Common';
import { useCompany } from '../../hooks/CompanyProvider';
import posAPI from '../../http/apiPos';

const PosPayment = ({close, products}) => {
  
  const totalToPay = calculateTotal(toLowerCase(products));

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: { card: totalToPay, cash: '', print: true }})
  
  const company = useCompany();
  const companyId = company.companyId;

  const [selectedInput, setSelectedInput] = useState('card'); // ['card', 'cash']
 
  const [totalEntered, setTotalEntered] = useState(totalToPay);
  const [inputCard, setInputCard] = useState('card'); // ['card', 'cash']
  const [inputCash, setInputCash] = useState('cash'); // ['card', 'cash']
  //const [print, setPrint] = useState(false);

  const onSubmit = (values) => { 
    console.log(values);
    
    if (totalToPay !== totalEntered) return;

    const itemsToPay = products.map(p => {
      return {
        orderItemId: p.id,
        productId: p.productId,
        count: p.count,
      }
    });

    const payments = [
      { paymentType: 'CARD', amount: parseFloat(values.card) || 0.00 },
      { paymentType: 'CASH', amount: parseFloat(values.cash) || 0.00 },
    ];

    console.log('itemsToPay: ', itemsToPay);
    console.log('payments: ', payments);

    posAPI.setItemsPay({ OrderItems: itemsToPay, Payments: payments });
  };
  const onFinishFailed = (errorInfo) => { console.error(errorInfo) };
  
  const updateSelectedInput = (value) => {
    let v = getValues(selectedInput);
    if (value === 'DEL')
      setValue(selectedInput, v.substring(0, v.length - 1));
    else
      setValue(selectedInput, getValues(selectedInput) + value);

    const card = parseFloat(getValues('card') || 0.00);
    const cash = parseFloat(getValues('cash') || 0.00);

    setTotalEntered(card + cash);
    console.log('total entered: ', card + cash);
  }



  return (
    <div className='flex-grow flex  h-screen '>
        <div className='flex flex-col h-full w-full max-w-xl bg-gray-300 pr-7'>
          <div className='p-6 text-black grid grid-cols-3 gap-3 text-xl'>
              
              <div onClick={()=>updateSelectedInput('7')} className='h-40 w-40 text-6xl bg-white shadow-md rounded-sm flex justify-center hover:bg-gray-600 hover:text-white '><div className='flex items-center justify-center'>7</div></div>
              <div onClick={()=>updateSelectedInput('8')} className='h-40 w-40 text-6xl bg-white shadow-md rounded-sm flex justify-center hover:bg-gray-600 hover:text-white '><div className='flex items-center justify-center'>8</div></div>
              <div onClick={()=>updateSelectedInput('9')} className='h-40 w-40 text-6xl bg-white shadow-md rounded-sm flex justify-center hover:bg-gray-600 hover:text-white '><div className='flex items-center justify-center'>9</div></div>

              <div onClick={()=>updateSelectedInput('4')} className='h-40 w-40 text-6xl bg-white shadow-md rounded-sm flex justify-center hover:bg-gray-600 hover:text-white '><div className='flex items-center justify-center'>4</div></div>
              <div onClick={()=>updateSelectedInput('5')} className='h-40 w-40 text-6xl bg-white shadow-md rounded-sm flex justify-center hover:bg-gray-600 hover:text-white '><div className='flex items-center justify-center'>5</div></div>
              <div onClick={()=>updateSelectedInput('6')} className='h-40 w-40 text-6xl bg-white shadow-md rounded-sm flex justify-center hover:bg-gray-600 hover:text-white '><div className='flex items-center justify-center'>6</div></div>
            
              <div onClick={()=>updateSelectedInput('1')} className='h-40 w-40 text-6xl bg-white shadow-md rounded-sm flex justify-center hover:bg-gray-600 hover:text-white '><div className='flex items-center justify-center'>1</div></div>
              <div onClick={()=>updateSelectedInput('2')} className='h-40 w-40 text-6xl bg-white shadow-md rounded-sm flex justify-center hover:bg-gray-600 hover:text-white '><div className='flex items-center justify-center'>2</div></div>
              <div onClick={()=>updateSelectedInput('3')} className='h-40 w-40 text-6xl bg-white shadow-md rounded-sm flex justify-center hover:bg-gray-600 hover:text-white '><div className='flex items-center justify-center'>3</div></div>

              <div onClick={()=>updateSelectedInput('.')} className='h-40 w-40 text-6xl bg-white shadow-md rounded-sm flex justify-center hover:bg-gray-600 hover:text-white '><div className='flex items-center justify-center'>.</div></div>
              <div onClick={()=>updateSelectedInput('0')} className='h-40 w-40 text-6xl bg-white shadow-md rounded-sm flex justify-center hover:bg-gray-600 hover:text-white '><div className='flex items-center justify-center'>0</div></div>
              <div onClick={()=>updateSelectedInput('DEL')} className='h-40 w-40 text-6xl bg-white shadow-md rounded-sm flex justify-center hover:bg-gray-600 hover:text-white '><div className='flex items-center justify-center'>DEL</div></div>
          </div>
        </div>
        <div className='flex flex-col h-screen w-full bg-white text-black'>
            <div className='flex justify-between text-4xl p-6 '>
                <div className='flex gap-3'><div>Total:</div><div className='font-bold'>{formatCurrencty(totalToPay, company.currency)}</div></div>
                <div className='cursor-pointer' onClick={() => close()}>BACK</div>
            </div>
            <div className='p-6 flex-1'>
              <form onSubmit={handleSubmit(onSubmit)}>
              
            
                <div>
                  <div className='max-w-sm'>
                    
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500">CARD</span>
                      </div>
                      <input 
                        readOnly 
                        onClick={() => setSelectedInput('card')}
                        {...register("card")} type="text" className="text-4xl block w-full rounded-md border-0 py-1.5 pl-14 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 " placeholder="0.00"/>
                      <div className="absolute inset-y-0 right-0 flex items-center">
                       
                      </div>
                    </div>
                  </div>
                </div>

                <div className='pt-3'>
                  <div className='max-w-sm'>
                    
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500">CASH</span>
                      </div>
                      <input 
                        readOnly
                        type="text" 
                        onClick={() => setSelectedInput('cash')}
                        {...register("cash")} 
                        className="text-4xl block w-full rounded-md border-0 py-1.5 pl-14 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600" placeholder="0.00"/>
                      <div className="absolute inset-y-0 right-0 flex items-center">
                        
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className='pt-3'>
                  <label for="toogleA" className="flex items-center cursor-pointer">
                    
                    <div className="relative">
                      <input {...register("print", {})} type="checkbox"/>
                     {/* 
                     <div className="bar w-12 h-6 bg-gray-500 rounded-full shadow-inner"></div>
                      <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-0 -top-0 transition"></div>
                      */}
                    </div>
                    <div className="ml-3 text-lg">Print</div>
                  </label>
                </div>

                <div className='pt-5'>
                    {
                      products.map((p, i) => {
                        return <div className='flex justify-between pt-1 text-xl'>
                          <div className="truncate text-black "><strong>{p.count}x</strong>  {getMultiLang(p.data || p, 'name')}</div>
                          <div className="">
                            
                            <div className="font-mulle font-bold text-right">{formatCurrencty(calculateTotal([p]), p.currency)}</div>
                        </div>
                        </div>
                      })
                    }
                </div>
              
              </form>
            </div>
            
            <div className='flex justify-between p-6 bottom-0 sticky'>
              <div className={`w-40 text-center px-7 pt-2.5 cursor-pointer ${totalToPay === totalEntered ? ' bg-green-600' : 'bg-yellow-500' } rounded-lg`} onClick={handleSubmit((d) => onSubmit(d))}>
                  <div className='pt-1 text-2xl font-bold'>PAY</div>
              </div>

              <div className="max-w-lg h-16 text-center px-7 pt-2.5 cursor-pointer text-red" onClick={handleSubmit((d) => console.log(d))}>
                  <div className='pt-1 text-2xl font-bold'>CLOSE WITHOUT PAY</div>
              </div>
              
            </div>
            
        </div>
    </div>
  );
};
export default PosPayment;