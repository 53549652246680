
const config = {
  apiKey: "AIzaSyDQFcCXD1MG_keBzMm7lz--FYcCfkOMvuE",
  authDomain: "admin.mulle.ee",
  projectId: "mulle-56438",
  storageBucket: "mulle-56438.appspot.com",
  messagingSenderId: "121164069043",
  appId: "1:121164069043:web:3f8e3db2cc730b401e58c6",
  measurementId: "G-FG6SV2575J"
};


export default config;